document.addEventListener('turbolinks:load', () => {

    const jsUploadWallpaper = document.getElementById('jsUploadWallpaper')
    const noJsUploadWallpaper = document.getElementById('noJsUploadWallpaper')

    const dropZoneWallpaper = document.getElementById('dropZoneWallpaper');
    const fileInputWallpaper = document.getElementById('file_input');
    const selectedFileNameWallpaper = document.getElementById('added_file');

    const fileSelectDivWallpaper = document.getElementById('fileSelectWallpaper')
    const uploadIndicatorWallpaper = document.getElementById('uploadIndicatorWallpaper')

    const submitAppstoreApk = document.getElementById('submitAppstoreApk')


    if (jsUploadWallpaper !== null && noJsUploadWallpaper !== null && dropZoneWallpaper !== null && fileInputWallpaper !== null
        && selectedFileNameWallpaper !== null && fileSelectDivWallpaper !== null && uploadIndicatorWallpaper !== null &&
        submitAppstoreApk !== null) {

        wallpaperUpload()
    }


    function wallpaperUpload() {
        jsUploadWallpaper.classList.remove('hidden')
        noJsUploadWallpaper.classList.add('hidden')

        // file input
        fileInputWallpaper.addEventListener('change', function(e) {
            selectedFileNameWallpaper.innerHTML = I18n.t('js.selected_file') + fileInputWallpaper.value.replace(/^.*[\\\/]/, '');
            checkStorage(e.target.files[0])
        })

        if (submitAppstoreApk !== null) {
            submitAppstoreApk.addEventListener('click', function () {
                fileSelectDivWallpaper.classList.add('hidden');
                uploadIndicatorWallpaper.classList.remove('hidden')
            })
        }

        // dropzone
        dropZoneWallpaper.ondragover = dropZoneWallpaper.ondragenter = function(evt) {
            evt.preventDefault();
        };
        dropZoneWallpaper.ondrop = function(evt) {
            evt.preventDefault();

            if (evt.dataTransfer.files.length > 1) {
                alert("You can't drop more then one file");
                return;
            }

            if (evt.dataTransfer.files[0].type !== 'image/png' && evt.dataTransfer.files[0].type !== 'image/jpeg') {
                alert("The file has an unsupported type. Please use only png files");
                return;
            }

            fileInputWallpaper.files = evt.dataTransfer.files;
            selectedFileNameWallpaper.innerHTML = I18n.t('js.selected_file') + evt.dataTransfer.files[0].name;
            checkStorage(evt.dataTransfer.files[0])

        };
    }

    function checkStorage(file) {
        let neededStorage = Math.ceil((file.size/1024/1024));
        $.rails.ajax({
            url: "/organizations/check_storage/" + neededStorage,
            type: "get",
            data: "",
        })
    }

});