document.addEventListener('turbolinks:load', () => {

    const showPasswordIconButton = document.getElementById('showPasswordIconButton')
    const hidePasswordIconButton = document.getElementById('hidePasswordIconButton')
    const passwordInput = document.getElementById('passwordInput')
    const no_passwordButton = document.getElementById('no_passwordButton')
    const no_password = document.getElementById('no_password')
    const passwordInputLabel = document.getElementById('passwordInputLabel')
    const hiddenNoPasswordValue = document.getElementById('hiddenNoPasswordValue')

    if (showPasswordIconButton !== null && hidePasswordIconButton !== null &&
        passwordInput !== null && no_passwordButton !== null && no_password !== null && hiddenNoPasswordValue !== null &&
        passwordInputLabel !== null) {
        init()
    }

    function init() {

        showPasswordIconButton.classList.remove('hidden')

        showPasswordIconButton.addEventListener("click", function () {
            passwordInput.type = "text"
            showPasswordIconButton.classList.add('hidden')
            hidePasswordIconButton.classList.remove('hidden')
        });

        hidePasswordIconButton.addEventListener("click", function () {
            passwordInput.type = "password"
            showPasswordIconButton.classList.remove('hidden')
            hidePasswordIconButton.classList.add('hidden')
        });

        // ### No Password switch ###
        // init
        initNoPasswordSwitch()
        // click listener
        no_passwordButton.addEventListener("click", function() {
            clickNoPasswordSwitch()
        });
    }

    function initNoPasswordSwitch() {
        // init
        if (hiddenNoPasswordValue.innerText === "false") {
            //Wifi with password
            passwordInput.disabled = false;
            passwordInput.classList.remove("bg-gray-100")
            passwordInputLabel.classList.add("text-gray-700")
            passwordInputLabel.classList.remove("text-gray-400")
            showPasswordIconButton.classList.add("text-gray-700")
            hidePasswordIconButton.classList.add("text-gray-700")
            showPasswordIconButton.classList.remove("text-gray-400")
            hidePasswordIconButton.classList.remove("text-gray-400")
        } else {
            //Wifi without password
            passwordInput.disabled = true;
            passwordInput.value = "";
            passwordInput.classList.add("bg-gray-100")
            passwordInputLabel.classList.remove("text-gray-700")
            passwordInputLabel.classList.add("text-gray-400")
            showPasswordIconButton.classList.remove("text-gray-700")
            hidePasswordIconButton.classList.remove("text-gray-700")
            showPasswordIconButton.classList.add("text-gray-400")
            hidePasswordIconButton.classList.add("text-gray-400")
        }
    }

    function clickNoPasswordSwitch() {
        if (no_passwordButton.ariaPressed) {
            //Wifi with password
            passwordInput.disabled = false;
            passwordInput.classList.remove("bg-gray-100")
            passwordInputLabel.classList.add("text-gray-700")
            passwordInputLabel.classList.remove("text-gray-400")
            showPasswordIconButton.classList.add("text-gray-700")
            hidePasswordIconButton.classList.add("text-gray-700")
            showPasswordIconButton.classList.remove("text-gray-400")
            hidePasswordIconButton.classList.remove("text-gray-400")
        } else {
            //Wifi without password
            passwordInput.disabled = true;
            passwordInput.value = "";
            passwordInput.classList.add("bg-gray-100")
            passwordInputLabel.classList.remove("text-gray-700")
            passwordInputLabel.classList.add("text-gray-400")
            showPasswordIconButton.classList.remove("text-gray-700")
            hidePasswordIconButton.classList.remove("text-gray-700")
            showPasswordIconButton.classList.add("text-gray-400")
            hidePasswordIconButton.classList.add("text-gray-400")
        }
    }
});