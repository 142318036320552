document.addEventListener('turbolinks:load', () => {

    const backgroundColorPreview = document.getElementById('snBackgroundColorPreview');
    const backgroundColorTextField = document.getElementById('snBackgroundColorTextField');
    const backgroundColorColorField = document.getElementById('snBackgroundColorColorField');
    const noValidBackgroundColorMessage = document.getElementById('snNoValidBackgroundColorMessage');

    // ################
    // ### headline ###
    //#################
    // color
    const snHeadlineTextColorPreview = document.getElementById('snHeadlineTextColorPreview');
    const snHeadlineTextColorTextField = document.getElementById('snHeadlineTextColorTextField');
    const snHeadlineTextColorColorField = document.getElementById('snHeadlineTextColorColorField');
    const snNoValidHeadlineTextColorMessage = document.getElementById('snNoValidHeadlineTextColorMessage');
    // background
    const snHeadlineBackgroundTransparentButton = document.getElementById('snHeadlineBackgroundTransparentButton')
    const snHeadlineBackgroundColorPreview = document.getElementById('snHeadlineBackgroundColorPreview');
    const snHeadlineBackgroundColorTextField = document.getElementById('snHeadlineBackgroundColorTextField');
    const snHeadlineBackgroundColorColorField = document.getElementById('snHeadlineBackgroundColorColorField');
    const snNoValidHeadlineBackgroundColorMessage = document.getElementById('snNoValidHeadlineBackgroundColorMessage');
    const snHeadlineBackgroundColorLabel = document.getElementById('snHeadlineBackgroundColorLabel')

    // ################
    // ### description ###
    //#################
    // color
    const snDescriptionTextColorPreview = document.getElementById('snDescriptionTextColorPreview');
    const snDescriptionTextColorTextField = document.getElementById('snDescriptionTextColorTextField');
    const snDescriptionTextColorColorField = document.getElementById('snDescriptionTextColorColorField');
    const snNoValidDescriptionTextColorMessage = document.getElementById('snNoValidDescriptionTextColorMessage');
    // background
    const snDescriptionBackgroundTransparentButton = document.getElementById('snDescriptionBackgroundTransparentButton')
    const snDescriptionBackgroundColorPreview = document.getElementById('snDescriptionBackgroundColorPreview');
    const snDescriptionBackgroundColorTextField = document.getElementById('snDescriptionBackgroundColorTextField');
    const snDescriptionBackgroundColorColorField = document.getElementById('snDescriptionBackgroundColorColorField');
    const snNoValidDescriptionBackgroundColorMessage = document.getElementById('snNoValidDescriptionBackgroundColorMessage');
    const snDescriptionBackgroundColorLabel = document.getElementById('snDescriptionBackgroundColorLabel')

    // ################
    // ### icon ###
    //#################
    // color
    const snIconColorPreview = document.getElementById('snIconColorPreview');
    const snIconColorTextField = document.getElementById('snIconColorTextField');
    const snIconColorColorField = document.getElementById('snIconColorColorField');
    const snNoValidIconColorMessage = document.getElementById('snNoValidIconColorMessage');
    // background
    const snIconBackgroundTransparentButton = document.getElementById('snIconBackgroundTransparentButton')
    const snIconBackgroundColorPreview = document.getElementById('snIconBackgroundColorPreview');
    const snIconBackgroundColorTextField = document.getElementById('snIconBackgroundColorTextField');
    const snIconBackgroundColorColorField = document.getElementById('snIconBackgroundColorColorField');
    const snNoValidIconBackgroundColorMessage = document.getElementById('snNoValidIconBackgroundColorMessage');
    const snIconBackgroundColorLabel = document.getElementById('snIconBackgroundColorLabel')

    // ################
    // ### button ###
    //#################
    // color
    const snButtonTextColorPreview = document.getElementById('snButtonTextColorPreview');
    const snButtonTextColorTextField = document.getElementById('snButtonTextColorTextField');
    const snButtonTextColorColorField = document.getElementById('snButtonTextColorColorField');
    const snNoValidButtonTextColorMessage = document.getElementById('snNoValidButtonTextColorMessage');
    // background
    const snButtonBackgroundTransparentButton = document.getElementById('snButtonBackgroundTransparentButton')
    const snButtonBackgroundColorPreview = document.getElementById('snButtonBackgroundColorPreview');
    const snButtonBackgroundColorTextField = document.getElementById('snButtonBackgroundColorTextField');
    const snButtonBackgroundColorColorField = document.getElementById('snButtonBackgroundColorColorField');
    const snNoValidButtonBackgroundColorMessage = document.getElementById('snNoValidButtonBackgroundColorMessage');
    const snButtonBackgroundColorLabel = document.getElementById('snButtonBackgroundColorLabel')

    // ################
    // ### progress_indicator ###
    //#################
    // color
    const snProgressIndicatorColorPreview = document.getElementById('snProgressIndicatorColorPreview');
    const snProgressIndicatorColorTextField = document.getElementById('snProgressIndicatorColorTextField');
    const snProgressIndicatorColorColorField = document.getElementById('snProgressIndicatorColorColorField');
    const snNoValidProgressIndicatorColorMessage = document.getElementById('snNoValidProgressIndicatorColorMessage');


    // #################
    // ### confirmation screen background color
    // #################
    const snConfirmationScreenBackgroundColorPreview = document.getElementById('snConfirmationScreenBackgroundColorPreview');
    const snConfirmationScreenBackgroundColorTextField = document.getElementById('snConfirmationScreenBackgroundColorTextField');
    const snConfirmationScreenBackgroundColorColorField = document.getElementById('snConfirmationScreenBackgroundColorColorField');
    const snNoValidConfirmationScreenBackgroundColorMessage = document.getElementById('snNoValidConfirmationScreenBackgroundColorMessage');

    // ################
    // ### confirmationScreenIcon ###
    //#################
    // color
    const snConfirmationScreenIconColorPreview = document.getElementById('snConfirmationScreenIconColorPreview');
    const snConfirmationScreenIconColorTextField = document.getElementById('snConfirmationScreenIconColorTextField');
    const snConfirmationScreenIconColorColorField = document.getElementById('snConfirmationScreenIconColorColorField');
    const snNoValidConfirmationScreenIconColorMessage = document.getElementById('snNoValidConfirmationScreenIconColorMessage');
    // background
    const snConfirmationScreenIconBackgroundTransparentButton = document.getElementById('snConfirmationScreenIconBackgroundTransparentButton')
    const snConfirmationScreenIconBackgroundColorPreview = document.getElementById('snConfirmationScreenIconBackgroundColorPreview');
    const snConfirmationScreenIconBackgroundColorTextField = document.getElementById('snConfirmationScreenIconBackgroundColorTextField');
    const snConfirmationScreenIconBackgroundColorColorField = document.getElementById('snConfirmationScreenIconBackgroundColorColorField');
    const snNoValidConfirmationScreenIconBackgroundColorMessage = document.getElementById('snNoValidConfirmationScreenIconBackgroundColorMessage');
    const snConfirmationScreenIconBackgroundColorLabel = document.getElementById('snConfirmationScreenIconBackgroundColorLabel')

    // ################
    // ### confirmationScreenText ###
    //#################
    // color
    const snConfirmationScreenTextColorPreview = document.getElementById('snConfirmationScreenTextColorPreview');
    const snConfirmationScreenTextColorTextField = document.getElementById('snConfirmationScreenTextColorTextField');
    const snConfirmationScreenTextColorColorField = document.getElementById('snConfirmationScreenTextColorColorField');
    const snNoValidConfirmationScreenTextColorMessage = document.getElementById('snNoValidConfirmationScreenTextColorMessage');
    // background
    const snConfirmationScreenTextBackgroundTransparentButton = document.getElementById('snConfirmationScreenTextBackgroundTransparentButton')
    const snConfirmationScreenTextBackgroundColorPreview = document.getElementById('snConfirmationScreenTextBackgroundColorPreview');
    const snConfirmationScreenTextBackgroundColorTextField = document.getElementById('snConfirmationScreenTextBackgroundColorTextField');
    const snConfirmationScreenTextBackgroundColorColorField = document.getElementById('snConfirmationScreenTextBackgroundColorColorField');
    const snNoValidConfirmationScreenTextBackgroundColorMessage = document.getElementById('snNoValidConfirmationScreenTextBackgroundColorMessage');
    const snConfirmationScreenTextBackgroundColorLabel = document.getElementById('snConfirmationScreenTextBackgroundColorLabel')

    // ################
    // ### confirmationScreenButton ###
    //#################
    // color
    const snConfirmationScreenButtonTextColorPreview = document.getElementById('snConfirmationScreenButtonTextColorPreview');
    const snConfirmationScreenButtonTextColorTextField = document.getElementById('snConfirmationScreenButtonTextColorTextField');
    const snConfirmationScreenButtonTextColorColorField = document.getElementById('snConfirmationScreenButtonTextColorColorField');
    const snNoValidConfirmationScreenButtonTextColorMessage = document.getElementById('snNoValidConfirmationScreenButtonTextColorMessage');
    // background
    const snConfirmationScreenButtonBackgroundTransparentButton = document.getElementById('snConfirmationScreenButtonBackgroundTransparentButton')
    const snConfirmationScreenButtonBackgroundColorPreview = document.getElementById('snConfirmationScreenButtonBackgroundColorPreview');
    const snConfirmationScreenButtonBackgroundColorTextField = document.getElementById('snConfirmationScreenButtonBackgroundColorTextField');
    const snConfirmationScreenButtonBackgroundColorColorField = document.getElementById('snConfirmationScreenButtonBackgroundColorColorField');
    const snNoValidConfirmationScreenButtonBackgroundColorMessage = document.getElementById('snNoValidConfirmationScreenButtonBackgroundColorMessage');
    const snConfirmationScreenButtonBackgroundColorLabel = document.getElementById('snConfirmationScreenButtonBackgroundColorLabel')

    if (backgroundColorPreview !== null && backgroundColorTextField !== null && noValidBackgroundColorMessage !== null &&
        snHeadlineTextColorTextField !== null && snHeadlineTextColorPreview !== null && snNoValidHeadlineTextColorMessage !== null &&
        snHeadlineBackgroundColorPreview !== null && snHeadlineBackgroundColorTextField !== null &&
        snNoValidHeadlineBackgroundColorMessage !== null && snHeadlineBackgroundTransparentButton !== null &&
        snHeadlineBackgroundColorLabel !== null && snDescriptionTextColorTextField !== null &&
        snDescriptionTextColorPreview !== null && snNoValidDescriptionTextColorMessage !== null &&
        snDescriptionBackgroundColorPreview !== null && snDescriptionBackgroundColorTextField !== null &&
        snNoValidDescriptionBackgroundColorMessage !== null && snDescriptionBackgroundTransparentButton !== null &&
        snDescriptionBackgroundColorLabel !== null &&
        snIconColorPreview !== null && snIconColorTextField !== null && snNoValidIconColorMessage != null &&
        snIconBackgroundTransparentButton !== null && snIconBackgroundColorPreview !== null &&
        snIconBackgroundColorTextField !== null && snNoValidIconBackgroundColorMessage !== null &&
        snIconBackgroundColorLabel != null && snButtonTextColorTextField !== null &&
        snButtonTextColorPreview !== null && snNoValidButtonTextColorMessage !== null &&
        snButtonBackgroundColorPreview !== null && snButtonBackgroundColorTextField !== null &&
        snNoValidButtonBackgroundColorMessage !== null && snButtonBackgroundTransparentButton !== null &&
        snButtonBackgroundColorLabel !== null &&
        snProgressIndicatorColorPreview !== null && snProgressIndicatorColorTextField !== null &&
        snNoValidProgressIndicatorColorMessage !== null &&
        snConfirmationScreenBackgroundColorPreview !== null && snNoValidConfirmationScreenBackgroundColorMessage !== null &&
        snConfirmationScreenBackgroundColorTextField !== null &&
        snConfirmationScreenIconColorPreview !== null && snConfirmationScreenIconColorTextField !== null && snNoValidConfirmationScreenIconColorMessage != null &&
        snConfirmationScreenIconBackgroundTransparentButton !== null && snConfirmationScreenIconBackgroundColorPreview !== null &&
        snConfirmationScreenIconBackgroundColorTextField !== null && snNoValidConfirmationScreenIconBackgroundColorMessage !== null &&
        snConfirmationScreenIconBackgroundColorLabel != null &&
        snConfirmationScreenTextColorPreview !== null && snConfirmationScreenTextColorTextField !== null && snNoValidConfirmationScreenTextColorMessage != null &&
        snConfirmationScreenTextBackgroundTransparentButton !== null && snConfirmationScreenTextBackgroundColorPreview !== null &&
        snConfirmationScreenTextBackgroundColorTextField !== null && snNoValidConfirmationScreenTextBackgroundColorMessage !== null &&
        snConfirmationScreenTextBackgroundColorLabel != null &&
        snConfirmationScreenButtonTextColorPreview !== null && snConfirmationScreenButtonTextColorTextField !== null && snNoValidConfirmationScreenButtonTextColorMessage != null &&
        snConfirmationScreenButtonBackgroundTransparentButton !== null && snConfirmationScreenButtonBackgroundColorPreview !== null &&
        snConfirmationScreenButtonBackgroundColorTextField !== null && snNoValidConfirmationScreenButtonBackgroundColorMessage !== null &&
        snConfirmationScreenButtonBackgroundColorLabel != null) {

        init()
    }

    function init() {

        backgroundColorTextField.addEventListener("change", function() {
            updateColor("background");
            updateColorValue("snBackgroundColor", backgroundColorTextField.value);
        });
        backgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snBackgroundColor", backgroundColorColorField.value);
        });
        // ################
        // ### headline ###
        //#################
        snHeadlineTextColorTextField.addEventListener("change", function() {
            updateColor("headlineTextColor");
            updateColorValue("snHeadlineTextColor", snHeadlineTextColorTextField.value);
        });
        snHeadlineTextColorColorField.addEventListener("change", function() {
            updateColorValue("snHeadlineTextColor", snHeadlineTextColorColorField.value);
        });

        snHeadlineBackgroundColorTextField.addEventListener("change", function() {
            updateColor("headlineBackgroundColor");
            updateColorValue("snHeadlineBackgroundColor", snHeadlineBackgroundColorTextField.value);
        });
        snHeadlineBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snHeadlineBackgroundColor", snHeadlineBackgroundColorColorField.value);
        });

        snHeadlineBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snHeadlineBackgroundTransparent').checked
            disEnableView(transparent, "headlineBackgroundColor");
        });

        let transparent =  document.getElementById('snHeadlineBackgroundTransparent').checked
        disEnableView(transparent, "headlineBackgroundColor");


        // ################
        // ### description ###
        //#################
        snDescriptionTextColorTextField.addEventListener("change", function() {
            updateColor("descriptionTextColor");
            updateColorValue("snDescriptionTextColor", snDescriptionTextColorTextField.value);
        });
        snDescriptionTextColorColorField.addEventListener("change", function() {
            updateColorValue("snDescriptionTextColor", snDescriptionTextColorColorField.value);
        });

        snDescriptionBackgroundColorTextField.addEventListener("change", function() {
            updateColor("descriptionBackgroundColor");
            updateColorValue("snDescriptionBackgroundColor", snDescriptionBackgroundColorTextField.value);
        });
        snDescriptionBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snDescriptionBackgroundColor", snDescriptionBackgroundColorColorField.value);
        });

        snDescriptionBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snDescriptionBackgroundTransparent').checked
            disEnableView(transparent, "descriptionBackgroundColor");
        });

        transparent =  document.getElementById('snDescriptionBackgroundTransparent').checked
        disEnableView(transparent, "descriptionBackgroundColor");

        // ################
        // ### icon ###
        //#################
        snIconColorTextField.addEventListener("change", function() {
            updateColor("iconColor");
            updateColorValue("snIconColor", snIconColorTextField.value);
        });
        snIconColorColorField.addEventListener("change", function() {
            updateColorValue("snIconColor", snIconColorColorField.value);
        });

        snIconBackgroundColorTextField.addEventListener("change", function() {
            updateColor("iconBackgroundColor");
            updateColorValue("snIconBackgroundColor", snIconBackgroundColorTextField.value);
        });
        snIconBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snIconBackgroundColor", snIconBackgroundColorColorField.value);
        });

        snIconBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snIconBackgroundTransparent').checked
            disEnableView(transparent, "iconBackgroundColor");
        });

        transparent =  document.getElementById('snIconBackgroundTransparent').checked
        disEnableView(transparent, "iconBackgroundColor");

        // ################
        // ### button ###
        //#################
        snButtonTextColorTextField.addEventListener("change", function() {
            updateColor("buttonTextColor");
            updateColorValue("snButtonTextColor", snButtonTextColorTextField.value);
        });
        snButtonTextColorColorField.addEventListener("change", function() {
            updateColorValue("snButtonTextColor", snButtonTextColorColorField.value);
        });

        snButtonBackgroundColorTextField.addEventListener("change", function() {
            updateColor("buttonBackgroundColor");
            updateColorValue("snButtonBackgroundColor", snButtonBackgroundColorTextField.value);
        });
        snButtonBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snButtonBackgroundColor", snButtonBackgroundColorColorField.value);
        });

        snButtonBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snButtonBackgroundTransparent').checked
            disEnableView(transparent, "buttonBackgroundColor");
        });

        transparent =  document.getElementById('snButtonBackgroundTransparent').checked
        disEnableView(transparent, "buttonBackgroundColor");

        // ################
        // ### ProgressIndicator  ###
        //#################
        snProgressIndicatorColorTextField.addEventListener("change", function() {
            updateColor("progressIndicatorColor");
            updateColorValue("snProgressIndicatorColor", snProgressIndicatorColorTextField.value);
        });
        snProgressIndicatorColorColorField.addEventListener("change", function() {
            updateColorValue("snProgressIndicatorColor", snProgressIndicatorColorColorField.value);
        });


        // ################
        // ### ConfirmationScreenBackground ###
        //#################
        snConfirmationScreenBackgroundColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenBackgroundColor");
            updateColorValue("snConfirmationScreenBackgroundColor", snConfirmationScreenBackgroundColorTextField.value);
        });
        snConfirmationScreenBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenBackgroundColor", snConfirmationScreenBackgroundColorColorField.value);
        });

        // ################
        // ### confirmationScreenIcon ###
        //#################
        snConfirmationScreenIconColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenIconColor");
            updateColorValue("snConfirmationScreenIconColor", snConfirmationScreenIconColorTextField.value);
        });
        snConfirmationScreenIconColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenIconColor", snConfirmationScreenIconColorColorField.value);
        });

        snConfirmationScreenIconBackgroundColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenIconBackgroundColor");
            updateColorValue("snConfirmationScreenIconBackgroundColor", snConfirmationScreenIconBackgroundColorTextField.value);
        });
        snConfirmationScreenIconBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenIconBackgroundColor", snConfirmationScreenIconBackgroundColorColorField.value);
        });

        snConfirmationScreenIconBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snConfirmationScreenIconBackgroundTransparent').checked
            disEnableView(transparent, "confirmationScreenIconBackgroundColor");
        });

        transparent =  document.getElementById('snConfirmationScreenIconBackgroundTransparent').checked
        disEnableView(transparent, "confirmationScreenIconBackgroundColor");

        // ################
        // ### confirmationScreenText ###
        //#################
        snConfirmationScreenTextColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenTextColor");
            updateColorValue("snConfirmationScreenTextColor", snConfirmationScreenTextColorTextField.value);
        });
        snConfirmationScreenTextColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenTextColor", snConfirmationScreenTextColorColorField.value);
        });

        snConfirmationScreenTextBackgroundColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenTextBackgroundColor");
            updateColorValue("snConfirmationScreenTextBackgroundColor", snConfirmationScreenTextBackgroundColorTextField.value);
        });
        snConfirmationScreenTextBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenTextBackgroundColor", snConfirmationScreenTextBackgroundColorColorField.value);
        });

        snConfirmationScreenTextBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snConfirmationScreenTextBackgroundTransparent').checked
            disEnableView(transparent, "confirmationScreenTextBackgroundColor");
        });

        transparent =  document.getElementById('snConfirmationScreenTextBackgroundTransparent').checked
        disEnableView(transparent, "confirmationScreenTextBackgroundColor");


        // ################
        // ### confirmationScreenButton ###
        //#################
        snConfirmationScreenButtonTextColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenButtonTextColor");
            updateColorValue("snConfirmationScreenButtonTextColor", snConfirmationScreenButtonTextColorTextField.value);
        });
        snConfirmationScreenButtonTextColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenButtonTextColor", snConfirmationScreenButtonTextColorColorField.value);
        });

        snConfirmationScreenButtonBackgroundColorTextField.addEventListener("change", function() {
            updateColor("confirmationScreenButtonBackgroundColor");
            updateColorValue("snConfirmationScreenButtonBackgroundColor", snConfirmationScreenButtonBackgroundColorTextField.value);
        });
        snConfirmationScreenButtonBackgroundColorColorField.addEventListener("change", function() {
            updateColorValue("snConfirmationScreenButtonBackgroundColor", snConfirmationScreenButtonBackgroundColorColorField.value);

        });

        snConfirmationScreenButtonBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snConfirmationScreenButtonBackgroundTransparent').checked
            disEnableView(transparent, "confirmationScreenButtonBackgroundColor");
        });

        transparent =  document.getElementById('snConfirmationScreenButtonBackgroundTransparent').checked
        disEnableView(transparent, "confirmationScreenButtonBackgroundColor");

        // ###################
        // ### Background ####
        // ###################
    }



    function disEnableView(transparent, type) {

        let textView
        let label
        let noValidColorMsg
        let colorView
        if (type === "headlineBackgroundColor") {
            textView = snHeadlineBackgroundColorTextField
            colorView = snHeadlineBackgroundColorColorField
            noValidColorMsg = snNoValidHeadlineBackgroundColorMessage
            label = snHeadlineBackgroundColorLabel
        } else if (type === "descriptionBackgroundColor") {
            textView = snDescriptionBackgroundColorTextField
            colorView = snDescriptionBackgroundColorColorField
            noValidColorMsg = snNoValidDescriptionBackgroundColorMessage
            label = snDescriptionBackgroundColorLabel
        } else if (type === "iconBackgroundColor") {
            textView = snIconBackgroundColorTextField
            colorView = snIconBackgroundColorColorField
            noValidColorMsg = snNoValidIconBackgroundColorMessage
            label = snIconBackgroundColorLabel
        } else if (type === "buttonBackgroundColor") {
            textView = snButtonBackgroundColorTextField
            colorView = snButtonBackgroundColorColorField
            noValidColorMsg = snNoValidButtonBackgroundColorMessage
            label = snButtonBackgroundColorLabel
        } else if (type === "confirmationScreenIconBackgroundColor") {
            textView = snConfirmationScreenIconBackgroundColorTextField
            colorView = snConfirmationScreenIconBackgroundColorColorField
            noValidColorMsg = snNoValidConfirmationScreenIconBackgroundColorMessage
            label = snConfirmationScreenIconBackgroundColorLabel
        } else if (type === "confirmationScreenTextBackgroundColor") {
            textView = snConfirmationScreenTextBackgroundColorTextField
            colorView = snConfirmationScreenTextBackgroundColorColorField
            noValidColorMsg = snNoValidConfirmationScreenTextBackgroundColorMessage
            label = snConfirmationScreenTextBackgroundColorLabel
        } else if (type === "confirmationScreenButtonBackgroundColor") {
            textView = snConfirmationScreenButtonBackgroundColorTextField
            colorView = snConfirmationScreenButtonBackgroundColorColorField
            noValidColorMsg = snNoValidConfirmationScreenButtonBackgroundColorMessage
            label = snConfirmationScreenButtonBackgroundColorLabel
        }

        textView.disabled = transparent
        colorView.disabled = transparent
        if (transparent) {
            noValidColorMsg.classList.add("hidden")
            textView.classList.add("text-gray-500")
            label.classList.add("text-gray-400")
            label.classList.remove("text-gray-700")

        } else if (!isColor(textView.value)) {
            noValidColorMsg.classList.remove("hidden")
            textView.classList.remove("text-gray-500")
            label.classList.remove("text-gray-400")
            label.classList.add("text-gray-700")
        } else {
            textView.classList.remove("text-gray-500")
            label.classList.remove("text-gray-400")
            label.classList.add("text-gray-700")
        }
    }

    function updateColor(type) {
        let colorPreview
        let colorTextField
        let noValidColorMessage
        if (type === "background") {
            colorPreview = backgroundColorPreview
            colorTextField = backgroundColorTextField
            noValidColorMessage = noValidBackgroundColorMessage
        } else if (type === "headlineTextColor") {
            colorPreview = snHeadlineTextColorPreview
            colorTextField = snHeadlineTextColorTextField
            noValidColorMessage = snNoValidHeadlineTextColorMessage
        } else if (type === "headlineBackgroundColor") {
            colorPreview = snHeadlineBackgroundColorPreview
            colorTextField = snHeadlineBackgroundColorTextField
            noValidColorMessage = snNoValidHeadlineBackgroundColorMessage
        } else if (type === "descriptionTextColor") {
            colorPreview = snDescriptionTextColorPreview
            colorTextField = snDescriptionTextColorTextField
            noValidColorMessage = snNoValidDescriptionTextColorMessage
        } else if (type === "descriptionBackgroundColor") {
            colorPreview = snDescriptionBackgroundColorPreview
            colorTextField = snDescriptionBackgroundColorTextField
            noValidColorMessage = snNoValidDescriptionBackgroundColorMessage
        } else if (type === "iconColor") {
            colorPreview = snIconColorPreview
            colorTextField = snIconColorTextField
            noValidColorMessage = snNoValidIconColorMessage
        } else if (type === "iconBackgroundColor") {
            colorPreview = snIconBackgroundColorPreview
            colorTextField = snIconBackgroundColorTextField
            noValidColorMessage = snNoValidIconBackgroundColorMessage
        } else if (type === "buttonTextColor") {
            colorPreview = snButtonTextColorPreview
            colorTextField = snButtonTextColorTextField
            noValidColorMessage = snNoValidButtonTextColorMessage
        } else if (type === "buttonBackgroundColor") {
            colorPreview = snButtonBackgroundColorPreview
            colorTextField = snButtonBackgroundColorTextField
            noValidColorMessage = snNoValidButtonBackgroundColorMessage
        } else if (type === "progressIndicatorColor") {
            colorPreview = snProgressIndicatorColorPreview
            colorTextField = snProgressIndicatorColorTextField
            noValidColorMessage = snNoValidProgressIndicatorColorMessage
        } else if (type === "confirmationScreenBackgroundColor") {
            colorPreview = snConfirmationScreenBackgroundColorPreview
            colorTextField = snConfirmationScreenBackgroundColorTextField
            noValidColorMessage = snNoValidConfirmationScreenBackgroundColorMessage
        } else if (type === "confirmationScreenIconColor") {
            colorPreview = snConfirmationScreenIconColorPreview
            colorTextField = snConfirmationScreenIconColorTextField
            noValidColorMessage = snNoValidConfirmationScreenIconColorMessage
        } else if (type === "confirmationScreenIconBackgroundColor") {
            colorPreview = snConfirmationScreenIconBackgroundColorPreview
            colorTextField = snConfirmationScreenIconBackgroundColorTextField
            noValidColorMessage = snNoValidConfirmationScreenIconBackgroundColorMessage
        } else if (type === "confirmationScreenTextColor") {
            colorPreview = snConfirmationScreenTextColorPreview
            colorTextField = snConfirmationScreenTextColorTextField
            noValidColorMessage = snNoValidConfirmationScreenTextColorMessage
        } else if (type === "confirmationScreenTextBackgroundColor") {
            colorPreview = snConfirmationScreenTextBackgroundColorPreview
            colorTextField = snConfirmationScreenTextBackgroundColorTextField
            noValidColorMessage = snNoValidConfirmationScreenTextBackgroundColorMessage
        } else if (type === "confirmationScreenButtonTextColor") {
            colorPreview = snConfirmationScreenButtonTextColorPreview
            colorTextField = snConfirmationScreenButtonTextColorTextField
            noValidColorMessage = snNoValidConfirmationScreenButtonTextColorMessage
        } else if (type === "confirmationScreenButtonBackgroundColor") {
            colorPreview = snConfirmationScreenButtonBackgroundColorPreview
            colorTextField = snConfirmationScreenButtonBackgroundColorTextField
            noValidColorMessage = snNoValidConfirmationScreenButtonBackgroundColorMessage
        }

        if (isColor(colorTextField.value)) {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = colorTextField.value;
            noValidColorMessage.classList.add('hidden');
        } else if (colorTextField.value.trim() === "") {
            colorPreview.classList.add("hidden")
            noValidColorMessage.classList.add('hidden');
        } else {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = '#94a3b8';
            noValidColorMessage.classList.remove('hidden');
        }
    }

    function isColor(color){
        return /^#[0-9A-F]{6}$/i.test(color);
    }

    function updateColorValue(fieldId, colorValue){
        const textField = document.getElementById(fieldId + 'TextField')
        // Only trigger changeEvent if the color changed
        if (textField !== null && textField.value !== colorValue){
            textField.value = colorValue
            //trigger changeEvent
            const e = new Event("change")
            textField.dispatchEvent(e);
        }

        const colorField = document.getElementById(fieldId + 'ColorField')
        if (colorField !== null) {
            colorField.value = colorValue
        }
    }
});