document.addEventListener('turbolinks:load', () => {
    const dropdownSelect = document.getElementById('dropdown-media-type-select')

    const videoTab = document.getElementById('js-video-tab');
    const imageTab = document.getElementById('js-image-tab');
    const audioTab = document.getElementById('js-audio-tab');

    const videoTabSVG = document.getElementById('js-video-tab-svg');
    const imageTabSVG = document.getElementById('js-image-tab-svg');
    const audioTabSVG = document.getElementById('js-audio-tab-svg');

    const videoDiv = document.getElementById('media_entry_videos');
    const imageDiv = document.getElementById('media_entry_image');
    const audioDiv = document.getElementById('media_entry_audio');

    const hiddenMediaTypeForm = document.getElementById('js-hidden-media-type');

    const mediaEntryVideoSubmit = document.getElementById('media_entry_video_submit');
    const mediaEntryAudioSubmit = document.getElementById('media_entry_audio_submit');
    const mediaEntryImageSubmit = document.getElementById('media_entry_image_submit');

    const quiz_language_00 = document.getElementById('quiz_language_00')
    const quizLanguageList = document.getElementById('quizLanguageList')
    const mediaQuizForm = document.getElementById('mediaQuizForm')
    const mediaQuizList = document.getElementById('mediaQuizList')

    // select initial view to display
    if (hiddenMediaTypeForm != null) {
        console.log("hiddenMediaTypeForm.value: " + hiddenMediaTypeForm.value);
        if (hiddenMediaTypeForm.value === 'video' && videoTab != null) {
            displayVideoTab();
        } else if (hiddenMediaTypeForm.value === 'image' && imageTab != null) {
            displayImageTab();
        } else if (hiddenMediaTypeForm.value === 'audio' && audioTab != null) {
            displayAudioTab();
        }
    }

    // click listener
    if (videoTab != null) {
        videoTab.addEventListener('click', function (event) {
            displayVideoTab();
        });
    }

    if (imageTab != null) {
        imageTab.addEventListener('click', function (event) {
            displayImageTab();
        });
    }

    if (audioTab != null) {
        audioTab.addEventListener('click', function (event) {
            displayAudioTab();
        });
    }

    if (dropdownSelect != null) {
        dropdownSelect.addEventListener('change', function (event) {
            if (this.value === 'video') {
                displayVideoTab();
            } else if (this.value === 'audio') {
                displayAudioTab();
            } else if (this.value === 'image') {
                displayImageTab();
            }
        });
    }

    //Check or uncheck all languages based on no_language
    if (quiz_language_00 !== null && quizLanguageList !== null && mediaQuizForm !== null) {
        let languageList = []
        let language_initial = []
        const quiz_language_00_initial = quiz_language_00.checked
        for(let quiz_lang of quizLanguageList.getElementsByTagName('input')){
            if(quiz_lang !== quiz_language_00) {
                languageList.push(quiz_lang)
                language_initial.push(quiz_lang.checked)
            }
        }
        //Check or uncheck all languages based on no_language
        quiz_language_00.addEventListener('change', function (event){
            for(let quiz_lang of languageList) {
                if (quiz_lang !== quiz_language_00) {
                    quiz_lang.checked = !quiz_language_00.checked;
                }
            }
        })

        //Uncheck no_language if a language is checked
        for(let quiz_lang of languageList) {
            quiz_lang.addEventListener('change', function(event) {
                if(event.target.checked){
                    quiz_language_00.checked = false
                } else {
                    if(languageList.every(checkbox => !checkbox.checked)){
                        quiz_language_00.checked = true
                    }
                }
            })
        }


        //Check for language changes
        mediaQuizForm.addEventListener('submit', function (event){
            if (mediaQuizList === null){
                //No Questions created. Don't have to care about language changes
                return true
            }
            let languagesUpdated = false
            if (quiz_language_00.checked !== quiz_language_00_initial){
                languagesUpdated = true
            }
            let languageRemoved = false
            let languageAdded = false
            for(let i = 0; i < languageList.length; i++) {
                if (languageList[i].checked !== language_initial[i]){
                    if (languageList[i].checked){
                        languageAdded = true
                    } else {
                        languageRemoved = true
                    }
                }
            }

            //Are languages changed?
            if (languagesUpdated || languageAdded || languageRemoved){
                // Make confirm box and write messages depending on the changes
                let confirmMessage = ""
                if (languagesUpdated){
                    if (quiz_language_00.checked) {
                        confirmMessage = I18n.t('js.quiz_confirm_change_to_no_language') + '\n'
                    } else {
                        confirmMessage = I18n.t('js.quiz_confirm_change_from_no_language') + '\n'
                    }
                } else {
                    //At this point, added or removed is always true
                    if (languageAdded){
                        confirmMessage += I18n.t('js.quiz_confirm_language_added') + '\n'
                    }
                    if (languageRemoved) {
                        confirmMessage += I18n.t('js.quiz_confirm_language_removed') + '\n'
                    }
                }
                confirmMessage += I18n.t('js.quiz_confirm_are_you_sure')
                let confirmationBool = confirm(confirmMessage)
                if (!confirmationBool){
                    event.preventDefault();
                }
                return confirmationBool
            } else {
                return true
            }
        })
    }



    // display video tab
    function displayVideoTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "video";
        }

        videoTab.className = "tabActive";
        videoTabSVG.classList.add('tabSVGActive');
        videoTabSVG.classList.remove('tabSVG');

        imageTab.className = 'tab';
        imageTabSVG.classList.remove('tabSVGActive');
        imageTabSVG.classList.add('tabSVG');

        audioTab.className = 'tab';
        audioTabSVG.classList.remove('tabSVGActive');
        audioTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'video';

        videoDiv.style.display = '';
        imageDiv.style.display = 'none';
        audioDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.remove('hidden');
        mediaEntryAudioSubmit.classList.add('hidden');
        mediaEntryImageSubmit.classList.add('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);
    }

    // display image
    function displayImageTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "image";
        }

        imageTab.className = "tabActive";
        imageTabSVG.classList.add('tabSVGActive');
        imageTabSVG.classList.remove('tabSVG')

        videoTab.className = 'tab';
        videoTabSVG.classList.remove('tabSVGActive');
        videoTabSVG.classList.add('tabSVG');

        audioTab.className = 'tab';
        audioTabSVG.classList.remove('tabSVGActive');
        audioTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'image';

        imageDiv.style.display = '';
        videoDiv.style.display = 'none';
        audioDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.add('hidden');
        mediaEntryAudioSubmit.classList.add('hidden');
        mediaEntryImageSubmit.classList.remove('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);

    }

    // display audio
    function displayAudioTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "audio";
        }

        // tabs
        audioTab.className = "tabActive";
        audioTabSVG.classList.add('tabSVGActive');
        audioTabSVG.classList.remove('tabSVG');

        videoTab.className = 'tab';
        videoTabSVG.classList.remove('tabSVGActive');
        videoTabSVG.classList.add('tabSVG');

        imageTab.className = 'tab';
        imageTabSVG.classList.remove('tabSVGActive');
        imageTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'audio';

        // audio div
        audioDiv.style.display = '';
        videoDiv.style.display = 'none';
        imageDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.add('hidden');
        mediaEntryAudioSubmit.classList.remove('hidden');
        mediaEntryImageSubmit.classList.add('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);

    }
});
