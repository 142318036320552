/**
 * JS file for the use with app/view/templates/instant_file_upload_no_label.html.erb
 * uploads file to server an shows spinner while upload
 *
 * Values to set:
 *  id: same value as in instant_file_upload_no_label.html.erb
 */

document.addEventListener('turbolinks:load', () => {
    // set base id und url for upload
    const id ="addBlogImageArea"

    // init elements
    const input = document.getElementById(`${id}Input`)
    const dropZone = document.getElementById(`${id}DropZone`)

    if (input !== null && dropZone !== null) {
        init()
    }

    function init() {

        // upload via input
        input.addEventListener('change', function(e) {
            // prevent other than jpg, png and mp4
            const type = e.target.files[0].type
            if (type !== 'image/png' && type !== 'image/jpeg' && type !== 'video/mp4') {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.wrong_type_alert'));
                return;
            }

            // prevent files larger then 10 mb
            if (e.target.files[0].size/1000000.0 > 20) {
                alert(I18n.t('js.blog_content_max_size_error'))
                return
            }

            // start ajax upload
            ajaxUpload(e.target.files[0], type)
        })

        // highlight drop area green
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
            dropZone.classList.add('bg-green-200')
        };

        //remove green highlight of drop area
        dropZone.ondragleave = function (evt) {
            evt.preventDefault();
            dropZone.classList.remove('bg-green-200')
        };

        //upload via drag&drop
        dropZone.ondrop = function(evt) {
            evt.preventDefault();

            // prevent more than one file
            if (evt.dataTransfer.files.length > 1) {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.more_than_one_file_alert'));
                return;
            }

            // prevent other than jpg, png and mp4
            const type = evt.dataTransfer.files[0].type
            if (type !== 'image/png' && type !== 'image/jpeg' && type !== 'video/mp4') {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.wrong_type_alert'));
                return;
            }

            // prevent files larger then 10 mb
            if (evt.dataTransfer.files[0].size/1000000.0 > 20) {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.blog_content_max_size_error'))
                return
            }

            // start ajax upload
            ajaxUpload(evt.dataTransfer.files[0], type)
        }
    }

    function ajaxUpload(file, type) {

        // show upload spinner
        const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
        const placeholder = document.getElementById(`${id}Placeholder`)
        const uploadText = document.getElementById(`${id}UploadText`)
        const uploadProgress = document.getElementById(`${id}uploadProgress`)
        uploadText.classList.add('hidden')
        uploadSpinner.classList.remove('hidden')
        placeholder.classList.add("hidden")
        uploadProgress.innerHTML = "0%"
        dropZone.classList.remove('bg-green-200')

        // form data from file
        const formData = new FormData();
        formData.append('file_filed', file);
        formData.append('usage', id)

        // rails id of object
        let objectId = ""
        const rootDiv = document.getElementById(`${id}Div`)
        objectId = rootDiv.dataset.id

        // type determines upload url
        let url = ''
        if (type === 'image/png' || type === 'image/jpeg') {
            url = `/admin/blog_entries/${objectId}/add_blog_image`
        } else if (type === 'video/mp4') {
            url = `/admin/blog_entries/${objectId}/add_blog_video`
        }

        // do ajax upload
        $.rails.ajax({
            url: url,
            type: "post",
            data: formData,
            async: true,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: true,
            success: function (response, status) {
            },
            error: function (response, status) {
            },
            beforeSend: function(xhr) {
                // show upload progress
                xhr.upload.addEventListener('progress', function(e) {
                    const percent_complete = Math.floor((e.loaded / e.total)*100);  // Percentage of upload
                    uploadProgress.innerHTML = `${percent_complete}%`
                })
                return true
            }
        })
    }


})