/**
 * JS file for the use with app/view/templates/_file_upload.html.erb
 * displayes name of selectedTdropped file and show upload spinner on save
 *
 * Copy file and set 'Values to set'
 *
 * Values to set:
 *  id: same value as in _file_upload.html.erb
 */



document.addEventListener('turbolinks:load', () => {
    // set base id und url for upload
    const id = "ccswUpload"


    // js Upload area
    const jsUpload = document.getElementById(`${id}JsUpload`)
    const noJsUpload = document.getElementById(`${id}NoJsUpload`)
    const currentFile = document.getElementById(`${id}CurrentFile`)
    const input = document.getElementById(`${id}Input`)
    const infoArea = document.getElementById(`${id}InfoArea`)
    const spinner = document.getElementById(`${id}Spinner`)
    const dropZone = document.getElementById(`${id}DropZone`)

    //save button - outside of template. It's not guaranteed, that they use the correct name schematic
    const saveCcsw = document.getElementById("saveCcsw")

    /**
     * add individual needs here
     */
    const contentChargerSoftwareVersion = document.getElementById("contentChargerSoftwareVersion")


    if (noJsUpload !== null && jsUpload !== null && currentFile !== null &&
        input!== null && saveCcsw !== null && infoArea !== null && spinner &&
        contentChargerSoftwareVersion !== null && dropZone !== null) {

        showJsUpload()
        showSelectedFile()
        showUploadSpinner()
        dragAndDrop()
    }

    // hide normal upload area and show js upload
    function showJsUpload() {
        noJsUpload.classList.add("hidden")
        jsUpload.classList.remove("hidden")
    }

    // handle file slect
    function showSelectedFile() {
        input.addEventListener('change', function(e) {
            const fileName = e.target.files[0].name

            /**
             * add individual needs here
             */
            // only allow files without filetype
            let type = ""
            if (fileName.includes(".")) {
                type = fileName.split('.').pop()
            }
            if (type !== "") {
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", "ContentCharger Update"));
                return;
            }

            // display name of selected file
            currentFile.innerHTML = I18n.t('js.selected_file') + input.value.replace(/^.*[\\\/]/, '');
        });
    }

    // show spinner while uploading
    function showUploadSpinner () {
        saveCcsw.addEventListener('click', function(e) {

            // if clause needed since 'form.number_field' will prevent upload
            if (contentChargerSoftwareVersion.value >= 10000 ) {
                // show upload spinner
                infoArea.classList.add("hidden")
                spinner.classList.remove("hidden")
            }
        });
    }

    function dragAndDrop() {
        //upload via drag&drop
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
            dropZone.classList.add('bg-green-200')
        };

        dropZone.ondragleave = function (evt) {
            evt.preventDefault();
            dropZone.classList.remove('bg-green-200')
        };

        dropZone.ondrop = function(evt) {
            evt.preventDefault();
            const fileName = evt.dataTransfer.files[0].name

            // only allow a single file
            if (evt.dataTransfer.files.length > 1) {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.more_than_one_file_alert'));
                return;
            }

            /**
             * add individual needs here
             */
            // only allow files without filetype
            let type = ""
            if (fileName.includes(".")) {
                type = fileName.split('.').pop()
            }
            if (type !== "") {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", "ContentCharger Update"));
                return;
            }

            // display name and select dropped file
            dropZone.classList.remove('bg-green-200')
            input.files = evt.dataTransfer.files
            currentFile.innerHTML = I18n.t('js.selected_file') + input.value.replace(/^.*[\\\/]/, '');


            /**
             * Add individual needs here
             */
        }
    }

});