document.addEventListener('turbolinks:load', () => {
    const updateStatisticsButton = document.getElementById('updateStatisticsButton')
    const updateStatisticsSpinner = document.getElementById('updateStatisticsSpinner')
    const statisticUpdateErrorDiv = document.getElementById('statisticUpdateErrorDiv')
    const statisticUpdateErrorDetails = document.getElementById('statisticUpdateErrorDetails')

    const requestStatisticDownloadButton = document.getElementById('requestStatisticDownloadButton')
    const prepareStatisticDownloadSpinner = document.getElementById('prepareStatisticDownloadSpinner')
    const downloadingStatisticsSpinner = document.getElementById('downloadingStatisticsSpinner')
    const statisticDownloadLink = document.getElementById('statisticDownloadLink')

    if (updateStatisticsButton !== null && updateStatisticsSpinner !== null && statisticUpdateErrorDiv !== null &&
        statisticUpdateErrorDetails !== null) {

        // add click listener to request latest stats via ajax
        updateStatisticsButton.addEventListener('click', function () {

            // hide button, show spinner
            updateStatisticsButton.classList.add("hidden")
            updateStatisticsSpinner.classList.remove("hidden")
            statisticUpdateErrorDiv.classList.add("hidden")

            // disable download button
            disableButton(requestStatisticDownloadButton)

            // request update
            $.rails.ajax({
                url: '/statistics/request_latest_data',
                type: "get",
                data: "",
            })

            // wait 5 seconds and than check if stats update is done
            checkUpdateState();

        });
    }


    if (requestStatisticDownloadButton !== null && prepareStatisticDownloadSpinner !== null &&
        downloadingStatisticsSpinner !== null && statisticDownloadLink !== null) {
        requestStatisticDownloadButton.addEventListener('click', function () {

            // hide button, show spinner
            requestStatisticDownloadButton.classList.add("hidden")
            prepareStatisticDownloadSpinner.classList.remove("hidden")

            // disable update button
            disableButton(updateStatisticsButton)

            // request download
            $.rails.ajax({
                url: '/statistics/request_download',
                type: "get",
                data: "",
            })

            // wait 5 seconds and than check if download is ready
            checkExportState();
        })
    }

    function checkExportState() {
        let i = setInterval(function() {
            $.rails.ajax({
                url: '/statistics/export_state',
                type: "get",
                data: "",
                success: function (response, status) {
                    // reload page if done to display latest stats
                    if (response.export_state === "DOWNLOAD_READY") {
                        // requestStatisticDownloadButton.classList.remove("hidden")
                        prepareStatisticDownloadSpinner.classList.add("hidden")
                        downloadingStatisticsSpinner.classList.remove('hidden')
                        statisticDownloadLink.href = response.export_download_path.replace("./public", "").replace("/app/public", "")
                        statisticDownloadLink.click()

                    } else if (response.export_state === "DONE") {
                        downloadingStatisticsSpinner.classList.add('hidden')
                        requestStatisticDownloadButton.classList.remove("hidden")
                        enableButton(updateStatisticsButton)
                        clearInterval(i)
                    }
                }
            })
        }, 2500)
    }

    function checkUpdateState() {
        // check every 2,5 seconds if stats update is done
        let i = setInterval(function() {
            $.rails.ajax({
                url: '/statistics/update_state',
                type: "get",
                data: "",
                success: function (response, status) {
                    // reload page if done to display latest stats
                    if (response.update_state === "DONE") {
                        clearInterval(i)
                        window.location.reload();
                    } else if (response.update_state.includes("ERROR")) {
                        clearInterval(i)
                        updateStatisticsButton.classList.remove("hidden")
                        updateStatisticsSpinner.classList.add("hidden")
                        statisticUpdateErrorDiv.classList.remove("hidden")
                        statisticUpdateErrorDetails.innerHTML = response.update_state
                        enableButton(requestStatisticDownloadButton)
                    }
                }
            })
        }, 2500)
    }

    function disableButton(button) {
        button.disabled = true
        button.classList.add("bg-gray-500", "cursor-default")
        button.classList.remove("bg-blue-600", "hover:bg-blue-700")
    }

    function enableButton(button) {
        button.disabled = false
        button.classList.remove("bg-gray-500", "cursor-default")
        button.classList.add("bg-blue-600", "hover:bg-blue-700")
    }
});


