/**
 * this file handles the
 * - drag&drop of the preview area
 * - the listeners for the input fields
 */
document.addEventListener('turbolinks:load', () => {

    //##################################
    // init UI elements
    //##################################

    //#################
    // Drag&Drop
    //################
    const safetyNoticePreviewDragArea = document.getElementById('safetyNoticePreviewDragArea')
    let previewPosX = 30 // corresponds with style right of safetyNoticePreviewArea
    let previewPosY = 100 // corresponds with style top of safetyNoticePreviewArea
    let dragStartX
    let dragStartY
    let dragStarted = false

    // #############
    // show and hide of preview
    // #############
    const showSafetyNoticePreviewButton = document.getElementById('showSafetyNoticePreviewButton')
    const safetyNoticePreviewArea = document.getElementById('safetyNoticePreviewArea')
    const safetyNoticePreviewAreaClose = document.getElementById('safetyNoticePreviewAreaClose')

    //#################
    // input fields for listeners
    //################
    // background (notice)
    const backgroundColorTextField = document.getElementById('snBackgroundColorTextField');
    //headline (notice)
    const snHeadlineTextColorTextField = document.getElementById('snHeadlineTextColorTextField');
    const snHeadlineBackgroundTransparentButton = document.getElementById('snHeadlineBackgroundTransparentButton')
    const snHeadlineBackgroundTransparent = document.getElementById('snHeadlineBackgroundTransparent')
    const snHeadlineBackgroundColorTextField = document.getElementById('snHeadlineBackgroundColorTextField');
    const snHeadlineAlignmentSelect = document.getElementById('snHeadlineAlignmentSelect')
    // description (notice)
    const snDescriptionTextColorTextField = document.getElementById('snDescriptionTextColorTextField');
    const snDescriptionBackgroundTransparentButton = document.getElementById('snDescriptionBackgroundTransparentButton')
    const snDescriptionBackgroundTransparent = document.getElementById('snDescriptionBackgroundTransparent')
    const snDescriptionBackgroundColorTextField = document.getElementById('snDescriptionBackgroundColorTextField');
    const snDescriptionAlignmentSelect = document.getElementById('snDescriptionAlignmentSelect')
    // icon (notice)
    const snIconColorTextField = document.getElementById('snIconColorTextField');
    const snIconBackgroundTransparentButton = document.getElementById('snIconBackgroundTransparentButton')
    const snIconBackgroundTransparent = document.getElementById('snIconBackgroundTransparent')
    const snIconBackgroundColorTextField = document.getElementById('snIconBackgroundColorTextField');
    // Button (notice)
    const snButtonTextColorTextField = document.getElementById('snButtonTextColorTextField');
    const snButtonBackgroundTransparentButton = document.getElementById('snButtonBackgroundTransparentButton')
    const snButtonBackgroundTransparent = document.getElementById('snButtonBackgroundTransparent')
    const snButtonBackgroundColorTextField = document.getElementById('snButtonBackgroundColorTextField');
    //progress_indicator (notice)
    const snProgressIndicatorColorTextField = document.getElementById('snProgressIndicatorColorTextField');
    //next text (notice)
    const snContinueTextTextField = document.getElementById('snContinueTextTextField')

    // change on confirm
    const snConfirmationScreenChange = document.getElementById('snConfirmationScreenChange')
    const snConfirmationScreenChangeButton = document.getElementById('snConfirmationScreenChangeButton')

    // background (confirm)
    const snConfirmationScreenBackgroundColorTextField = document.getElementById('snConfirmationScreenBackgroundColorTextField');
    // icon (confirm)
    const snConfirmationScreenIconColorTextField = document.getElementById('snConfirmationScreenIconColorTextField')
    const snConfirmationScreenIconBackgroundColorTextField = document.getElementById('snConfirmationScreenIconBackgroundColorTextField')
    const snConfirmationScreenIconBackgroundTransparentButton = document.getElementById('snConfirmationScreenIconBackgroundTransparentButton')
    const snConfirmationScreenIconBackgroundTransparent = document.getElementById('snConfirmationScreenIconBackgroundTransparent')
    // checkbox (confirm)
    const snConfirmationScreenTextColorTextField = document.getElementById('snConfirmationScreenTextColorTextField');
    const snConfirmationScreenTextBackgroundColorTextField = document.getElementById('snConfirmationScreenTextBackgroundColorTextField');
    const snConfirmationScreenTextBackgroundTransparentButton = document.getElementById('snConfirmationScreenTextBackgroundTransparentButton')
    const snConfirmationScreenTextBackgroundTransparent = document.getElementById('snConfirmationScreenTextBackgroundTransparent')
    // button (confirm)
    const snConfirmationScreenButtonTextColorTextField = document.getElementById('snConfirmationScreenButtonTextColorTextField');
    const snConfirmationScreenButtonBackgroundColorTextField = document.getElementById('snConfirmationScreenButtonBackgroundColorTextField');
    const snConfirmationScreenButtonBackgroundTransparentButton = document.getElementById('snConfirmationScreenButtonBackgroundTransparentButton')
    const snConfirmationScreenButtonBackgroundTransparent = document.getElementById('snConfirmationScreenButtonBackgroundTransparent')
    const snConfirmationScreenButtonTextTextField = document.getElementById('snConfirmationScreenButtonTextTextField')
    // checkbox Text (confirm)
    const snConfirmationCheckboxTextTextField = document.getElementById('snConfirmationCheckboxTextTextField')



    //###################
    // Preview UI elements.
    // Set through initNoticePreviewElements and initConfirmPreviewElements() via confirm_preview.js.erb and notice_preview.js.erb
    // when update UI is inserted
    // #################
    // notice
    let safetyNoticePreviewScreen = document.getElementById('safetyNoticePreviewScreen')
    let safetyNoticePreviewHeading = document.getElementById('safetyNoticePreviewHeading')
    let safetyNoticePreviewHeadingSpan = document.getElementById('safetyNoticePreviewHeadingSpan')
    let safetyNoticePreviewDescription = document.getElementById('safetyNoticePreviewDescription')
    let safetyNoticePreviewDescriptionSpan = document.getElementById('safetyNoticePreviewDescriptionSpan')
    let safetyNoticePreviewIconCircle = document.getElementById('safetyNoticePreviewIconCircle')
    let safetyNoticePreviewNextButton = document.getElementById('safetyNoticePreviewNextButton')
    let safetyNoticePreviewProgressIndicator = document.getElementById('safetyNoticePreviewProgressIndicator')
    let safetyNoticeVideoReplayButton = document.getElementById('safetyNoticeVideoReplayButton')
    let safetyNoticePreviewNextButtonText = document.getElementById('safetyNoticePreviewNextButtonText')

    // confirm
    let safetyConfirmPreviewScreen
    let safetyConfirmPreviewIconCircle
    let safetyConfirmCheckbox
    let safetyConfirmCheckboxInput
    let safetyConfirmCheckboxText
    let safetyConfirmPreviewNextButton

    if (showSafetyNoticePreviewButton !== null && safetyNoticePreviewArea !== null && safetyNoticePreviewDragArea !== null &&
        safetyNoticePreviewAreaClose !== null && backgroundColorTextField !== null &&
        snHeadlineTextColorTextField !== null && snHeadlineBackgroundTransparentButton !== null &&
        snHeadlineBackgroundTransparent !== null && snHeadlineBackgroundColorTextField !== null &&
        snHeadlineAlignmentSelect !== null && snDescriptionTextColorTextField !== null &&
        snDescriptionBackgroundTransparentButton !== null && snDescriptionBackgroundTransparent !== null &&
        snDescriptionBackgroundColorTextField!== null && snDescriptionAlignmentSelect !== null &&
        snIconColorTextField !== null && snIconBackgroundTransparentButton !== null &&
        snIconBackgroundTransparent !== null && snIconBackgroundColorTextField !== null &&
        snButtonTextColorTextField !== null && snButtonBackgroundTransparentButton !== null &&
        snButtonBackgroundTransparent !== null && snButtonBackgroundColorTextField !== null &&
        snContinueTextTextField !== null && snProgressIndicatorColorTextField !== null &&
        snConfirmationScreenChange !== null && snConfirmationScreenChangeButton !== null &&
        snConfirmationScreenBackgroundColorTextField !== null && snConfirmationScreenIconColorTextField !== null &&
        snConfirmationScreenIconBackgroundColorTextField !== null && snConfirmationScreenIconBackgroundTransparentButton !== null &&
        snConfirmationScreenIconBackgroundTransparent !== null && snConfirmationScreenTextColorTextField !== null &&
        snConfirmationScreenTextBackgroundColorTextField !== null && snConfirmationScreenTextBackgroundTransparentButton !== null &&
        snConfirmationScreenTextBackgroundTransparent !== null && snConfirmationScreenButtonTextColorTextField !== null &&
        snConfirmationScreenButtonBackgroundColorTextField !== null && snConfirmationScreenButtonBackgroundTransparentButton !== null &&
        snConfirmationScreenButtonBackgroundTransparent !== null && snConfirmationScreenButtonTextTextField !== null &&
        snConfirmationCheckboxTextTextField !== null) {
        init()
    }


    function init() {
        safetyNoticePreviewDragAndDrop()
        safeTyNoticePreviewHideAndShow()
        addListener()
    }

    /**
     * display and drag&drop of preview
     */
    function safeTyNoticePreviewHideAndShow() {
        showSafetyNoticePreviewButton.addEventListener('click', function () {
            showSafetyNoticePreviewButton.blur()
            safetyNoticePreviewArea.classList.remove("hidden")
        })

        safetyNoticePreviewAreaClose.addEventListener('click', function () {
            safetyNoticePreviewArea.classList.add("hidden")
        })
    }

    function safetyNoticePreviewDragAndDrop() {
        safetyNoticePreviewDragArea.addEventListener("mousedown", function (ev) {
            dragStarted = true
            dragStartY = ev.clientY
            dragStartX = ev.clientX
        })

        window.addEventListener('mouseup', function() {
            if (dragStarted) {
                previewPosX = previewPosX +  (dragStartX - event.clientX)
                previewPosY = previewPosY - (dragStartY - event.clientY)
            }
            dragStarted = false;
        });

        window.addEventListener('mousemove', function() {
            if (dragStarted) {
                window.getSelection().removeAllRanges()
                safetyNoticePreviewArea.style.top = previewPosY - (dragStartY - event.clientY) + 'px';
                safetyNoticePreviewArea.style.right = previewPosX +  (dragStartX - event.clientX) + 'px';
            }
        })
    }

    /**
     * add Listener for all setup options
     */
    function addListener() {
        addNoticePreviewListener()
        addConfirmPreviewListener()

        // update UI snConfirmationScreenChange is eb/disabled
        snConfirmationScreenChangeButton.addEventListener('click', function() {
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null) {
                // only if checkbox is currently displayed (_confirm_preview.html.erb)

                if (safetyConfirmCheckboxInput.checked && !snConfirmationScreenChange.checked) {
                    // use changed style
                    useConfirmPreviewElementsChangedStyle()
                    safetyConfirmCheckboxInput.style.backgroundImage = `url("data:image/svg+xml,%3csvg viewBox='0 0 200 200'  fill='${snConfirmationScreenTextColorTextField.value.replace('#', '%23')}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 0.00,0.00 C 0.00,0.00 200.00,0.00 200.00,0.00 200.00,0.00 200.00,200.00 200.00,200.00 200.00,200.00 0.00,200.00 0.00,200.00 0.00,200.00 0.00,0.00 0.00,0.00 Z M 140.00,56.42 C 132.99,58.88 128.14,64.86 123.00,70.00 123.00,70.00 97.00,96.00 97.00,96.00 94.48,98.52 84.60,109.12 82.00,109.80 78.74,110.66 75.07,106.06 73.00,104.00 67.95,98.97 62.96,92.60 55.00,93.56 47.77,94.43 42.68,100.73 43.56,108.00 44.20,113.35 47.42,116.36 51.00,120.00 51.00,120.00 67.00,136.00 67.00,136.00 70.95,139.89 74.17,143.39 80.00,143.89 89.51,144.71 95.94,136.06 102.00,130.00 102.00,130.00 137.00,95.00 137.00,95.00 141.21,90.79 152.20,80.43 154.77,76.00 160.27,66.56 154.55,54.34 140.00,56.42 Z'/%3e%3c/svg%3e")`
                } else if (safetyConfirmCheckboxInput.checked) {
                    // use default style
                    useConfirmPreviewElementsDefaultStyle()
                    safetyConfirmCheckboxInput.style.backgroundImage = `url("data:image/svg+xml,%3csvg viewBox='0 0 200 200'  fill='${snDescriptionTextColorTextField.value.replace('#', '%23')}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 0.00,0.00 C 0.00,0.00 200.00,0.00 200.00,0.00 200.00,0.00 200.00,200.00 200.00,200.00 200.00,200.00 0.00,200.00 0.00,200.00 0.00,200.00 0.00,0.00 0.00,0.00 Z M 140.00,56.42 C 132.99,58.88 128.14,64.86 123.00,70.00 123.00,70.00 97.00,96.00 97.00,96.00 94.48,98.52 84.60,109.12 82.00,109.80 78.74,110.66 75.07,106.06 73.00,104.00 67.95,98.97 62.96,92.60 55.00,93.56 47.77,94.43 42.68,100.73 43.56,108.00 44.20,113.35 47.42,116.36 51.00,120.00 51.00,120.00 67.00,136.00 67.00,136.00 70.95,139.89 74.17,143.39 80.00,143.89 89.51,144.71 95.94,136.06 102.00,130.00 102.00,130.00 137.00,95.00 137.00,95.00 141.21,90.79 152.20,80.43 154.77,76.00 160.27,66.56 154.55,54.34 140.00,56.42 Z'/%3e%3c/svg%3e")`
                } else {
                    // only update checkbox
                    safetyConfirmCheckboxInput.style.backgroundImage = 'none'
                }
            }
        })
    }
    function addNoticePreviewListener() {
        // background
        backgroundColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewScreen, "background", false, backgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewScreen, "background", false, this.value)
            }
        });

        // ################
        // ### headline ###
        //#################
        snHeadlineTextColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewHeading, "color", false, snHeadlineTextColorTextField.value)
        });
        snHeadlineBackgroundColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewHeadingSpan, "background",
                snHeadlineBackgroundTransparent.checked, snHeadlineBackgroundColorTextField.value)
        });
        snHeadlineBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snHeadlineBackgroundTransparent').checked
            updatePreviewTransparency(transparent, safetyNoticePreviewHeadingSpan, snHeadlineBackgroundColorTextField.value)
        });
        snHeadlineAlignmentSelect.addEventListener('change', function (e) {
            updatePreviewTextAlignment(this.value, safetyNoticePreviewHeading)
        })

        // ################
        // ### description ###
        //#################
        snDescriptionTextColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewDescription, "color", false, snDescriptionTextColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmCheckboxText, "color", false, this.value)
                updatePreviewColor(safetyConfirmCheckboxInput, "border", false, this.value)
            }
        });
        snDescriptionBackgroundColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewDescriptionSpan, "background",
                snDescriptionBackgroundTransparent.checked, snDescriptionBackgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmCheckbox, "background",
                    snDescriptionBackgroundTransparent.checked, this.value)
            }
        });
        snDescriptionBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snDescriptionBackgroundTransparent').checked
            updatePreviewTransparency(transparent, safetyNoticePreviewDescriptionSpan, snDescriptionBackgroundColorTextField.value)

            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snDescriptionBackgroundTransparent.checked, safetyConfirmCheckbox, snDescriptionBackgroundColorTextField.value)
            }
        });
        snDescriptionAlignmentSelect.addEventListener('change', function (e) {
            updatePreviewTextAlignment(this.value, safetyNoticePreviewDescription)
        })

        // ################
        // ### icon ###
        //#################
        snIconColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewIconCircle, "color", false, snIconColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewIconCircle, "color", false, this.value)
            }
        });
        snIconBackgroundColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewIconCircle, "background",
                snIconBackgroundTransparent.checked, snIconBackgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewIconCircle, "background",
                    snIconBackgroundTransparent.checked, this.value)
            }
        });
        snIconBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snIconBackgroundTransparent').checked
            updatePreviewTransparency(transparent, safetyNoticePreviewIconCircle, snIconBackgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snIconBackgroundTransparent.checked,
                    safetyConfirmPreviewIconCircle, snIconBackgroundColorTextField.value)
            }
        });

        // ################
        // ### button ###
        //#################
        snButtonTextColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewNextButton, "color", false, snButtonTextColorTextField.value)
            updatePreviewColor(safetyNoticeVideoReplayButton, "color", false, snButtonTextColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewNextButton, "color", false, this.value)
            }
        });
        snButtonBackgroundColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewNextButton, "background",
                snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)
            updatePreviewColor(safetyNoticeVideoReplayButton, "background",
                snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewNextButton, "background",
                    snButtonBackgroundTransparent.checked, this.value)
            }
        });
        snButtonBackgroundTransparentButton.addEventListener("click", function() {
            const transparent =  !document.getElementById('snButtonBackgroundTransparent').checked
            updatePreviewTransparency(transparent, safetyNoticePreviewNextButton, snButtonBackgroundColorTextField.value)
            updatePreviewTransparency(transparent, safetyNoticeVideoReplayButton, snButtonBackgroundColorTextField.value)

            // update confirm_preview if visible and change is disabled
            safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
            if (safetyConfirmCheckboxInput !== null && !safetyConfirmCheckboxInput.checked || !snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snButtonBackgroundTransparent.checked, safetyConfirmPreviewNextButton, snButtonBackgroundColorTextField.value)
            }
        });

        // ################
        // ### ProgressIndicator  ###
        //#################
        snProgressIndicatorColorTextField.addEventListener("change", function() {
            updatePreviewColor(safetyNoticePreviewProgressIndicator, "color", false,
                snProgressIndicatorColorTextField.value)
            updatePreviewColor(safetyNoticePreviewProgressIndicator, "border", false,
                snProgressIndicatorColorTextField.value)
        });

        // ################
        // ### ContinueText  ###
        //#################
        snContinueTextTextField.addEventListener("change", function() {
            // set text
            safetyNoticePreviewNextButtonText.innerHTML = snContinueTextTextField.value
            // transform if video
            const dataType = safetyNoticePreviewScreen.dataset.dataType
            if (dataType === 'VIDEO') {
                transformVideoNextButton()
            }
        })
    }
    // add listeners
    function addConfirmPreviewListener() {
        // Background
        snConfirmationScreenBackgroundColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewScreen, "background", false, snConfirmationScreenBackgroundColorTextField.value)
            }
        })
        // ################
        // ### icon ###
        //#################
        snConfirmationScreenIconColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewIconCircle, "color", false, this.value)
            }
        })
        snConfirmationScreenIconBackgroundColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewIconCircle, "background",
                    snConfirmationScreenIconBackgroundTransparent.checked, this.value)
            }
        })
        snConfirmationScreenIconBackgroundTransparentButton.addEventListener("click", function() {
            // let transparent =  document.getElementById('snIconBackgroundTransparent').checked
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snConfirmationScreenIconBackgroundTransparent.checked, safetyConfirmPreviewIconCircle, snConfirmationScreenIconBackgroundColorTextField.value)
            }
        })

        // ################
        // ### text ###
        //#################
        snConfirmationScreenTextColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmCheckboxText, "color", false, this.value)
                updatePreviewColor(safetyConfirmCheckboxInput, "border", false, this.value)
                safetyConfirmCheckboxInput.style.backgroundImage = `url("data:image/svg+xml,%3csvg viewBox='0 0 200 200'  fill='${this.value.replace('#', '%23')}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 0.00,0.00 C 0.00,0.00 200.00,0.00 200.00,0.00 200.00,0.00 200.00,200.00 200.00,200.00 200.00,200.00 0.00,200.00 0.00,200.00 0.00,200.00 0.00,0.00 0.00,0.00 Z M 140.00,56.42 C 132.99,58.88 128.14,64.86 123.00,70.00 123.00,70.00 97.00,96.00 97.00,96.00 94.48,98.52 84.60,109.12 82.00,109.80 78.74,110.66 75.07,106.06 73.00,104.00 67.95,98.97 62.96,92.60 55.00,93.56 47.77,94.43 42.68,100.73 43.56,108.00 44.20,113.35 47.42,116.36 51.00,120.00 51.00,120.00 67.00,136.00 67.00,136.00 70.95,139.89 74.17,143.39 80.00,143.89 89.51,144.71 95.94,136.06 102.00,130.00 102.00,130.00 137.00,95.00 137.00,95.00 141.21,90.79 152.20,80.43 154.77,76.00 160.27,66.56 154.55,54.34 140.00,56.42 Z'/%3e%3c/svg%3e")`
            }
        })
        snConfirmationScreenTextBackgroundColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmCheckbox, "background",
                    snConfirmationScreenTextBackgroundTransparent.checked, this.value)
            }
        })
        snConfirmationScreenTextBackgroundTransparentButton.addEventListener("click", function() {
            //let transparent =  document.getElementById('snDescriptionBackgroundTransparent').checked
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snConfirmationScreenTextBackgroundTransparent.checked, safetyConfirmCheckbox, snConfirmationScreenTextBackgroundColorTextField.value)
            }
        })

        // ################
        // ### button ###
        //#################
        snConfirmationScreenButtonTextColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewNextButton, "color", false, this.value)
            }
        })
        snConfirmationScreenButtonBackgroundColorTextField.addEventListener("change", function() {
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewColor(safetyConfirmPreviewNextButton, "background",
                    snConfirmationScreenButtonBackgroundTransparent.checked, this.value)
            }
        })
        snConfirmationScreenButtonBackgroundTransparentButton.addEventListener("click", function() {
            // let transparent =  document.getElementById('snButtonBackgroundTransparent').checked
            if (safetyConfirmCheckboxInput.checked && snConfirmationScreenChange.checked) {
                updatePreviewTransparency(!snConfirmationScreenButtonBackgroundTransparent.checked, safetyConfirmPreviewNextButton, snConfirmationScreenButtonBackgroundColorTextField.value)
            }
        })
        snConfirmationScreenButtonTextTextField.addEventListener("change", function() {
            // set text
            safetyConfirmPreviewNextButton.innerHTML = snConfirmationScreenButtonTextTextField.value
        })

        // ################
        // ### checkbox ###
        // ################
        snConfirmationCheckboxTextTextField.addEventListener("change", function() {
            // set text
            safetyConfirmCheckboxText.innerHTML = snConfirmationCheckboxTextTextField.value
        })
    }

    /**
     * Init alle changeable ui elements of the _notice_preview.html.erb
     * and use the current style values
     * called via notice_preview.js.erb
     */
    function initNoticePreview() {
        initNoticePreviewElements()
        useNoticePreviewElementsStyle()
    }
    window.initNoticePreview = initNoticePreview; // provide for notice_preview.js.erb

    // init elements
    function initNoticePreviewElements() {
        safetyNoticePreviewScreen = document.getElementById('safetyNoticePreviewScreen')
        safetyNoticePreviewHeading = document.getElementById('safetyNoticePreviewHeading')
        safetyNoticePreviewHeadingSpan = document.getElementById('safetyNoticePreviewHeadingSpan')
        safetyNoticePreviewDescription = document.getElementById('safetyNoticePreviewDescription')
        safetyNoticePreviewDescriptionSpan = document.getElementById('safetyNoticePreviewDescriptionSpan')
        safetyNoticePreviewIconCircle = document.getElementById('safetyNoticePreviewIconCircle')
        safetyNoticePreviewNextButton = document.getElementById('safetyNoticePreviewNextButton')
        safetyNoticePreviewProgressIndicator = document.getElementById('safetyNoticePreviewProgressIndicator')
        safetyNoticeVideoReplayButton = document.getElementById('safetyNoticeVideoReplayButton')
        safetyNoticePreviewNextButtonText = document.getElementById('safetyNoticePreviewNextButtonText')
    }

    // set style
    function useNoticePreviewElementsStyle() {
        const dataType = safetyNoticePreviewScreen.dataset.dataType
        if (dataType === 'TEXT_WITH_ICON') {
            // Background
            updatePreviewColor(safetyNoticePreviewScreen, "background", false, backgroundColorTextField.value)

            // ################
            // ### headline ###
            // #################
            updatePreviewColor(safetyNoticePreviewHeading, "color", false, snHeadlineTextColorTextField.value)
            updatePreviewColor(safetyNoticePreviewHeadingSpan, "background",
                snHeadlineBackgroundTransparent.checked, snHeadlineBackgroundColorTextField.value)
            updatePreviewTransparency(snHeadlineBackgroundTransparent.checked, safetyNoticePreviewHeadingSpan,
                snHeadlineBackgroundColorTextField.value)
            updatePreviewTextAlignment(snHeadlineAlignmentSelect.value, safetyNoticePreviewHeading)

            // ################
            // ### description ###
            //#################
            updatePreviewColor(safetyNoticePreviewDescription, "color", false, snDescriptionTextColorTextField.value)
            updatePreviewColor(safetyNoticePreviewDescriptionSpan, "background",
                snDescriptionBackgroundTransparent.checked, snDescriptionBackgroundColorTextField.value)
            updatePreviewTransparency(snDescriptionBackgroundTransparent.checked, safetyNoticePreviewDescriptionSpan,
                snDescriptionBackgroundColorTextField.value)
            updatePreviewTextAlignment(snDescriptionAlignmentSelect.value, safetyNoticePreviewDescription)

            // ################
            // ### icon ###
            //#################
            updatePreviewColor(safetyNoticePreviewIconCircle, "color", false, snIconColorTextField.value)
            updatePreviewColor(safetyNoticePreviewIconCircle, "background",
                snIconBackgroundTransparent.checked, snIconBackgroundColorTextField.value)
            updatePreviewTransparency(snIconBackgroundTransparent.checked, safetyNoticePreviewIconCircle,
                snIconBackgroundColorTextField.value)

            // ################
            // ### button ###
            //#################
            updatePreviewColor(safetyNoticePreviewNextButton, "color", false, snButtonTextColorTextField.value)
            updatePreviewColor(safetyNoticePreviewNextButton, "background",
                snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)
            updatePreviewTransparency(snButtonBackgroundTransparent.checked, safetyNoticePreviewNextButton,
                snButtonBackgroundColorTextField.value)

            // ################
            // ### ProgressIndicator  ###
            //#################
            updatePreviewColor(safetyNoticePreviewProgressIndicator, "color", false,
                snProgressIndicatorColorTextField.value)
            updatePreviewColor(safetyNoticePreviewProgressIndicator, "border", false,
                snProgressIndicatorColorTextField.value)

        } else { // dataType === 'VIDEO'
            updatePreviewColor(safetyNoticePreviewScreen, "background", false, "#000000")
            // ################
            // ### next button ###
            //#################
            updatePreviewColor(safetyNoticePreviewNextButton, "color", false, snButtonTextColorTextField.value)
            updatePreviewColor(safetyNoticePreviewNextButton, "background",
                snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)
            updatePreviewTransparency(snButtonBackgroundTransparent.checked, safetyNoticePreviewNextButton,
                snButtonBackgroundColorTextField.value)

            // ################
            // ### replay button ###
            //#################
            updatePreviewColor(safetyNoticeVideoReplayButton, "color", false, snButtonTextColorTextField.value)
            updatePreviewColor(safetyNoticeVideoReplayButton, "background",
                snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)
            updatePreviewTransparency(snButtonBackgroundTransparent.checked, safetyNoticeVideoReplayButton,
                snButtonBackgroundColorTextField.value)
        }

        safetyNoticePreviewNextButtonText.innerHTML = snContinueTextTextField.value
    }


    /**
     * Init alle changeable ui elements of the _confirmation_preview.html.erb
     * and use the current style values
     * called via confirm_preview.js.erb
     */
    function initConfirmPreview() {
        initConfirmPreviewElements()
        useConfirmPreviewElementsDefaultStyle()

        safetyConfirmCheckboxInput.addEventListener('change', function() {
            changeConfirmScreenStyle(this.checked)
        })
    }
    window.initConfirmPreview = initConfirmPreview; // provide for confirm_preview.js.erb

    // init elements
    function initConfirmPreviewElements() {
        safetyConfirmPreviewScreen = document.getElementById('safetyConfirmPreviewScreen')
        safetyConfirmPreviewIconCircle = document.getElementById('safetyConfirmPreviewIconCircle')
        safetyConfirmCheckbox = document.getElementById('safetyConfirmCheckbox')
        safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
        safetyConfirmCheckboxText = document.getElementById('safetyConfirmCheckboxText')
        safetyConfirmPreviewNextButton = document.getElementById('safetyConfirmPreviewNextButton')
    }

    // set style
    // called from confirm_preview.js.erb or if snConfirmationScreenChangeButton or safetyConfirmCheckboxInput is changed
    function useConfirmPreviewElementsDefaultStyle() {
        // background
        updatePreviewBackground(safetyConfirmPreviewScreen, "notice", backgroundColorTextField.value)

        // ################
        // ### icon ###
        //#################
        updatePreviewColor(safetyConfirmPreviewIconCircle, "color", false, snIconColorTextField.value)
        updatePreviewColor(safetyConfirmPreviewIconCircle, "background",
            snIconBackgroundTransparent.checked, snIconBackgroundColorTextField.value)
        updatePreviewTransparency(snIconBackgroundTransparent.checked, safetyConfirmPreviewIconCircle, snIconBackgroundColorTextField.value)

        // ################
        // ### checkbox ###
        //#################
        updatePreviewColor(safetyConfirmCheckbox, "background",
            snDescriptionBackgroundTransparent.checked, snDescriptionBackgroundColorTextField.value)
        updatePreviewTransparency(snDescriptionBackgroundTransparent.checked, safetyConfirmCheckbox, snDescriptionBackgroundColorTextField.value)
        updatePreviewColor(safetyConfirmCheckboxText, "color", false, snDescriptionTextColorTextField.value)
        updatePreviewColor(safetyConfirmCheckboxInput, "border", false, snDescriptionTextColorTextField.value)

        // ################
        // ### button  ###
        //#################
        updatePreviewColor(safetyConfirmPreviewNextButton, "color", false, snButtonTextColorTextField.value)
        updatePreviewColor(safetyConfirmPreviewNextButton, "background",
            snButtonBackgroundTransparent.checked, snButtonBackgroundColorTextField.value)
        updatePreviewTransparency(snButtonBackgroundTransparent.checked, safetyConfirmPreviewNextButton, snButtonBackgroundColorTextField.value)
        safetyConfirmPreviewNextButton.innerHTML = snConfirmationScreenButtonTextTextField.value

        // ################
        // ### checkbox ###
        // ################
        safetyConfirmCheckboxText.innerHTML = snConfirmationCheckboxTextTextField.value
    }

    function useConfirmPreviewElementsChangedStyle() {
        // background
        updatePreviewBackground(safetyConfirmPreviewScreen, "confirm", snConfirmationScreenBackgroundColorTextField.value)

        // ################
        // ### icon ###
        //#################
        updatePreviewColor(safetyConfirmPreviewIconCircle, "color", false, snConfirmationScreenIconColorTextField.value)
        updatePreviewColor(safetyConfirmPreviewIconCircle, "background",
            snConfirmationScreenIconBackgroundTransparent.checked, snConfirmationScreenIconBackgroundColorTextField.value)
        updatePreviewTransparency(snConfirmationScreenIconBackgroundTransparent.checked, safetyConfirmPreviewIconCircle, snConfirmationScreenIconBackgroundColorTextField.value)

        // ################
        // ### checkbox ###
        //#################
        updatePreviewColor(safetyConfirmCheckbox, "background",
            snConfirmationScreenTextBackgroundTransparent.checked, snConfirmationScreenTextBackgroundColorTextField.value)
        updatePreviewTransparency(snConfirmationScreenTextBackgroundTransparent.checked, safetyConfirmCheckbox, snConfirmationScreenTextBackgroundColorTextField.value)
        updatePreviewColor(safetyConfirmCheckboxText, "color", false, snConfirmationScreenTextColorTextField.value)
        updatePreviewColor(safetyConfirmCheckboxInput, "border", false, snConfirmationScreenTextColorTextField.value)

        // ################
        // ### button  ###
        //#################
        updatePreviewColor(safetyConfirmPreviewNextButton, "color", false, snConfirmationScreenButtonTextColorTextField.value)
        updatePreviewColor(safetyConfirmPreviewNextButton, "background",
            snConfirmationScreenButtonBackgroundTransparent.checked, snConfirmationScreenButtonBackgroundColorTextField.value)
        updatePreviewTransparency(snConfirmationScreenButtonBackgroundTransparent.checked, safetyConfirmPreviewNextButton, snConfirmationScreenButtonBackgroundColorTextField.value)
    }

    function changeConfirmScreenStyle(checked) {
        if (snConfirmationScreenChange.checked) { // change is enabled
            if (checked) {
                useConfirmPreviewElementsChangedStyle()
                safetyConfirmCheckboxInput.style.backgroundImage = `url("data:image/svg+xml,%3csvg viewBox='0 0 200 200'  fill='${snConfirmationScreenTextColorTextField.value.replace('#', '%23')}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 0.00,0.00 C 0.00,0.00 200.00,0.00 200.00,0.00 200.00,0.00 200.00,200.00 200.00,200.00 200.00,200.00 0.00,200.00 0.00,200.00 0.00,200.00 0.00,0.00 0.00,0.00 Z M 140.00,56.42 C 132.99,58.88 128.14,64.86 123.00,70.00 123.00,70.00 97.00,96.00 97.00,96.00 94.48,98.52 84.60,109.12 82.00,109.80 78.74,110.66 75.07,106.06 73.00,104.00 67.95,98.97 62.96,92.60 55.00,93.56 47.77,94.43 42.68,100.73 43.56,108.00 44.20,113.35 47.42,116.36 51.00,120.00 51.00,120.00 67.00,136.00 67.00,136.00 70.95,139.89 74.17,143.39 80.00,143.89 89.51,144.71 95.94,136.06 102.00,130.00 102.00,130.00 137.00,95.00 137.00,95.00 141.21,90.79 152.20,80.43 154.77,76.00 160.27,66.56 154.55,54.34 140.00,56.42 Z'/%3e%3c/svg%3e")`
            } else {
                useConfirmPreviewElementsDefaultStyle()
                safetyConfirmCheckboxInput.style.backgroundImage = 'none'
            }
        } else { //No design change on check. only display checkmark
            if (checked) {
                safetyConfirmCheckboxInput.style.backgroundImage = `url("data:image/svg+xml,%3csvg viewBox='0 0 200 200'  fill='${snDescriptionTextColorTextField.value.replace('#', '%23')}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 0.00,0.00 C 0.00,0.00 200.00,0.00 200.00,0.00 200.00,0.00 200.00,200.00 200.00,200.00 200.00,200.00 0.00,200.00 0.00,200.00 0.00,200.00 0.00,0.00 0.00,0.00 Z M 140.00,56.42 C 132.99,58.88 128.14,64.86 123.00,70.00 123.00,70.00 97.00,96.00 97.00,96.00 94.48,98.52 84.60,109.12 82.00,109.80 78.74,110.66 75.07,106.06 73.00,104.00 67.95,98.97 62.96,92.60 55.00,93.56 47.77,94.43 42.68,100.73 43.56,108.00 44.20,113.35 47.42,116.36 51.00,120.00 51.00,120.00 67.00,136.00 67.00,136.00 70.95,139.89 74.17,143.39 80.00,143.89 89.51,144.71 95.94,136.06 102.00,130.00 102.00,130.00 137.00,95.00 137.00,95.00 141.21,90.79 152.20,80.43 154.77,76.00 160.27,66.56 154.55,54.34 140.00,56.42 Z'/%3e%3c/svg%3e")`
            } else {
                safetyConfirmCheckboxInput.style.backgroundImage = 'none'
            }
        }
    }


    /**
     * Style helper functions
     */
    function updatePreviewBackground(element, type, value) {
        // get background information
        let backgroundSrc
        if (type === "confirm") {
            const snConfirmationScreenBackgroundJS = document.getElementById('snConfirmationScreenBackgroundJS')
            const snConfirmationScreenBackgroundImg = document.getElementById('snConfirmationScreenBackgroundImg')
            if (snConfirmationScreenBackgroundJS !== null) {
                backgroundSrc = snConfirmationScreenBackgroundJS.getAttribute("src")
            } else if (snConfirmationScreenBackgroundImg !== null) {
                backgroundSrc = snConfirmationScreenBackgroundImg.getAttribute("src")
            }

        } else if (type === "notice") {
            const snBackgroundImg = document.getElementById('snBackgroundImg')
            const snBackgroundJS = document.getElementById('snBackgroundJS')
            if (snBackgroundImg !== null) {
                backgroundSrc = snBackgroundImg.getAttribute("src")
            } else if (snBackgroundJS !== null) {
                backgroundSrc = snBackgroundJS.getAttribute("src")
            }
        }

        // set background if src exists, color otherwise
        if (backgroundSrc !== "") {
            element.style.backgroundImage = `url(${backgroundSrc})`
        } else {
            element.style.backgroundImage = 'none'
            element.style.backgroundColor = value
        }
    }

    function updatePreviewColor(element, type, transparent, value) {
        const dataType = safetyNoticePreviewScreen.dataset.dataType
        if (dataType === 'TEXT_WITH_ICON' ||
            element === safetyNoticePreviewNextButton || element === safetyNoticeVideoReplayButton) {
            if (!transparent) {
                if (type === "background") {
                    element.style.backgroundColor = value
                } else if (type === "border") {
                    element.style.borderColor = value
                } else {
                    element.style.color = value
                }
            }
        }
    }

    function updatePreviewTransparency(transparent, background, value) {
        const dataType = safetyNoticePreviewScreen.dataset.dataType
        if (dataType === 'TEXT_WITH_ICON' ||
            background === safetyNoticePreviewNextButton || background === safetyNoticeVideoReplayButton) {
            if (transparent) {
                background.style.backgroundColor = null
            } else {
                background.style.backgroundColor = value
            }
        }
    }

    function updatePreviewTextAlignment(value, div) {
        const dataType = safetyNoticePreviewScreen.dataset.dataType
        if (dataType === 'TEXT_WITH_ICON') {
            if (value === "center") {
                div.classList.add("text-center")
                div.classList.remove("text-left")
                div.classList.remove("text-right")
            } else if (value === "left") {
                div.classList.remove("text-center")
                div.classList.add("text-left")
                div.classList.remove("text-right")
            } else if (value === "right") {
                div.classList.remove("text-center")
                div.classList.remove("text-left")
                div.classList.add("text-right")
            }
        }
    }
})