document.addEventListener('turbolinks:load', () => {

    // background color
    const byodAppBackgroundColorTextField = document.getElementById('byodAppBackgroundColorTextField')
    const byodAppBackgroundColorColorField = document.getElementById('byodAppBackgroundColorColorField')
    const colorPreviewBackground = document.getElementById('byodAppBackgroundColorPreview')
    const noValidColorMessageBackground = document.getElementById('noValidColorMessageBackground')
    // Ui color
    const byodAppUiColorTextField = document.getElementById('byodAppUiColorTextField')
    const byodAppUiColorColorField = document.getElementById('byodAppUiColorColorField')
    const colorPreviewUi = document.getElementById('byodAppUiColorPreview')
    const noValidColorMessageUi = document.getElementById('noValidColorMessageUi')


    // Background color
    if (byodAppBackgroundColorTextField !== null && byodAppBackgroundColorColorField !== null &&
        colorPreviewBackground !== null && noValidColorMessageBackground !== null) {

        // show/hide invalid color warning on page load
        if (isColor(byodAppBackgroundColorTextField.value)) {
            noValidColorMessageBackground.classList.add('hidden')
        } else {
            noValidColorMessageBackground.classList.remove('hidden')
        }

        // change listener for background color: show/hide invalid color warning, show preview color
        byodAppBackgroundColorTextField.addEventListener("change", function(e) {
            if(isColor(this.value)) {
                colorPreviewBackground.style.fill = this.value // shoe preview color
                noValidColorMessageBackground.classList.add('hidden')
            } else {
                colorPreviewBackground.style.fill = null
                noValidColorMessageBackground.classList.remove('hidden')
            }

            updateColorValue("byodAppBackgroundColor", byodAppBackgroundColorTextField.value)
        })
        byodAppBackgroundColorColorField.addEventListener("change", function(e) {
            updateColorValue("byodAppBackgroundColor", byodAppBackgroundColorColorField.value)
        })
    }

    // UI color
    if (byodAppUiColorTextField !== null && byodAppUiColorColorField !== null &&
        colorPreviewUi !== null && noValidColorMessageUi !== null) {
        // show/hide invalid color warning on page load
        if (isColor(byodAppUiColorTextField.value)) {
            noValidColorMessageUi.classList.add('hidden')
        } else {
            noValidColorMessageUi.classList.remove('hidden')
        }

        // change listener for background color: show/hide invalid color warning, show preview color
        byodAppUiColorTextField.addEventListener("change", function(e) {
            if(isColor(this.value)) {
                colorPreviewUi.style.fill = this.value // shoe preview color
                noValidColorMessageUi.classList.add('hidden')
            } else {
                colorPreviewUi.style.fill = null
                noValidColorMessageUi.classList.remove('hidden')
            }

            updateColorValue("byodAppUiColor", byodAppUiColorTextField.value)
        })
        byodAppUiColorColorField.addEventListener("change", function(e) {
            updateColorValue("byodAppUiColor", byodAppUiColorColorField.value)
        })
    }

    // check if value is valid hex color
    function isColor(color){
        return /^#[0-9A-F]{6}$/i.test(color);
    }

    function updateColorValue(fieldId, colorValue){
        const textField = document.getElementById(fieldId + 'TextField')
        // Only trigger changeEvent if the color changed
        if (textField !== null && textField.value !== colorValue){
            textField.value = colorValue
            //trigger changeEvent
            const e = new Event("change")
            textField.dispatchEvent(e);
        }

        const colorField = document.getElementById(fieldId + 'ColorField')
        if (colorField !== null) {
            colorField.value = colorValue
        }
    }
})