document.addEventListener('turbolinks:load', () => {

    const dropdownToggle = document.getElementById('profileDropdownToggle');
    const dropdown = document.getElementById('profileDropdown');

    if (dropdown !== null && dropdownToggle !== null) {

        dropdown.classList.add('hidden');

        dropdownToggle.addEventListener('click', function () {
            dropdown.classList.toggle('hidden');

            hideOnClickOutside(this);
        });
    }

    function hideOnClickOutside(element) {
        const outsideClickListener = function(event) {
            if (!element.contains(event.target)) {
                if (!!element && !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)) {
                    element.nextElementSibling.classList.toggle('hidden');
                    removeClickListener();
                }
            }
        };

        const removeClickListener = function() {
            document.removeEventListener('click', outsideClickListener);
        };

        document.addEventListener('click', outsideClickListener);
    }
});