document.addEventListener('turbolinks:load', () => {

    const tagColorPreview = document.getElementById('tagColorPreview');
    const tagColorTextField = document.getElementById('tagColorTextField');
    const tagColorColorField = document.getElementById('tagColorColorField');
    const noValidTagColorMessage = document.getElementById('noValidColorMessageTagColor');

    if (tagColorPreview !== null && tagColorTextField !== null && tagColorColorField !== null && noValidTagColorMessage) {

        tagColorTextField.addEventListener("change", function () {
            updateColor();
            updateColorValue("tagColor", tagColorTextField.value);
        });
        tagColorColorField.addEventListener("change", function () {
            updateColorValue("tagColor", tagColorColorField.value);
        });
    }


    function updateColor() {
        let colorPreview = tagColorPreview
        let colorTextField = tagColorTextField
        let noValidColorMessage = noValidTagColorMessage

        if (isColor(colorTextField.value)) {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = colorTextField.value;
            noValidColorMessage.classList.add('hidden');
        } else {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = '#94a3b8';
            noValidColorMessage.classList.remove('hidden');
        }
    }

    function isColor(color){
        return /^#[0-9A-F]{6}$/i.test(color);
    }

    function updateColorValue(fieldId, colorValue){
        const textField = document.getElementById(fieldId + 'TextField')
        const colorField = document.getElementById(fieldId + 'ColorField')
        if(isColor(colorValue)) {
            // Only trigger changeEvent if the color changed
            if (textField !== null && textField.value !== colorValue) {
                textField.value = colorValue
                //trigger changeEvent
                const e = new Event("change")
                textField.dispatchEvent(e);
            }

            if (colorField !== null) {
                colorField.value = colorValue
            }
        } else if(colorField !== null){
            colorField.value = "#aaaaaa"
        }
    }
});