document.addEventListener('turbolinks:load', () => {
    // elements
    const blogEntryContentDragAndDrops = document.getElementsByClassName('blogEntryContentDragAreaOuter')

    // drag position logic
    let dragStarted = false
    let dragStartClickX
    let dragStartClickY
    let dragStartElementPosX
    let dragStartElementPosY

    // drag&drop elements
    let currentDropArea
    let draggedElement
    let clonedDraggedElement

    // style
    // all elements where the cursor was changed while dragging are saved in 'changedCursorTargets'
    //      The cursor is changed to 'move' on all elements that are passed while dragging. Otherwise
    //      'pointer-events-none' will lead to wrong pointer since the cursor will change depending on the element
    //      underneath the dragged element.
    //      the array is needed to reset the cursor after drop
    let changedCursorTargets = []


    // init startDrag logic if draggable elements exists
    if (blogEntryContentDragAndDrops.length > 0) {

        for (let i = 0; i < blogEntryContentDragAndDrops.length; i++) {
            blogEntryContentDragAndDrops[i].addEventListener("mousedown", function (ev) {
                startDrag(event)
            })
        }
    }
    // init move and drop
    window.addEventListener('mouseup', function() {
        dropElement(event)

    });

    window.addEventListener('mousemove', function() {
        moveElement(event)
    })

    function startDrag(event) {
        dragStarted = true

        // init draggedArea
        if (event.target.classList.contains("blogEntryContentDragAreaInner")) {
            draggedElement = event.target.parentElement.parentElement.parentElement
        } else if (event.target.classList.contains("blogEntryContentDragAreaOuter")){
            draggedElement= event.target.parentElement.parentElement
        }

        // get start positions for drag and drop
        dragStartClickX = event.clientX
        dragStartClickY = event.clientY
        dragStartElementPosX = draggedElement.getBoundingClientRect().left
        dragStartElementPosY = draggedElement.getBoundingClientRect().top

        // clone dragged node to show on old position. Change color to lighter gray to symbolize moving state
        clonedDraggedElement = draggedElement.cloneNode(true)
        const clonedDraggedElementContentData = clonedDraggedElement.getElementsByClassName("blogEntryContentData")[0]
        clonedDraggedElementContentData.classList.add("border-gray-200")
        clonedDraggedElementContentData.classList.remove("border-gray-300")
        clonedDraggedElementContentData.classList.add("resize-none")
        clonedDraggedElementContentData.classList.add("text-gray-200")
        const clonedDraggedElementInnerDrag = clonedDraggedElement.getElementsByClassName("blogEntryContentDragAreaInner")[0]
        clonedDraggedElementInnerDrag.classList.add("bg-gray-200")
        clonedDraggedElementInnerDrag.classList.remove("bg-gray-400")
        const clonedDraggedElementDeleteIcon = clonedDraggedElement.getElementsByClassName("blogEntryContentDelete")[0]
        clonedDraggedElementDeleteIcon.classList.remove("text-gray-400")
        clonedDraggedElementDeleteIcon.classList.add("text-gray-200")
        // only relevant for images. Make image transparent
        const clonedDraggedElementImage = clonedDraggedElement.getElementsByClassName('blogEntryContentDataImage')[0]
        if (clonedDraggedElementImage !== null && clonedDraggedElementImage !== undefined) {
            clonedDraggedElementImage.style.opacity = 0.5
            clonedDraggedElementImage.style.MozOpacity = 0.5
            clonedDraggedElementImage.style.KhtmlOpacity = 0.5
            clonedDraggedElementImage.style.filter = 'alpha(opacity=' + 50 + ')';
        }
        // only relevant for videos. Show white, transparent element over the video
        const clonedDraggedElementVideoOverlay = clonedDraggedElement.getElementsByClassName('blogEntryContentDataVideoOverlay')[0]
        if (clonedDraggedElementVideoOverlay !== null && clonedDraggedElementVideoOverlay !== undefined) {
            clonedDraggedElementVideoOverlay.classList.remove('hidden')
        }
        draggedElement.parentNode.insertBefore(clonedDraggedElement, draggedElement.nextSibling)


        // add class needed for dragging
        draggedElement.classList.add("pointer-events-none") // pointer events must ignore currently dragged element to check element below
        draggedElement.classList.add("fixed") // enable moving element

        // set start position for the dragged element. Otherwise the dragged element will jump if the user has scrolled
        draggedElement.style.left = dragStartElementPosX + 'px';
        draggedElement.style.top = dragStartElementPosY  + 'px';

        // #######
        // style
        // #######
        // set correct size of drag area indicator (inner)
        const blogEntryContentDragAreaInner = draggedElement.getElementsByClassName("blogEntryContentDragAreaInner")[0]
        blogEntryContentDragAreaInner.style.height = clonedDraggedElement.getElementsByClassName("blogEntryContentDragAreaInner")[0].getBoundingClientRect().height + "px"
        blogEntryContentDragAreaInner.classList.remove("min-h-full")
        // set correct size dragged area (outer)
        draggedElement.style.width = clonedDraggedElement.getBoundingClientRect().width + 'px'
        draggedElement.style.height = clonedDraggedElement.getBoundingClientRect().height + 'px'

        // remove hover:text-blue-500 from delete buttons while dragging
        const blogEntryContentDeleteButtons = document.getElementsByClassName('blogEntryContentDelete')
        for (let i = 0; i < blogEntryContentDeleteButtons.length; i++) {
            blogEntryContentDeleteButtons[i].classList.remove('hover:text-blue-500')
        }


    }
    // provide function for 'add_blog_text.js.erb'. Needed to enable dragging for added element
    window.startDrag = startDrag

    function dropElement(event) {

        if (dragStarted) {

            // remove highlighted drop area
            if (currentDropArea != null) {
                currentDropArea.classList.remove("bg-green-300")
            }

            // revert cursor of all elements from 'changedCursorTargets'
            for (let i = 0; i < changedCursorTargets.length; i++) {
                if (changedCursorTargets[i].style !== undefined) { // undefined if courser was moved out of the view while dragging
                    changedCursorTargets[i].style.cursor = ""
                }
            }
            changedCursorTargets=[]

            // add hover:text-blue-500 from delete buttons after dragging
            const blogEntryContentDeleteButtons = document.getElementsByClassName('blogEntryContentDelete')
            for (let i = 0; i < blogEntryContentDeleteButtons.length; i++) {
                blogEntryContentDeleteButtons[i].classList.add('hover:text-blue-500')
            }

            // handle drop
            // undefined element was dropped outside the view
            if (event.target.classList !== undefined && event.target.classList.contains("blogEntryContentDropArea")) {
                // drop over valid area

                // display element at correct position
                draggedElement.classList.remove("fixed")
                draggedElement.classList.remove("pointer-events-none")
                draggedElement.style.width = null
                draggedElement.style.height = null
                draggedElement.style.left = null
                draggedElement.style.top = null

                if (event.target.dataset.firstContent==="true") { // dataset only available for drop area above first blog content
                    // insert before
                    event.target.parentNode.parentNode.insertBefore(draggedElement, event.target.parentNode)
                } else {
                    // insert after
                    event.target.parentNode.parentNode.insertBefore(draggedElement, event.target.parentNode.nextSibling)
                }
                // remove cloned element on old position
                clonedDraggedElement.remove()

                // update position
                // loop over all children of contentDiv. If of class  'blogEntryContent' and set
                // blogEntryContentHiddenPosition value according to index pos (j)
                const contents = document.getElementById('contentDiv').childNodes
                for (let i = 0, j = 1; i < contents.length; i++) {
                    let content = contents[i]
                    if (content instanceof Element && `${content.className}`.includes('blogEntryContent')) {
                        content.getElementsByClassName('blogEntryContentHiddenPosition')[0].value = j

                        // show or hide drop area above element
                        if (j === 1) {
                            // show only for first
                            content.getElementsByClassName('blogEntryContentDropArea')[0].classList.remove('hidden')
                        } else {
                            // hide for others
                            content.getElementsByClassName('blogEntryContentDropArea')[0].classList.add('hidden')
                        }

                        j++
                    }
                }

            } else {
                // drop outside of valid area

                // use previously cloned element as new element
                const clonedNodeContentData = clonedDraggedElement.getElementsByClassName("blogEntryContentData")[0]
                clonedNodeContentData.classList.remove("border-gray-200")
                clonedNodeContentData.classList.add("border-gray-300")
                clonedNodeContentData.classList.remove("resize-none")
                clonedNodeContentData.classList.remove("text-gray-200")
                const clonedNodeInnerDrag = clonedDraggedElement.getElementsByClassName("blogEntryContentDragAreaInner")[0]
                clonedNodeInnerDrag.classList.remove("bg-gray-200")
                clonedNodeInnerDrag.classList.add("bg-gray-400")
                const clonedDeleteIcon = clonedDraggedElement.getElementsByClassName("blogEntryContentDelete")[0]
                clonedDeleteIcon.classList.add("text-gray-400")
                clonedDeleteIcon.classList.remove("text-gray-200")
                // only relevant for images. remove image transparency
                const clonedImage = clonedDraggedElement.getElementsByClassName('blogEntryContentDataImage')[0]
                if (clonedImage !== null && clonedImage !== undefined) {
                    clonedImage.style.opacity = null
                    clonedImage.style.MozOpacity = null
                    clonedImage.style.KhtmlOpacity = null
                    clonedImage.style.filter = '';
                }
                // only relevant for videos. Hide white, transparent element over the video
                const clonedVideoOverlay = clonedDraggedElement.getElementsByClassName('blogEntryContentDataVideoOverlay')[0]
                if (clonedVideoOverlay !== null && clonedVideoOverlay !== undefined) {
                    clonedVideoOverlay.classList.add('hidden')
                }
                // add listener for drag and drop
                const e = clonedDraggedElement.getElementsByClassName("blogEntryContentDragAreaOuter")[0]
                e.addEventListener("mousedown", function (ev) {
                    startDrag(ev)
                })

                // remove dragged element
                draggedElement.remove()
            }

        }
        dragStarted = false;
    }

    function moveElement(event) {
        if (dragStarted) {

            // set move cursor
            if (!changedCursorTargets.includes(event.target)) {
                changedCursorTargets.push(event.target)
                if (event.target.style !== undefined) { // undefined if courser was moved out of the view while dragging
                    event.target.style.cursor = "move";
                }
            }


            window.getSelection().removeAllRanges() // prevent marking of ui elements

            // highlight drop areas
            // undefined if courser was moved out of the view while dragging
            if (event.target.classList !== undefined && event.target.classList.contains("blogEntryContentDropArea")) {
                event.target.classList.add("bg-green-300")
                currentDropArea = event.target
            } else {
                if (currentDropArea != null) {
                    currentDropArea.classList.remove("bg-green-300")
                }
            }

            // move element
            draggedElement.style.left = dragStartElementPosX - (dragStartClickX - event.clientX) + 'px';
            draggedElement.style.top = dragStartElementPosY - (dragStartClickY - event.clientY) + 'px';
        }
    }

});