document.addEventListener('turbolinks:load', () => {

    const jsUpload = document.getElementById('jsUpload')
    const noJsUpload = document.getElementById('noJsUpload')

    const dropZone = document.getElementById('dropZone');
    const fileInput = document.getElementById('file_input');
    const selectedFileName = document.getElementById('added_file');

    const fileSelectDiv = document.getElementById('fileSelect')
    const uploadIndicator = document.getElementById('uploadIndicator')

    const submitStationGroup = document.getElementById("submitStationGroup")

    const colorPreview = document.getElementById('colorPreview');
    const colorTextField = document.getElementById('colorTextField');
    const colorColorField = document.getElementById('colorColorField');
    const noValidColorMessage = document.getElementById('noValidColorMessage');

    if (jsUpload !== null && noJsUpload !== null && dropZone !== null && fileInput !== null &&
        selectedFileName !== null && fileSelectDiv !== null && uploadIndicator !== null &&
        colorPreview !== null && colorTextField !== null && noValidColorMessage !== null) {
        init()
    }

    function init() {
        fileUpload()
        updateColor()
        addChangeColorListener()
    }

    function fileUpload() {
        // show js upload hide no js upload
        jsUpload.classList.remove('hidden')
        noJsUpload.classList.add('hidden')

        // file input
        fileInput.addEventListener('change', function(e) {
            selectedFileName.innerHTML = I18n.t('js.selected_file') + fileInput.value.replace(/^.*[\\\/]/, '');
            checkStorage(e.target.files[0])
        })

        submitStationGroup.addEventListener('click', function () {
            fileSelectDiv.classList.add('hidden');
            uploadIndicator.classList.remove('hidden')
        })

        // dropzone
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
        };
        dropZone.ondrop = function(evt) {
            evt.preventDefault();

            if (evt.dataTransfer.files.length > 1) {
                alert("You can't drop more then one file");
                return;
            }

            if (evt.dataTransfer.files[0].type !== 'image/png' && evt.dataTransfer.files[0].type !== 'image/jpeg') {
                alert("The file has an unsupported type. Please use only png files");
                return;
            }

            fileInput.files = evt.dataTransfer.files;
            selectedFileName.innerHTML = I18n.t('js.selected_file') + evt.dataTransfer.files[0].name;
            checkStorage(evt.dataTransfer.files[0])

        };
    }

    function checkStorage(file) {
        let neededStorage = Math.ceil((file.size/1024/1024));
        console.log(neededStorage)
        $.rails.ajax({
            url: "/organizations/check_storage/" + neededStorage,
            type: "get",
            data: "",
        })
    }

    function addChangeColorListener() {
        colorTextField.addEventListener("change", function() {
            updateColor();
            updateColorValue("color", colorTextField.value);
        });
        colorColorField.addEventListener("change", function() {
            updateColorValue("color", colorColorField.value);

        });
    }

    // Color
    function updateColor() {

        if (isColor(colorTextField.value)) {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = colorTextField.value;
            noValidColorMessage.classList.add('hidden');
        } else if (colorTextField.value.trim() === "") {
            colorPreview.classList.add("hidden")
            noValidColorMessage.classList.add('hidden');
        } else {
            colorPreview.classList.remove("hidden")
            colorPreview.style.fill = '#94a3b8';
            noValidColorMessage.classList.remove('hidden');
        }
    }

    function isColor(color){
        return /^#[0-9A-F]{6}$/i.test(color);
    }

    function updateColorValue(fieldId, colorValue){
        const textField = document.getElementById(fieldId + 'TextField')
        // Only trigger changeEvent if the color changed
        if (textField !== null && textField.value !== colorValue){
            textField.value = colorValue
            //trigger changeEvent
            const e = new Event("change")
            textField.dispatchEvent(e);
        }

        const colorField = document.getElementById(fieldId + 'ColorField')
        if (colorField !== null) {
            colorField.value = colorValue
        }
    }
});