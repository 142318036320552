document.addEventListener('turbolinks:load', () => {
    const expandVideoListButton = document.getElementById('expand_video_list_button')
    const videoList = document.getElementById('video_list')
    const expandAudioListButton = document.getElementById('expand_audio_list_button')
    const audioList = document.getElementById('audio_list')
    const expandImageListButton = document.getElementById('expand_image_list_button')
    const imageList = document.getElementById('image_list')


    if(expandVideoListButton != null && videoList != null && expandAudioListButton != null
        && audioList != null && expandImageListButton != null && imageList != null) {
        init()
    }

    function init() {

        expandVideoListButton.addEventListener('click', function (event) {
            if(expandVideoListButton.style.transform === "") {
                expandVideoListButton.style.transform = 'rotate(-90deg)';
                videoList.classList.add('hidden')
            } else {
                expandVideoListButton.style.transform = ""
                videoList.classList.remove('hidden')
            }
        });

        expandAudioListButton.addEventListener('click', function (event) {
            if(expandAudioListButton.style.transform === "") {
                expandAudioListButton.style.transform = 'rotate(-90deg)';
                audioList.classList.add('hidden')
            } else {
                expandAudioListButton.style.transform = ""
                audioList.classList.remove('hidden')
            }
        });

        expandImageListButton.addEventListener('click', function (event) {
            if(expandImageListButton.style.transform === "") {
                expandImageListButton.style.transform = 'rotate(-90deg)';
                imageList.classList.add('hidden')
            } else {
                expandImageListButton.style.transform = ""
                imageList.classList.remove('hidden')
            }
        });
    }
});