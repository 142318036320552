document.addEventListener('turbolinks:load', () => {

    const safetyNoticeIconUploadDiv = document.getElementById('safetyNoticeIconUploadDiv')
    const safetyNoticeIconInput = document.getElementById("safetyNoticeIconInput")
    const customIconsGrid = document.getElementById('customIconsGrid')
    const safetyNoticeIconDropZone = document.getElementById('safetyNoticeIconDropZone')


    if (safetyNoticeIconUploadDiv !== null && safetyNoticeIconInput !== null && customIconsGrid !== null &&
        safetyNoticeIconDropZone !== null) {
        init()
    }

    function init() {
        // upload via input
        safetyNoticeIconInput.addEventListener('change', function(e) {
            if (e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg' &&
                e.target.files[0].type !== 'image/svg+xml') {
                alert(I18n.t('js.wrong_type_alert_svg'));
                return;
            }

            // upload via ajax
            const _URL = window.URL || window.webkitURL;
            const img = new Image();
            const objectUrl = _URL.createObjectURL(e.target.files[0]);
            img.onload = function () {
                ajaxUpload(e.target.files[0], true)
            };
            img.src = objectUrl;
        })

        // upload via drag&Drop
        safetyNoticeIconDropZone.ondragover = safetyNoticeIconDropZone.ondragenter = function(evt) {
            evt.preventDefault();
            safetyNoticeIconDropZone.classList.add('bg-green-200')
        };

        safetyNoticeIconDropZone.ondragleave = function (evt) {
            evt.preventDefault();
            safetyNoticeIconDropZone.classList.remove('bg-green-200')
        };

        safetyNoticeIconDropZone.ondrop = function(evt) {
            evt.preventDefault();

            for (let i = 0; i < evt.dataTransfer.files.length; i++) {
                if (evt.dataTransfer.files[i].type !== 'image/png' && evt.dataTransfer.files[i].type !== 'image/jpeg' &&
                    evt.dataTransfer.files[i].type !== 'image/svg+xml') {

                    safetyNoticeIconDropZone.classList.remove('bg-green-200')
                    alert(I18n.t('js.wrong_type_alert_svg'));

                    if (i === evt.dataTransfer.files.length-1) {
                        const uploadText = document.getElementById('safetyNoticeIconUploadText')
                        const uploadSpinner = document.getElementById('safetyNoticeIconUploadSpinner')
                        const uploadIcon = document.getElementById("safetyNoticeIconDropZoneIcon")
                        hideSpinner(uploadText, uploadSpinner, uploadIcon)
                    }

                    continue;
                }

                // upload via ajax
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                const objectUrl = _URL.createObjectURL(evt.dataTransfer.files[i]);
                img.onload = function () {
                    const last = (i === evt.dataTransfer.files.length-1)
                    ajaxUpload(evt.dataTransfer.files[i], last)

                };
                img.src = objectUrl;
            }
        }

    }

    function ajaxUpload(file, lastUpload) {
        // show upload spinner
        const uploadText = document.getElementById('safetyNoticeIconUploadText')
        const uploadSpinner = document.getElementById('safetyNoticeIconUploadSpinner')
        const uploadIcon = document.getElementById("safetyNoticeIconDropZoneIcon")
        const iconUploadErrorMsg = document.getElementById('iconUploadErrorMsg')

        showSpinner(uploadText, uploadSpinner, uploadIcon, iconUploadErrorMsg)

        // upload file
        const formData = new FormData();
        formData.append('safety_notice_icon[src]', file);
        const safetyNoticeSettingId = safetyNoticeIconUploadDiv.dataset.safetyNoticeSettingId
        const safetyNoticeId = safetyNoticeIconUploadDiv.dataset.safetyNoticeId
        $.rails.ajax({
            url: `/safety_notice_settings/${safetyNoticeSettingId}/safety_notices/${safetyNoticeId}/safety_notice_icons/upload_resource`,
            type: "post",
            data: formData,
            async: true,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            success: function (response, status) {
                safetyNoticeIconDropZone.classList.remove('bg-green-200')
                if (lastUpload) {
                    hideSpinner(uploadText, uploadSpinner, uploadIcon)
                }
                addIcon(response.url, response.icon_id)
            },
            error: function (response, status) {
                safetyNoticeIconDropZone.classList.remove('bg-green-200')
                if (lastUpload) {
                    hideSpinner(uploadText, uploadSpinner, uploadIcon)
                }
                showErrorMsg(iconUploadErrorMsg, response.error_msg)
            }
        })
    }

    function showSpinner(uploadText, uploadSpinner, uploadIcon, iconUploadErrorMsg) {
        if (uploadText !== null) {
            uploadText.classList.add('hidden')
        }
        if (uploadSpinner !== null) {
            uploadSpinner.classList.remove('hidden')
        }
        if (uploadIcon !== null) {
            uploadIcon.classList.add('hidden')
        }
        if (iconUploadErrorMsg != null) {
            iconUploadErrorMsg.classList.add("hidden")
        }
    }

    function hideSpinner(uploadText, uploadSpinner, uploadIcon) {
        if (uploadText !== null) {
            uploadText.classList.remove('hidden')
        }
        if (uploadSpinner !== null) {
            uploadSpinner.classList.add('hidden')
        }
        if (uploadIcon !== null) {
            uploadIcon.classList.remove('hidden')
        }
    }

    function addIcon(url, iconId) {
        let label = document.createElement("label")
        let div = document.createElement("div")
        let input = document.createElement("input")
        let span = document.createElement("span")
        let image = document.createElement("img")

        label.classList.add("w-24")
        label.classList.add("h-24")

        div.classList.add("relative")
        div.classList.add("w-24")
        div.classList.add("h-24")

        input.classList.add("radioIcon")
        input.type = "radio"
        input.value = iconId
        input.name = "safety_notice[icon]"

        span.classList.add("flex")
        span.classList.add("w-24")
        span.classList.add("h-24")

        image.classList.add("p-1")
        image.classList.add("object-scale-down")
        image.classList.add("mx-auto")

        const safetyNoticeSettingId = safetyNoticeIconUploadDiv.dataset.safetyNoticeSettingId
        const safetyNoticeId = safetyNoticeIconUploadDiv.dataset.safetyNoticeId
        const deleteIcon = `
            <div class="absolute -top-1 -right-1">
              <a href="/safety_notice_settings/${safetyNoticeSettingId}/safety_notices/${safetyNoticeId}/safety_notice_icons/${iconId}/delete_resource" class="h-6 top-0 rounded-md flex absolute bottom-0 text-gray-400 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </a>
            </div>`

        image.src = url
        label.appendChild(div)
        div.appendChild(input)
        div.appendChild(span)
        span.appendChild(image)
        div.innerHTML += deleteIcon

        customIconsGrid.appendChild(label)
    }

    function showErrorMsg(iconUploadErrorMsg, errorMsg) {
        const iconUploadErrorMsgHeader = document.getElementById("iconUploadErrorMsgHeader")

        if (iconUploadErrorMsg != null) {
            // show correct error msg
            if (iconUploadErrorMsgHeader !== null) {
                if (errorMsg === "max_size_error") {
                    iconUploadErrorMsgHeader.innerHTML = I18n.t('js.icon_upload_max_size_error')
                } else {
                    iconUploadErrorMsgHeader.innerHTML = I18n.t('js.icon_upload_unknown_error')
                }
            }

            iconUploadErrorMsg.classList.remove("hidden")
        }
    }
});