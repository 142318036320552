document.addEventListener('turbolinks:load', () => {
    const designIconsTab = document.getElementById('designIconsTab')
    const designIconsDiv = document.getElementById('designIconsDiv')
    const isoIconsTab = document.getElementById('isoIconsTab')
    const isoIconsDiv = document.getElementById('isoIconsDiv')
    const customIconsTab = document.getElementById('customIconsTab')
    const customIconsDiv = document.getElementById('customIconsDiv')
    const iconTypeSelect = document.getElementById('safety_notice_icon_type')


    if (designIconsTab !== null && customIconsTab !== null && isoIconsTab !== null && iconTypeSelect !== null &&
        designIconsDiv !== null && isoIconsDiv !== null && customIconsDiv !== null ) {
        init()
    }

    function init() {
        designIconsTab.addEventListener("click", function () {
            showDesignTab()
        })

        isoIconsTab.addEventListener("click", function () {
            showIsoTab()
        })

        customIconsTab.addEventListener("click", function () {
            showCustomTab()
        })

        iconTypeSelect.addEventListener("change", function (evt) {
            const selected = evt.target.options[evt.target.selectedIndex].value

            if(selected === "design") {
                showDesignTab()
            } else if (selected === "iso") {
                showIsoTab()
            } else if (selected === "custom") {
                showCustomTab()
            }
        })
    }

    function showDesignTab() {
        selectTab(designIconsTab)
        unselectTab(isoIconsTab)
        unselectTab(customIconsTab)

        designIconsDiv.classList.remove("hidden")
        isoIconsDiv.classList.add("hidden")
        customIconsDiv.classList.add("hidden")

        iconTypeSelect.value = "design"
    }

    function showIsoTab() {
        unselectTab(designIconsTab)
        selectTab(isoIconsTab)
        unselectTab(customIconsTab)

        designIconsDiv.classList.add("hidden")
        isoIconsDiv.classList.remove("hidden")
        customIconsDiv.classList.add("hidden")

        iconTypeSelect.value = "iso"
    }

    function  showCustomTab() {
        unselectTab(designIconsTab)
        unselectTab(isoIconsTab)
        selectTab(customIconsTab)

        designIconsDiv.classList.add("hidden")
        isoIconsDiv.classList.add("hidden")
        customIconsDiv.classList.remove("hidden")

        iconTypeSelect.value = "custom"
    }

    function selectTab(tab) {
        tab.classList.add("border-blue-500")
        tab.classList.add("text-blue-600")
        tab.classList.remove("border-transparent")
        tab.classList.remove("text-gray-500")
        tab.classList.remove("hover:border-gray-300")
        tab.classList.remove("hover:text-gray-700")
    }

    function unselectTab(tab) {
        tab.classList.remove("border-blue-500")
        tab.classList.remove("text-blue-600")
        tab.classList.add("border-transparent")
        tab.classList.add("text-gray-500")
        tab.classList.add("hover:border-gray-300")
        tab.classList.add("hover:text-gray-700")
    }
});