document.addEventListener('turbolinks:load', () => {
    const duplication_in_progress = document.getElementById("duplication_in_progress")
    const duplication_org = document.getElementById("duplication_org")

    if (duplication_in_progress !== null && duplication_org !== null && duplication_in_progress.value === "1"){
        startDuplicationStatusLoop()
    }


    async function startDuplicationStatusLoop(){
        let organizationID = duplication_org.value
        //infinite loop to update duplication status
        while(true){
            //brake loop (organizations view is no longer shown)
            if (duplication_in_progress === null || duplication_org === null){
                break
            }
            if (duplication_in_progress.value === "0"){
                break
            }

            requestDuplicationUpdate(organizationID)

            // wait 3 seconds before next loop
            await new Promise(r => setTimeout(r, 3000));
        }
    }

    function requestDuplicationUpdate(organizationID) {
        // request to update duplication state via controller action
        $.rails.ajax({
            url: `/admin/organizations/${organizationID}/duplicate_state`,
            type: "get",
            data: "",
        })
    }
})