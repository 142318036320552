/**
 * JS file for the use with app/view/templates/instant_image_upload.html.erb
 *
 * Values to set:
 *  id: same value as in instant_image_upload.html.erb
 *  urlSegment: as defined in routes
 *  otherIds: array that contains all other ids that use the same upload template (of any type (image, font))
 *      in the same view. Needed to set ID value for rootDiv.dataset.id if a create action
 *      in the controller is used. Otherwise multiple safety_notice_settings
 *      for one organization could be created
 */

document.addEventListener('turbolinks:load', () => {

    // set base id und url for upload
    const id ="mmgAppLogo"
    const otherIds = ["mmgAppBackground", "mmgAppFont"]
    const urlSegment = "mmg_apps"

    // init elements
    const rootDiv = document.getElementById(`${id}Div`)
    const jsUpload = document.getElementById(`${id}JsUpload`)
    const noJsUpload = document.getElementById(`${id}NoJsUpload`)
    const input = document.getElementById(`${id}Input`)
    const dropZone = document.getElementById(`${id}DropZone`)

    // information what files are allowed
    let allowedFileTypes
    let allowedFileDimensions

    /**
     * add individual needs here
     */


    if (rootDiv !== null && jsUpload !== null && noJsUpload !== null && input !== null && dropZone !== null) {
        init()
    }

    function init() {
        // display js upload
        jsUpload.classList.remove('hidden')
        noJsUpload.classList.add('hidden')

        // set info what files are allowed
        allowedFileTypes = rootDiv.dataset.allowedFileTypes
        allowedFileDimensions = rootDiv.dataset.allowedFileDimensions
        if (allowedFileDimensions !== "any") {
            allowedFileDimensions = allowedFileDimensions.split(",")
        }

        // upload via input
        input.addEventListener('change', function(e) {

            // prevent other than jpg and png
            if (!allowedFileTypes.includes(e.target.files[0].type) || e.target.files[0].type === "") {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", allowedFileTypeString(allowedFileTypes)));
                return;
            }

            // check dimension if needed and upload if correct
            if (allowedFileDimensions !== "any") {
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                const objectUrl = _URL.createObjectURL(e.target.files[0]);
                img.onload = function () {
                    if (img.width !== parseInt(allowedFileDimensions[0]) || img.height !== parseInt(allowedFileDimensions[1])) {
                        alert(I18n.t('js.background_size_limitation_alert_with_param')
                            .replace("${width}",allowedFileDimensions[0])
                            .replace("${height}",allowedFileDimensions[1]));
                    } else {
                        ajaxUpload(e.target.files[0], id)
                    }
                };
                img.src = objectUrl;
            } else {
                // upload without dimension check
                ajaxUpload(e.target.files[0], id)
            }
        })

        //upload via drag&drop
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
            dropZone.classList.add('bg-green-200')
        };

        dropZone.ondragleave = function (evt) {
            evt.preventDefault();
            dropZone.classList.remove('bg-green-200')
        };

        dropZone.ondrop = function(evt) {
            evt.preventDefault();

            if (evt.dataTransfer.files.length > 1) {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.more_than_one_file_alert'));
                return;
            }

            if (!allowedFileTypes.includes(evt.dataTransfer.files[0].type) || evt.dataTransfer.files[0].type === "") {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", allowedFileTypeString(allowedFileTypes)));
                return;
            }

            input.files = evt.dataTransfer.files
            // check dimension if needed and upload if correct
            if (allowedFileDimensions !== "any") {
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                const objectUrl = _URL.createObjectURL(evt.dataTransfer.files[0]);
                img.onload = function () {
                    if (img.width !== parseInt(allowedFileDimensions[0]) || img.height !== parseInt(allowedFileDimensions[1])) {
                        dropZone.classList.remove('bg-green-200')
                        alert(I18n.t('js.background_size_limitation_alert_with_param')
                            .replace("${width}", allowedFileDimensions[0])
                            .replace("${height}", allowedFileDimensions[1]));
                    } else {
                        ajaxUpload(input.files[0], id)
                    }
                };
                img.src = objectUrl;
            } else {
                // upload without dimension check
                ajaxUpload(input.files[0], id)
            }

            /**
             * Add individual needs here
             */
        }
    }

    function ajaxUpload(file, usage) {
        // hide upload area and show upload spinner
        if (usage === id) {
            const placeholder = document.getElementById(`${id}Placeholder`)
            const img = document.getElementById(`${id}Img`)
            const uploadText = document.getElementById(`${id}UploadText`)
            const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
            const imgJs = document.getElementById(`${id}JS`)
            const removeIcon = document.getElementById(`remove${capitalizeFirstLetter(id)}`)

            uploadText.classList.add('hidden')
            uploadSpinner.classList.remove('hidden')
            if (imgJs !== null) {
                imgJs.classList.add("hidden")
            }
            if (removeIcon !== null) {
                removeIcon.classList.add('hidden')
            }
            if (placeholder !== null) {
                placeholder.classList.add("hidden")
            } else if (img !== null) {
                img.classList.add("hidden")
            }
        }

        // form data from file
        const formData = new FormData();
        formData.append('file_filed', file);
        formData.append('usage', usage)

        // rails id of object
        let objectId = ""
        if (rootDiv !== null) {
            objectId = rootDiv.dataset.id
            if (objectId === "") {
                objectId = "undefined"
            }
        }

        // do ajax upload
        $.rails.ajax({
            url: `/${urlSegment}/${objectId}/upload_resource`,
            type: "put",
            data: formData,
            async: true,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            success: function (response, status) {
                const fr = new FileReader();
                if (usage === id) {
                    const uploadText = document.getElementById(`${id}UploadText`)
                    const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
                    const imgJs = document.getElementById(`${id}JS`)
                    const removeIcon = document.getElementById(`remove${capitalizeFirstLetter(id)}`)
                    const img = document.getElementById(`${id}Img`)

                    // display image after successfully upload
                    fr.onload = function (e) {
                        if (imgJs !== null) {
                            imgJs.src = this.result;
                        } else if (img !== null) {
                            img.src = this.result
                        }
                    };
                    fr.readAsDataURL(file)

                    // update remove url
                    removeIcon.childNodes[1].href = `/${urlSegment}/${response.object_id}/delete_resource/${id}`

                    // update rails id of object
                    rootDiv.dataset.id = response.object_id
                    for (let i = 0; i < otherIds.length; i++) {
                        const otherRootDiv = document.getElementById(`${otherIds[i]}Div`)
                        otherRootDiv.dataset.id = response.object_id
                    }

                    // hide and display different parts of ui
                    if (imgJs !== null) {
                        imgJs.classList.remove("hidden")
                    } else if (img !== null) {
                        img.classList.remove("hidden")
                    }
                    removeIcon.classList.remove("hidden")
                    uploadText.classList.remove('hidden')
                    uploadSpinner.classList.add('hidden')
                    dropZone.classList.remove('bg-green-200')

                    /**
                     * Add individual needs here
                     *
                     */

                }
            }
        });
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // get correct formatted string from comma seperated allowed string
    function allowedFileTypeString(allowedFileType) {
        let result = ""

        const allowedFileTypeArray = allowedFileType.split(",")
        for (var i = 0; i < allowedFileTypeArray.length; i++) {
            if (i === 0) {
                result += allowedFileTypeArray[i].split("/")[1]
            } else if (i < allowedFileTypeArray.length-1) {
                result += `, ${allowedFileTypeArray[i].split("/")[1]}`
            } else {
                result += ` ${I18n.t('js.or')} ${allowedFileTypeArray[i].split("/")[1]}`
            }
        }

        return result
    }
});