document.addEventListener('turbolinks:load', () => {
    const noPreviewScreen = document.getElementById('noPreviewScreen')
    const noPreviewSgTop = document.getElementById('noPreviewSgTop')
    const noPreviewSgBottom = document.getElementById('noPreviewSgBottom')
    const noPreviewMsg = document.getElementById('noPreviewMsg')

    if (noPreviewScreen!== null && noPreviewSgTop !== null && noPreviewSgBottom !== null &&
        noPreviewMsg !== null) {
        startResizeObserver()
    }

    function startResizeObserver() {

        let resizeObserver = new ResizeObserver(() => {

            noPreviewMsg.style.fontSize = `${noPreviewScreen.getBoundingClientRect().width/14}px`;

            noPreviewSgTop.style.borderTopLeftRadius = `${noPreviewScreen.getBoundingClientRect().height/22.0}px`
            noPreviewSgTop.style.borderTopRightRadius = `${noPreviewScreen.getBoundingClientRect().height/22.0}px`
            noPreviewSgBottom.style.borderBottomLeftRadius = `${noPreviewScreen.getBoundingClientRect().height/22.0}px`
            noPreviewSgBottom.style.borderBottomRightRadius = `${noPreviewScreen.getBoundingClientRect().height/22.0}px`
        });

        resizeObserver.observe(noPreviewScreen);
    }
});