document.addEventListener('turbolinks:load', () => {

    const jsUpload = document.getElementById('jsUpload')
    const noJsUpload = document.getElementById('noJsUpload')

    const dropZone = document.getElementById('dropZone');
    const fileInput = document.getElementById('file_input');
    const selectedFileName = document.getElementById('added_file');

    const fileSelectDiv = document.getElementById('fileSelect')
    const uploadIndicator = document.getElementById('uploadIndicator')

    const submitMediaEntryButton = document.getElementById('submitMediaEntry')
    const addAudioButton = document.getElementById("addAudio")

    if (jsUpload !== null && noJsUpload !== null && dropZone !== null && fileInput !== null &&
    selectedFileName !== null && fileSelectDiv !== null &&
    uploadIndicator !== null && addAudioButton !== null) {
        init()
    }

    function init() {
        // show js upload hide no js upload
        jsUpload.classList.remove('hidden')
        noJsUpload.classList.add('hidden')

        // file input
        fileInput.addEventListener('change', function(e) {
            selectedFileName.innerHTML = I18n.t('js.selected_file') + fileInput.value.replace(/^.*[\\\/]/, '');
            checkStorage(e.target.files[0])
        })

        if (submitMediaEntryButton !== null) {
            submitMediaEntryButton.addEventListener('click', function () {
                fileSelectDiv.classList.add('hidden');
                uploadIndicator.classList.remove('hidden')
            })
        }

        addAudioButton.addEventListener('click', function () {
            fileSelectDiv.classList.add('hidden');
            uploadIndicator.classList.remove('hidden')
        })

        // dropzone
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
        };
        dropZone.ondrop = function(evt) {
            evt.preventDefault();

            if (evt.dataTransfer.files.length > 1) {
                alert("You can't drop more then one file");
                return;
            }

            if (evt.dataTransfer.files[0].type !== 'image/png' && evt.dataTransfer.files[0].type !== 'image/jpeg') {
                alert("The file has an unsupported type. Please use png or jpg files");
                return;
            }

            fileInput.files = evt.dataTransfer.files;
            selectedFileName.innerHTML = I18n.t('js.selected_file') + evt.dataTransfer.files[0].name;
            checkStorage(evt.dataTransfer.files[0])

        };
    }

    function checkStorage(file) {
        let neededStorage = Math.ceil((file.size/1024/1024));
        console.log(neededStorage)
        $.rails.ajax({
            url: "/organizations/check_storage/" + neededStorage,
            type: "get",
            data: "",
        })
    }
});