document.addEventListener('turbolinks:load', () => {

    const safetyNoticeTableBody = document.getElementById('safetyNoticeTableBody')
    //is null without safety_notices
    const safetyNoticeTable = document.getElementById('safetyNoticeTable')

    if (safetyNoticeTableBody !== null) {
        safetyNoticeDragAndDrop()
    }

    const safetyNoticeVideoSelect = document.getElementById('safetyNoticeVideoSelect')
    const safetyNoticeVideoControl = document.getElementById('safetyNoticeVideoControl')
    const safetyNoticeVideoSource = document.getElementById('safetyNoticeVideoSource')
    if (safetyNoticeVideoSelect !== null && safetyNoticeVideoControl !== null && safetyNoticeVideoSource !== null) {
        // load video
        const videoSrc = safetyNoticeVideoSelect.selectedOptions[0].dataset.src
        if (videoSrc !== undefined) {
            safetyNoticeVideoSource.src = videoSrc
            safetyNoticeVideoControl.load()
        }
        // enable select2
        videoSelect2()
    }

    function videoSelect2() {
        // TODO warning?
        const safetyNoticeVideoSelect2 = $("#safetyNoticeVideoSelect").select2();
        safetyNoticeVideoSelect2.data('select2').$container.addClass("wrap")
        safetyNoticeVideoSelect2.on('select2:selecting', function(e) {

            // show/hide warning
            const safetyNoticeWarning = document.getElementById('safetyNoticeWarning')
            if (safetyNoticeWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    safetyNoticeWarning.classList.add("hidden")
                } else {
                    safetyNoticeWarning.classList.remove("hidden")
                    const safetyNoticeWarningMsg = document.getElementById('safetyNoticeWarningMsg')
                    if (safetyNoticeWarningMsg !== null) {
                        safetyNoticeWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }
            }


            // load selected video
            const videoSrc = e.params.args.data.element.dataset.src
            if (videoSrc !== undefined ) {
                safetyNoticeVideoSource.src = videoSrc
                safetyNoticeVideoControl.load()
            }
        });
        $("#safetyNoticeVideoSelect").hide()
    }


    function safetyNoticeDragAndDrop() {
        let current = null
        let tableRows = safetyNoticeTableBody.getElementsByTagName("tr");

        for (let tr of tableRows) {
            tr.draggable = true;

            // START
            tr.addEventListener("dragstart", function (ev) {
                current = this;
                //Remove the alternate-children class, because this bg-color gets used otherwise and the added color classes don't work
                safetyNoticeTable.classList.remove("alternate-children")
                // style other elements
                for (let it of tableRows) {
                    if (it !== current) {
                        it.classList.add("bg-amber-200")
                    }
                }
            });

            // ENTER
            tr.addEventListener("dragenter", function (ev) {
                for (let it of tableRows) {
                    if (current !== null) {
                        if (it !== tr && it !== current) {
                            // other stations
                            it.classList.add("bg-amber-200")
                            it.classList.remove("bg-red-200")
                        } else if (it === tr && it !== current) {
                            // reached station
                            it.classList.add("bg-red-200")
                            it.classList.remove("bg-amber-200")
                        }
                    }
                }
            });

            //LEAVE
            tr.addEventListener("dragleave", function () {
            });

            // END
            tr.addEventListener("dragend", function (ev) {
                // reset everything
                current = null
                safetyNoticeTable.classList.add("alternate-children")
                for (let it of tableRows) {
                    it.classList.remove("bg-amber-200")
                    it.classList.remove("bg-red-200")
                }
            });

            // OVER
            tr.addEventListener("dragover", function (evt) {
                evt.preventDefault();
            });

            // DROP
            tr.addEventListener("drop", function (evt) {
                evt.preventDefault();
                if (this !== current) {
                    let currentPos = 0;
                    let droppedPos = 0;
                    for (let it = 0; it < tableRows.length; it++) {
                        if (current === tableRows[it]) {
                            currentPos = it;
                        }
                        if (this === tableRows[it]) {
                            droppedPos = it;
                        }
                    }

                    if (currentPos < droppedPos) {
                        this.parentNode.insertBefore(current, this.nextSibling);
                    } else {
                        this.parentNode.insertBefore(current, this);
                    }
                }

            });

        }
    }


});