document.addEventListener('turbolinks:load', () => {
    const iso3166TextField = document.getElementById('iso3166TextField')
    const languageFlag = document.getElementById('languageFlag')
    const languageFlagPlaceholder = document.getElementById('languageFlagPlaceholder')
    const invalidIso3166Message = document.getElementById('invalidIso3166Message')

    if (iso3166TextField !== null && languageFlag !== null && languageFlagPlaceholder!== null &&
        invalidIso3166Message !== null) {

        iso3166TextField.addEventListener("change", function() {
           showFlagIcon(this.value)
        });

        initWarning()
    }


    function showFlagIcon(input) {
        if (isValidIso3166Value(input)) {
            languageFlag.classList.remove('hidden')
            languageFlagPlaceholder.classList.add('hidden')
            invalidIso3166Message.classList.add('hidden')

            const firstLetter = input.toUpperCase().codePointAt(0) - 0x41 + 0x1F1E6
            const secondLetter = input.toUpperCase().codePointAt(1) - 0x41 + 0x1F1E6

            languageFlag.innerHTML = String.fromCodePoint(firstLetter, secondLetter)
        } else {
            languageFlag.classList.add('hidden')
            languageFlagPlaceholder.classList.remove('hidden')

            if (input !== "") {
                invalidIso3166Message.classList.remove('hidden')
            }
        }
    }

    function isValidIso3166Value(value) {
        const validIso3166Values = ["AC", "AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU",
            "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR",
            "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO",
            "CP", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DG", "DJ", "DK", "DM", "DO", "DZ", "EA", "EC", "EE",
            "EG", "EH", "ER", "ES", "ET", "EU", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG",
            "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT",
            "HU", "IC", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG",
            "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU",
            "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS",
            "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU",
            "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE",
            "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO",
            "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TA", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN",
            "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "UN", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI",
            "VN", "VU", "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW", ]

        if (value !== null) {
            return validIso3166Values.includes(value.toUpperCase())
        } else {
            return false
        }
    }

    function initWarning() {
        if(isValidIso3166Value(iso3166TextField.value) || iso3166TextField.value === "") {
            invalidIso3166Message.classList.add('hidden')
        } else {
            invalidIso3166Message.classList.remove('hidden')
        }
    }
})