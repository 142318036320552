document.addEventListener('turbolinks:load', () => {
    const deploymentView = document.getElementById("deploymentView")

    if (deploymentView !== null) {
        startDeploymentStatusLoop()
        startDownloadUsbDeploymentLoop()
        hideReloadInfo()
    }

    async function startDeploymentStatusLoop() {
        // infinite loop to update Deployment View
        while (true) {

            // check if id and state info exists
            const deploymentIdTag = document.getElementById('hiddenDeploymentId')
            const deploymentStateTag = document.getElementById('hiddenDeploymentState')
            if (deploymentIdTag === null || deploymentStateTag === null) {
                // brake loop (deployment view is no longer shown)
                break
            }
            const deploymentId = deploymentIdTag.innerText
            const deploymentState = deploymentStateTag.innerText

            // request update if deployment is ongoing
            if (deploymentState === "prepare_data" || deploymentState === "uploading" ||
                deploymentState === "usb_data_prepared" || deploymentState === "usb_link_provided" ||
                deploymentState === "validating" || deploymentState === "video_audio_sync_data_prepared" ||
                deploymentState === "video_audio_sync_data_link_provided"
            ) {
                requestViewUpdate(deploymentId)
            }

            // wait 3 seconds before next loop
            await new Promise(r => setTimeout(r, 3000));
        }
    }

    async function startDownloadUsbDeploymentLoop() {
        // infinite loop to download usb deployment
        while (true) {

            // check if id and state info exists
            const deploymentIdTag = document.getElementById('hiddenDeploymentId')
            if (deploymentIdTag === null) {
                // brake loop (deployment view is no longer shown)
                break
            }

            // download  usb deployment
            const deploymentUsbDownloadLink = document.getElementById('deploymentUsbDownloadLink')
            if (deploymentUsbDownloadLink !== null) {
                deploymentUsbDownloadLink.click()
                break
            }

            // download video_audio_sync
            const deploymentVideoAudioSyncDataDownloadLink = document.getElementById('deploymentVideoAudioSyncDataDownloadLink')
            if (deploymentVideoAudioSyncDataDownloadLink !== null) {
                deploymentVideoAudioSyncDataDownloadLink.click()
                break
            }

            // wait 3 seconds
            await new Promise(r => setTimeout(r, 3000));
        }
    }

    function hideReloadInfo() {
        // hide reload info. Only needed if JS not available to update view
        const reloadInfoPWA = document.getElementById('reloadInfoPWA')
        const reloadInfoSmartGuide = document.getElementById('reloadInfoSmartGuide')

        if (reloadInfoPWA !== null) {
            reloadInfoPWA.classList.add("hidden")
        }

        if (reloadInfoSmartGuide !== null) {
            reloadInfoSmartGuide.classList.add("hidden")
        }
    }

    function requestViewUpdate(deploymentId) {
        // request to update view via controller action
        $.rails.ajax({
            url: `/deployments/${deploymentId}/state_update`,
            type: "get",
            data: "",
        })
    }

})