document.addEventListener('turbolinks:load', () => {
    const btBeaconCount = document.getElementById('btBeaconCount')

    const byodModuleButton = document.getElementById('moduleBringYourOwnDeviceEnabledButton')
    const byodModuleSwitch = document.getElementById('moduleBringYourOwnDeviceEnabled')
    const byodSettings = document.getElementById('byodSettings')

    const cmsAppEnabledButton = document.getElementById('moduleCmsAppEnabledButton')
    const cmsAppEnabledSwitch = document.getElementById('moduleCmsAppEnabled')
    const cmsAppSettings = document.getElementById('cmsAppSettings')

    const moduleMediaEnabledButton = document.getElementById('moduleMediaEnabledButton')
    const moduleMediaEnabledSwitch = document.getElementById('moduleMediaEnabled')
    const moduleQuizEnabledButton = document.getElementById('moduleQuizEnabledButton')
    const moduleQuizEnabledLabel = document.getElementById('moduleQuizEnabledLabel')

    // btBeaconCount change listener
    if (btBeaconCount !== null) {
        btBeaconCount.addEventListener('change', function (e) {
            onBtBeaconCountChange()
        })
    }

    // BYOD Module Settings
    if (byodModuleButton !== null && byodModuleSwitch !== null && byodSettings !== null) {

        // get all labels and data fields (input, buttons)
        const labels = byodSettings.getElementsByTagName("label")
        const inputs = byodSettings.getElementsByTagName("input")
        const buttons = byodSettings.getElementsByTagName("button")

        // init visibility
        initByodSettingVisibility(labels, inputs, buttons)

        // change listener for byodModuleButton/Switch
        byodModuleButton.addEventListener('click', function (e) {
            if (byodModuleButton.ariaPressed) { // set via alpine.js
                disableSettings(labels, inputs, buttons, "click")
            } else {
                enableSettings(labels, inputs, buttons)
            }
        })
    }

    // CMS APP Settings
    if (cmsAppEnabledButton !== null && cmsAppEnabledSwitch !== null && cmsAppSettings !== null) {

        // get all labels and data fields (input, buttons)
        const labels = cmsAppSettings.getElementsByTagName("label")
        const inputs = cmsAppSettings.getElementsByTagName("input")
        const buttons = cmsAppSettings.getElementsByTagName("button")

        // init visibility
        initCmsAppSettings(labels, inputs, buttons)

        // change listener for cmsAppEnabledButton/Switch
        cmsAppEnabledButton.addEventListener('click', function (e) {
            if (cmsAppEnabledButton.ariaPressed) { // set via alpine.js
                disableSettings(labels, inputs, buttons, "click")
            } else {
                enableSettings(labels, inputs, buttons)
            }
        })
    }

    if (moduleMediaEnabledButton !== null && moduleMediaEnabledSwitch !== null && moduleQuizEnabledButton !== null &&
        moduleQuizEnabledLabel !== null) {

        initQuizModuleEnabled([moduleQuizEnabledLabel], [], [moduleQuizEnabledButton])

        moduleMediaEnabledButton.addEventListener('click', function (e) {
            if (moduleMediaEnabledButton.ariaPressed) { // set via alpine.js
                disableSettings([moduleQuizEnabledLabel], [], [moduleQuizEnabledButton], "click")
            } else {
                enableSettings([moduleQuizEnabledLabel], [], [moduleQuizEnabledButton])
            }
        })
    }



    // ##################################################################################################################
    // Helper functions
    // ##################################################################################################################
    /**
     * enable/disable cmsAppSettings on page load
     */
    function initCmsAppSettings(labels, inputs, buttons) {
        if (cmsAppEnabledSwitch.checked) {
            enableSettings(labels, inputs, buttons)
        } else {
            disableSettings(labels, inputs, buttons, "load")
        }
    }

    /**
     * enable/disable byodSettings on page load
     */
    function initByodSettingVisibility(labels, inputs, buttons) {
        if (byodModuleSwitch.checked) {
            enableSettings(labels, inputs, buttons)
        } else {
            disableSettings(labels, inputs, buttons, "load")
        }
    }

    function initQuizModuleEnabled(labels, inputs, buttons) {
        if (moduleMediaEnabledSwitch.checked) {
            enableSettings(labels, inputs, buttons)
        } else {
            disableSettings(labels, inputs, buttons, "load")
        }
    }

    /**
     * disable all labels, inputs and buttons
     */
    function disableSettings(labels, inputs, buttons, mode) {

        // loop all labels and add class
        for (let label of labels) {
            label.classList.add("text-gray-400")
            label.classList.remove("text-gray-700")
        }

        // loop all inputs, disable them and add class
        for (let input of inputs) {
            input.disabled = true
            input.classList.add("bg-gray-100")
            input.classList.add("text-gray-300")
        }

        // loop all switches, disable them and add class
        for (let uiSwitch of buttons) {

            if (mode === "load") {
                // is the page is loaded we have to check for the value of the 'checked' value of the input
                if (document.getElementById(uiSwitch.id.replace("Button", "")).checked) {
                    uiSwitch.classList.add("bg-grayOverwrite-200") // since 'bg-gray-200' will be overwritten by alpine.js we use another class
                    uiSwitch.classList.remove("bg-blue-600")
                }
            } else {
                if (uiSwitch.ariaPressed) { // only change background if currently enabled
                    uiSwitch.classList.add("bg-gray-200")
                    uiSwitch.classList.remove("bg-blue-600")
                }
            }
            uiSwitch.disabled = true
        }
    }

    /**
     * enable all labels, inputs and buttons
     */
    function enableSettings(labels, inputs, buttons) {

        // loop all labels and add class
        for (let label of labels) {
            label.classList.remove("text-gray-400")
            label.classList.add("text-gray-700")
        }

        // loop all inputs, disable them and add class
        for (let input of inputs) {
            input.disabled = false
            input.classList.remove("bg-gray-100")
            input.classList.remove("text-gray-300")
        }

        // loop all switches, disable them and add class
        for (let uiSwitch of buttons) {
            uiSwitch.disabled = false
            if (uiSwitch.ariaPressed) { // only change background if currently enabled
                uiSwitch.classList.remove("bg-grayOverwrite-200") // is maybe set on page load when switch was disabled so we have to remove ist
                uiSwitch.classList.remove("bg-gray-200")
                uiSwitch.classList.add("bg-blue-600")
            }
        }
    }


    function onBtBeaconCountChange() {
        const selectedCount = btBeaconCount.value
        const btBeaconMacs = document.getElementsByClassName("btBeaconMac")
        const fieldCount = btBeaconMacs[btBeaconMacs.length-1].dataset.number

        if (selectedCount > 0) {
            if (selectedCount > fieldCount) {
                for (let i = 0; i < selectedCount - fieldCount; i++) {
                    const newBtBeaconsMac = btBeaconMacs[0].cloneNode()
                    newBtBeaconsMac.id = "bt_beacon_mac_" + (parseInt(fieldCount) + 1)
                    newBtBeaconsMac.dataset.number = "" + (parseInt(fieldCount) + 1)
                    newBtBeaconsMac.value = ""
                    btBeaconMacs[0].parentNode.appendChild(newBtBeaconsMac)
                }
            } else if (fieldCount > selectedCount) {
                for (let i = 0; i < fieldCount - selectedCount; i++) {
                    const indexToRemove = btBeaconMacs.length - 1
                    btBeaconMacs[indexToRemove].remove()
                }
            }
        }
    }
});