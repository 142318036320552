document.addEventListener('turbolinks:load', () => {

    const  CryptoJS = require("crypto-js");

    let IP = ""
    if (RailsEnv === "development") {
        IP = "https://dev-54ta5gq-tewfnygcdfgvu.de-2.platformsh.site/"
    } else if (RailsEnv === "production") {
        IP = "https://smartsync.meder-commtech.com/"
    }

    const statusUpdateAvailableMsg = document.getElementById('statusUpdateAvailableMsg')
    const closeStatusUpdateAvailableMsg = document.getElementById('closeStatusUpdateAvailableMsg')

    let currentConnectionTimeStamp
    let customerId
    let customerKey

    if(statusUpdateAvailableMsg !== null && closeStatusUpdateAvailableMsg !== null) {

        currentConnectionTimeStamp = document.getElementById('auth_information').dataset.date
        customerId = document.getElementById('auth_information').dataset.id
        customerKey = document.getElementById('auth_information').dataset.key

        checkForUpdate()
        closeUpdateMsgButton()
    }

    function checkForUpdate() {
        setInterval(function() {
            statusRequest()
        },30000);
    }

    function closeUpdateMsgButton() {
        closeStatusUpdateAvailableMsg.addEventListener('click', function (event) {
            statusUpdateAvailableMsg.classList.add('hidden')
        })
    }

    function statusRequest() {
        // url to request the result file
        const url = IP + "status/chargers/customer/" + customerId + "/charger/all"
        // http request
        var request = new XMLHttpRequest();
        // process the response
        request.addEventListener("readystatechange", processResponse, false);
        // open http request
        request.open("GET", url);

        // prepare authetification
        var currentTime = new Date().yyyyMMddhhmmss();
        var hash = CryptoJS.HmacSHA256(currentTime + customerId, customerKey);
        var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
        request.setRequestHeader("Authorization", hashInBase64)
        request.setRequestHeader("x-Date", currentTime)

        // send http request
        request.send(null);
    }

    function processResponse(e) {
        // loop over all units to check last connected date
        if (e.target.readyState === 4 && e.target.status === 200) {
            const chargerStatus = JSON.parse(e.target.response)
            for(let i = 0; i < chargerStatus.charger.length; i++) {
                let units = chargerStatus.charger[i].unit
                for (let j = 0; j < units.length; j++) {
                    if(Date.parse(currentConnectionTimeStamp) < Date.parse(units[j].last_connected)) {
                        // new data available, show info
                        statusUpdateAvailableMsg.classList.remove('hidden')
                        break
                    }
                }
            }
        }
    }

});

Date.prototype.yyyyMMddhhmmss = function() {
    var MM = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
    var yyyy = this.getFullYear();
    var hh = this.getHours();
    var mm = this.getMinutes();
    var timeZoneOffset = this.getTimezoneOffset()/60


    if (dd < 10) {
        dd = "0" + dd
    }

    if (MM < 10) {
        MM = "0" + MM
    }

    if (mm < 10) {
        mm = "0" + mm
    }

    var ss = this.getSeconds();
    if (ss < 10) {
        ss = "0" + ss
    }
    if (hh < 10) {
        hh = "0" + hh
    }

    if (timeZoneOffset < 0) {
        if (timeZoneOffset > -9) {
            var xxxx = "+0" + (timeZoneOffset*-1) +":00"
        } else {
            var xxxx = "+" + (timeZoneOffset*-1) +":00"
        }
    } else if (timeZoneOffset > 0) {
        if (timeZoneOffset < 9) {
            var xxxx = "-0" + (timeZoneOffset*-1) +":00"
        } else {
            var xxxx = "-" + (timeZoneOffset*-1) +":00"
        }
    } else {
        var xxxx = "+00:00"
    }

    return yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm + ":" + ss +""+ xxxx
};