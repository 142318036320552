document.addEventListener('turbolinks:load', () => {

    const primaryColorPreview = document.getElementById('primaryColorPreview');
    const primaryColorTextField = document.getElementById('primaryColorTextField');
    const primaryColorColorField = document.getElementById('primaryColorColorField');
    const NoValidPrimaryColorMessage = document.getElementById('noValidPrimaryColorMessage');

    const backgroundColorPreview = document.getElementById('backgroundColorPreview');
    const backgroundColorTextField = document.getElementById('backgroundColorTextField');
    const backgroundColorColorField = document.getElementById('backgroundColorColorField');
    const noValidBackgroundColorMessage = document.getElementById('noValidBackgroundColorMessage');

    const turnScreenOffIfNoLightDetectedDiv = document.getElementById('turnScreenOffIfNoLightDetectedDiv');
    const guideModeSelect = document.getElementById('mmg_app_guide_mode')
    const turnScreenOffIfNoLightDetectedLabel = document.getElementById('turnScreenOffIfNoLightDetectedLabel')

    const themeSelect = document.getElementById('themeSelect')


    if (primaryColorPreview!= null && NoValidPrimaryColorMessage !== null && themeSelect !== null && backgroundColorPreview !== null &&
        noValidBackgroundColorMessage !== null && turnScreenOffIfNoLightDetectedDiv !== null && guideModeSelect !== null &&
        turnScreenOffIfNoLightDetectedLabel !== null) {
        init();
    }

    function init() {

        // color
        updateColor();
        addChangeColorListener();

        // wifi password
        handleShowPasswordIconButtonPress()

        //Turn screen off in darkness only for selfguided
        guideModeSelect.addEventListener('change', function(e){
            const guideMode = e.target.selectedOptions[0].value
            if (guideMode === "guided"){
                turnScreenOffIfNoLightDetectedDiv.classList.add('hidden')
            } else {
                turnScreenOffIfNoLightDetectedDiv.classList.remove('hidden')
                if (guideMode === "dynamic") {
                    turnScreenOffIfNoLightDetectedLabel.innerHTML = I18n.t('js.turn_screen_off_if_no_light_detected') +
                        I18n.t('js.only_for_self_guided_tours_html')
                } else {
                    turnScreenOffIfNoLightDetectedLabel.innerHTML = I18n.t('js.turn_screen_off_if_no_light_detected')
                }
            }
        })
    }

    function handleShowPasswordIconButtonPress() {

        const showPasswordIconButtons = document.getElementsByClassName('showPasswordIconButtons')
        for (let showPasswordIconButton of showPasswordIconButtons) {

            showPasswordIconButton.classList.remove('hidden')
            showPasswordIconButton.addEventListener("click", function() {
                const passwordValue = this.parentNode.querySelector(".password").innerHTML
                const hidePasswordIconButton = this.parentNode.querySelector("#hidePasswordIconButton")

                const displayedWifiPassword = this.parentNode.querySelector("#wifiPassword")
                if (displayedWifiPassword !== null) {
                    displayedWifiPassword.innerHTML = passwordValue
                    showPasswordIconButton.classList.add('hidden')

                    if (hidePasswordIconButton !== null) {
                        hidePasswordIconButton.classList.remove('hidden')
                        hidePasswordIconButton.addEventListener("click", function() {
                            showPasswordIconButton.classList.remove('hidden')
                            hidePasswordIconButton.classList.add('hidden')
                            const regex = /./g;
                            displayedWifiPassword.innerHTML = passwordValue.replaceAll(regex, '*')
                        });
                    }
                }
            });
        }
    }

    // Color
    function updateColor() {

        if (isColor(primaryColorTextField.value)) {
            primaryColorPreview.style.fill = primaryColorTextField.value;
            NoValidPrimaryColorMessage.classList.add('hidden');
        } else {
            primaryColorPreview.style.fill = '#94a3b8';
            NoValidPrimaryColorMessage.classList.remove('hidden');
        }

        if (isColor(backgroundColorTextField.value)) {
            backgroundColorPreview.style.fill = backgroundColorTextField.value;
            noValidBackgroundColorMessage.classList.add('hidden');
        } else if (backgroundColorTextField.disabled) {
            backgroundColorPreview.style.fill = '#D1D5DB';
            noValidBackgroundColorMessage.classList.add('hidden');
        } else {
            backgroundColorPreview.style.fill = '#94a3b8';
            noValidBackgroundColorMessage.classList.remove('hidden');
        }
    }

    function addChangeColorListener() {
        primaryColorTextField.addEventListener("change", function() {
            updateColor();
            updateColorValue("primaryColor", primaryColorTextField.value);
        });
        primaryColorColorField.addEventListener("change", function() {
            updateColorValue("primaryColor", primaryColorColorField.value);
        });

        backgroundColorTextField.addEventListener("change", function() {
            updateColor();
            updateColorValue("backgroundColor", backgroundColorTextField.value);
        });
        backgroundColorColorField.addEventListener("change", function() {
            updateColorValue("backgroundColor", backgroundColorColorField.value);
        });
    }

    function isColor(color){
        return /^#[0-9A-F]{6}$/i.test(color);
    }

    function updateColorValue(fieldId, colorValue){
        const textField = document.getElementById(fieldId + 'TextField')
        // Only trigger changeEvent if the color changed
        if (textField !== null && textField.value !== colorValue){
            textField.value = colorValue
            //trigger changeEvent
            const e = new Event("change")
            textField.dispatchEvent(e);
        }

        const colorField = document.getElementById(fieldId + 'ColorField')
        if (colorField !== null) {
            colorField.value = colorValue
        }
    }

    slist("sortlist");

});

function slist (target) {
    target = document.getElementById(target);
    if (target != null) {
        target.classList.add("slist");

        let items = target.getElementsByTagName("li"), current = null;
        for (let i of items) {

            i.draggable = true;

            i.addEventListener("dragstart", function (ev) {
                current = this;
                for (let it of items) {
                    if (it != current) { it.classList.add("hint"); }
                }
            });

            i.addEventListener("dragenter", function (ev) {
                if (this != current) { this.classList.add("active"); }
            });

            i.addEventListener("dragleave", function () {
                this.classList.remove("active");
            });

            i.addEventListener("dragend", function () {

                let s = 1;
                for (let it of items) {
                    it.classList.remove("hint");
                    it.classList.remove("active");

                    it.setAttribute('data-sort', s);

                    s += 1;
                }
            });

            i.addEventListener("dragover", function (evt) {
                evt.preventDefault();
            });

            i.addEventListener("drop", function (evt) {
                evt.preventDefault();
                if (this != current) {
                    let currentpos = 0, droppedpos = 0;
                    for (let it=0; it<items.length; it++) {
                        if (current == items[it]) { currentpos = it; }
                        if (this == items[it]) { droppedpos = it; }
                    }
                    if (currentpos < droppedpos) {
                        this.parentNode.insertBefore(current, this.nextSibling);
                    } else {
                        this.parentNode.insertBefore(current, this);
                    }
                }
            });
        }
    }
}


