document.addEventListener('turbolinks:load', () => {

    // preview
    const previewImageId = document.getElementById('tourPreviewImageId');
    const previewImageSrc = document.getElementById('tourPreviewImageSrc');
    // is null without Stations in the tour
    const sortTableStationGroups = document.getElementById('sortTableStationGroups')

    if (previewImageId !== null && previewImageSrc !== null) {
        // update
        const previewImageSelect2 = $("#tourPreviewImageId").select2();
        previewImageSelect2.data('select2').$container.addClass("wrap")
        previewImageSelect2.on('select2:selecting', function(e) {
            previewSrc = e.params.args.data.element.dataset.src
            if (previewSrc === undefined) {
                previewSrc = "/assets/media_gallery/image_placeholder.png"
            }
            previewImageSrc.src = previewSrc
        });
        $("#previewImageIdViaGallery").hide()
    }

    // Table sort
    let mCurrentArea = null
    sortTableStationGroup ('sortTableStationGroups')
    sortTableStation('stationGroup')

    // Drag&Drop for Areas
    function sortTableStationGroup (target) {
        target = document.getElementById(target);
        if (target != null) {
            let items = document.getElementsByClassName("stationGroup")
            for (let i = 0; i < items.length; i++) {
                const k = items[i].getElementsByClassName('areaHeading')
                k[0].draggable = true;

                // START
                k[0].addEventListener("dragstart", function (ev) {
                    mCurrentArea = this.parentNode
                    //Remove the alternate-children class, because this bg-color gets used otherwise and the added color classes don't work
                    sortTableStationGroups.classList.remove('alternate-children')

                    // mark other list elements
                    for (let j = 0; j < items.length; j++) {
                        const m = items[j].getElementsByClassName('areaHeading')
                        if (this !== m[0]) {
                            m[0].childNodes[1].classList.remove("bg-blue-50")
                            m[0].childNodes[1].classList.add("bg-amber-200")
                            m[0].parentNode.classList.add("bg-amber-100");
                        }
                    }
                });

                // ENTER
                k[0].addEventListener("dragenter", function (ev) {
                    if (mCurrentArea !== null) {
                        if (this.parentNode !== mCurrentArea) {

                            // style reached area
                            this.childNodes[1].classList.remove("bg-amber-200")
                            this.childNodes[1].classList.add("bg-red-200")
                            this.parentNode.classList.remove("bg-amber-100");
                            this.parentNode.classList.add("bg-red-100");

                            // remove style from other areas
                            for (let l = 0; l < items.length; l++) {
                                const m = items[l].getElementsByClassName('areaHeading')
                                if (this !== m[0] && mCurrentArea.childNodes[3] !== m[0]) {
                                    m[0].childNodes[1].classList.remove("bg-red-200")
                                    m[0].childNodes[1].classList.add("bg-amber-200")
                                    m[0].parentNode.classList.remove("bg-red-100");
                                    m[0].parentNode.classList.add("bg-amber-100");
                                }
                            }
                        } else {
                            // remove style from others when reaching current dragged area
                            for (let l = 0; l < items.length; l++) {
                                const m = items[l].getElementsByClassName('areaHeading')
                                if (mCurrentArea.childNodes[3] !== m[0]) {
                                    m[0].childNodes[1].classList.remove("bg-red-200")
                                    m[0].childNodes[1].classList.add("bg-amber-200")
                                    m[0].parentNode.classList.remove("bg-red-100");
                                    m[0].parentNode.classList.add("bg-amber-100");
                                }
                            }
                        }
                    }
                });

                // LEAVE
                items[i].addEventListener("dragleave", function () {
                });

                // END
                k[0].addEventListener("dragend", function () {

                    // reset everything
                    mCurrentArea = null
                    sortTableStationGroups.classList.add('alternate-children')
                    for (let j = 0; j < items.length; j++) {
                        const m = items[j].getElementsByClassName('areaHeading')
                        m[0].childNodes[1].classList.add("bg-blue-50")
                        m[0].childNodes[1].classList.remove("bg-amber-200")
                        m[0].parentNode.classList.remove("bg-amber-100");
                        m[0].childNodes[1].classList.remove("bg-red-200")
                        m[0].parentNode.classList.remove("bg-red-100");
                    }
                });


                // OVER
                k[0].addEventListener("dragover", function (evt) {
                    evt.preventDefault();
                });


                // DROP
                k[0].addEventListener("drop", function (evt) {
                    evt.preventDefault();
                    // insert at correct position
                    if (this.parentNode !== mCurrentArea && this.parentNode.tagName === "TBODY") {

                        const stationGroup = this.parentNode

                        let currentpos = 0, droppedpos = 0;
                        for (let it=0; it<items.length; it++) {
                            if (mCurrentArea === items[it]) { currentpos = it; }
                            if (stationGroup === items[it]) { droppedpos = it; }
                        }
                        if (currentpos < droppedpos) {
                            stationGroup.parentNode.insertBefore(mCurrentArea, stationGroup.nextSibling);
                        } else {
                            stationGroup.parentNode.insertBefore(mCurrentArea, stationGroup);
                        }
                    }
                });
            }
        }
    }

    // Drag&Drop for Stations
    function sortTableStation (target) {
        const targets = document.getElementsByClassName(target);
        if (targets != null && targets.length > 0) {
            for (let i = 0; i < targets.length; i++) {
                target = targets[i];

                let items = target.getElementsByTagName("tr");
                let current = null;
                let itemsArray = Array.prototype.slice.call( items )
                for (let i = itemsArray.length -1; i-- ; i>= 0 ) {
                    if(itemsArray[i].classList.contains('areaHeading') || itemsArray[i].classList.contains('mainHeading')) {
                        itemsArray.splice(i, 1);
                    }
                }

                for (let i of itemsArray) {
                    i.draggable = true;

                    // START
                    i.addEventListener("dragstart", function (ev) {
                        current = this;
                        //Remove the alternate-children class, because this bg-color gets used otherwise and the added classes don't work
                        sortTableStationGroups.classList.remove('alternate-children')
                        // style other elements
                        for (let it of items) {
                            if (it !== current) {
                                it.classList.add("bg-amber-200")
                            }
                        }
                    });

                    // ENTER
                    i.addEventListener("dragenter", function (ev) {
                        if (mCurrentArea === this.parentNode) {
                            // styling of AREA - all yellow when the currently dragged area is reached again
                            let items = document.getElementsByClassName("stationGroup");
                            for (let l = 0; l < items.length; l++) {
                                const m = items[l].getElementsByClassName('areaHeading')
                                if (mCurrentArea.childNodes[3] !== m[0]) {
                                    m[0].childNodes[1].classList.remove("bg-red-200")
                                    m[0].childNodes[1].classList.add("bg-amber-200")
                                    m[0].parentNode.classList.remove("bg-red-100");
                                    m[0].parentNode.classList.add("bg-amber-100");
                                }
                            }
                        } else {
                            // styling of stations
                            for (let it of items) {
                                if (current != null) {
                                    if (it !== current && it !== i) {
                                        // other stations
                                        it.classList.add("bg-amber-200")
                                        it.classList.remove("bg-red-200")
                                    } else if (it === i && it !== current) {
                                        // reached station
                                        it.classList.add("bg-red-200")
                                        it.classList.remove("bg-amber-200")
                                    }
                                }
                            }
                        }
                    });

                    // LEAVE
                    i.addEventListener("dragleave", function () {
                    });

                    // END
                    i.addEventListener("dragend", function () {
                        // reset everything
                        current = null
                        sortTableStationGroups.classList.add('alternate-children')
                        for (let it of items) {
                            it.classList.remove("bg-amber-200")
                            it.classList.remove("bg-red-200")
                        }
                    });

                    // OVER
                    i.addEventListener("dragover", function (evt) {
                        evt.preventDefault();
                    });

                    // DROP
                    i.addEventListener("drop", function (evt) {
                        evt.preventDefault();
                        if (this !== current && current  &&this.parentNode === current.parentNode) {
                            // Drop of stations
                            let currentpos = 0, droppedpos = 0;
                            for (let it=0; it<items.length; it++) {
                                if (current === items[it]) { currentpos = it; }
                                if (this === items[it]) { droppedpos = it; }
                            }
                            if (currentpos < droppedpos) {
                                this.parentNode.insertBefore(current, this.nextSibling);
                            } else {
                                this.parentNode.insertBefore(current, this);
                            }
                        } else if (mCurrentArea != null && this.parentNode !== mCurrentArea && this.parentNode.classList.contains('stationGroup') &&
                            mCurrentArea.classList.contains('stationGroup')){

                            // Drop of Area
                            const stationGroup = this.parentNode
                            let items = document.getElementsByClassName("stationGroup")
                            let currentpos = 0, droppedpos = 0;
                            for (let it=0; it<items.length; it++) {
                                if (mCurrentArea === items[it]) {
                                    currentpos = it;
                                }
                                if (stationGroup === items[it]) {
                                    droppedpos = it;
                                }
                            }
                            if (currentpos < droppedpos) {
                                stationGroup.parentNode.insertBefore(mCurrentArea, stationGroup.nextSibling);
                            } else if (currentpos > (droppedpos+1)) {
                                stationGroup.parentNode.insertBefore(mCurrentArea, stationGroup.nextSibling);
                            }
                        }
                    });
                }
            }
        }
    }
});