/**
 * This file handles:
 *  - the size of the preview area
 *  - the the size, positioning and rotation of the included items
 *  - click listeners for replay button
 */
document.addEventListener('turbolinks:load', () => {

    // UI elements
    // must be 'let' since they are set again via notice_preview.js.erb
    let safetyNoticePreviewScreen
    let safetyNoticePreviewNextButton
    let SafetyNoticeNextArrow
    let safetyNoticePreviewProgressIndicator
    let safetyNoticePreviewHeading
    let safetyNoticePreviewHeadingSpan
    let safetyNoticePreviewDescription
    let safetyNoticePreviewDescriptionSpan
    let safetyNoticePreviewIconCircle
    let safetyNoticePreviewIcon
    let safetyNoticePreviewSgTop
    let safetyNoticePreviewSgBottom
    let safetyNoticeVideo
    let safetyNoticeVideoReplayButton

    initNoticePreviewSizeObserver()

    function initNoticePreviewSizeObserver() {
        initElements()

        if (safetyNoticePreviewScreen !== null && safetyNoticePreviewSgTop !== null &&
            safetyNoticePreviewSgBottom !== null) {
            startObserver()

            // Video
            if (safetyNoticeVideo !== null && safetyNoticeVideoReplayButton !== null &&
                safetyNoticePreviewNextButton !== null ) {
                showVideoButtonsOnVideoEnd()
                videoReplayClickListener()
            }
        }
    }
    window.initNoticePreviewSizeObserver = initNoticePreviewSizeObserver; // provide for notice_preview.js.erb


    function initElements() {
        safetyNoticePreviewScreen = document.getElementById('safetyNoticePreviewScreen')
        safetyNoticePreviewNextButton = document.getElementById('safetyNoticePreviewNextButton')
        SafetyNoticeNextArrow = document.getElementById('SafetyNoticeNextArrow')
        safetyNoticePreviewProgressIndicator = document.getElementById('safetyNoticePreviewProgressIndicator')
        safetyNoticePreviewHeading = document.getElementById('safetyNoticePreviewHeading')
        safetyNoticePreviewHeadingSpan = document.getElementById('safetyNoticePreviewHeadingSpan')
        safetyNoticePreviewDescription = document.getElementById('safetyNoticePreviewDescription')
        safetyNoticePreviewDescriptionSpan = document.getElementById('safetyNoticePreviewDescriptionSpan')
        safetyNoticePreviewIconCircle = document.getElementById('safetyNoticePreviewIconCircle')
        safetyNoticePreviewIcon = document.getElementById('safetyNoticePreviewIcon')
        safetyNoticeVideo = document.getElementById('safetyNoticeVideo')
        safetyNoticeVideoReplayButton = document.getElementById('safetyNoticeVideoReplayButton')
        safetyNoticePreviewSgTop = document.getElementById('safetyNoticePreviewSgTop')
        safetyNoticePreviewSgBottom = document.getElementById('safetyNoticePreviewSgBottom')
    }

    function startObserver() {

        let resizeObserver = new ResizeObserver(() => {

            // SG Top and Bottom Black area
            safetyNoticePreviewSgTop.style.borderTopLeftRadius = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 22.0}px`
            safetyNoticePreviewSgTop.style.borderTopRightRadius = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 22.0}px`
            safetyNoticePreviewSgBottom.style.borderBottomLeftRadius = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 22.0}px`
            safetyNoticePreviewSgBottom.style.borderBottomRightRadius = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 22.0}px`

            if (safetyNoticePreviewNextButton !== null && SafetyNoticeNextArrow !== null &&
            safetyNoticePreviewProgressIndicator !== null && safetyNoticePreviewHeading !== null &&
            safetyNoticePreviewDescription !== null && safetyNoticePreviewHeadingSpan !== null &&
            safetyNoticePreviewIconCircle !== null && safetyNoticePreviewIcon !== null &&
            safetyNoticePreviewDescriptionSpan !== null) {
                // ### TEXT_WITH_ICON ###

                // Next Button
                // font siz is 20sp
                safetyNoticePreviewNextButton.style.fontSize = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 21}px`;
                safetyNoticePreviewNextButton.style.paddingTop = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 60}px`
                safetyNoticePreviewNextButton.style.paddingBottom = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 60}px`
                safetyNoticePreviewNextButton.style.paddingLeft = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 26}px`
                safetyNoticePreviewNextButton.style.paddingRight = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 26}px`
                SafetyNoticeNextArrow.setAttribute('width',
                    `${safetyNoticePreviewScreen.getBoundingClientRect().height / 32.77}`)
                SafetyNoticeNextArrow.setAttribute('height',
                    `${safetyNoticePreviewScreen.getBoundingClientRect().height / 32.77}`)

                // Progress Indicator
                // font siz is 16sp
                safetyNoticePreviewProgressIndicator.style.fontSize = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 26}px`;
                safetyNoticePreviewProgressIndicator.style.paddingLeft = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 45}px`
                safetyNoticePreviewProgressIndicator.style.paddingRight = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 45}px`

                // Heading
                // font size is 24sp
                safetyNoticePreviewHeading.style.fontSize = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 15.5}px`;
                safetyNoticePreviewHeadingSpan.style.padding = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 247.45}px`;

                // description
                // font site is 16sp
                safetyNoticePreviewDescription.style.fontSize = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 24}px`;
                safetyNoticePreviewDescription.style.marginTop = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 2.4}`
                safetyNoticePreviewDescription.style.marginTop = `${(safetyNoticePreviewScreen.getBoundingClientRect().height / 741.11669921875) * 305}px`
                safetyNoticePreviewDescriptionSpan.style.paddingTop = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 371.17}px`;
                safetyNoticePreviewDescriptionSpan.style.paddingBottom = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 371.17}px`;
                safetyNoticePreviewDescriptionSpan.style.paddingLeft = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 185.58}px`;
                safetyNoticePreviewDescriptionSpan.style.paddingRight = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 185.58}px`;

                // Icon
                safetyNoticePreviewIconCircle.style.width = `${safetyNoticePreviewScreen.getBoundingClientRect().width * 0.377}px`;
                safetyNoticePreviewIconCircle.style.height = `${safetyNoticePreviewScreen.getBoundingClientRect().width * 0.377}px`;
                safetyNoticePreviewIconCircle.style.marginTop = `${(safetyNoticePreviewScreen.getBoundingClientRect().height / 741.11669921875) * 134}px`
                safetyNoticePreviewIcon.width = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 8.5}`
                safetyNoticePreviewIcon.height = `${safetyNoticePreviewScreen.getBoundingClientRect().height / 8.5}`
                safetyNoticePreviewIcon.setAttribute('width', `${safetyNoticePreviewScreen.getBoundingClientRect().height / 8.5}`)
                safetyNoticePreviewIcon.setAttribute('height', `${safetyNoticePreviewScreen.getBoundingClientRect().height / 8.5}`)
            } else if (safetyNoticeVideo !== null && safetyNoticeVideoReplayButton !== null &&
                safetyNoticePreviewNextButton !== null ) {
                // ### VIDEO ###

                // The video needs to be rotated and moved to fit the safetyNoticePreviewScreen area

                // set Video height and width to safetyNoticePreviewScreen size
                safetyNoticeVideo.style.maxHeight = `${safetyNoticePreviewScreen.getBoundingClientRect().width}px`
                safetyNoticeVideo.style.maxWidth = `${safetyNoticePreviewScreen.getBoundingClientRect().height}px`

                // get original video height and width
                const dimensions = safetyNoticeVideo.dataset.dimensions.split('x')
                const sizeFactor = safetyNoticePreviewScreen.getBoundingClientRect().width/parseFloat(dimensions[0])
                const videoHeight = parseFloat(dimensions[1]) * sizeFactor
                const videoWidth = safetyNoticePreviewScreen.getBoundingClientRect().width

                // get rotated video height and weight. Old width becomes new height
                const rotateVideoHeight = videoWidth
                const rotateVideoWeight = videoWidth*(parseFloat(dimensions[0])/parseFloat(dimensions[1]))

                // translate video
                const translateX = (rotateVideoWeight-rotateVideoHeight)/2.0
                const translateY = translateX + (safetyNoticePreviewScreen.getBoundingClientRect().height - rotateVideoWeight)/2
                safetyNoticeVideo.style.left = `-${translateX}px`
                safetyNoticeVideo.style.top = `${translateY}px`

                // replay Button scaling
                safetyNoticeVideoReplayButton.style.width = `${safetyNoticePreviewScreen.getBoundingClientRect().width * 0.155}px`;
                safetyNoticeVideoReplayButton.style.height = `${safetyNoticePreviewScreen.getBoundingClientRect().width * 0.155}px`;
                safetyNoticeVideoReplayButton.childNodes[1].style.margin =
                    `${safetyNoticePreviewScreen.getBoundingClientRect().height/117.1125}px`


                transformVideoNextButton()
            }
        });

        resizeObserver.observe(safetyNoticePreviewScreen);
    }

    function showVideoButtonsOnVideoEnd() {
        safetyNoticeVideo.addEventListener('ended', function () {
            safetyNoticeVideoReplayButton.classList.remove('hidden')
            safetyNoticePreviewNextButton.classList.remove('hidden')
            transformVideoNextButton()
        })
    }

    function videoReplayClickListener() {
        safetyNoticeVideoReplayButton.addEventListener('click', function () {
            safetyNoticeVideo.pause();
            safetyNoticeVideo.currentTime = '0';
            safetyNoticeVideo.play();

            // hide buttons on replay
            safetyNoticeVideoReplayButton.classList.add('hidden')
            safetyNoticePreviewNextButton.classList.add('hidden')
        })
    }

    window.transformVideoNextButton = transformVideoNextButton;
    function transformVideoNextButton() {
        // Next Button
        // font siz is 20sp
        safetyNoticePreviewNextButton.style.fontSize = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 21}px`;
        safetyNoticePreviewNextButton.style.paddingTop = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 60}px`
        safetyNoticePreviewNextButton.style.paddingBottom = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 60}px`
        safetyNoticePreviewNextButton.style.paddingLeft = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 26}px`
        safetyNoticePreviewNextButton.style.paddingRight = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 26}px`
        SafetyNoticeNextArrow.setAttribute('width',
            `${safetyNoticePreviewScreen.getBoundingClientRect().height / 32.77}`)
        SafetyNoticeNextArrow.setAttribute('height',
            `${safetyNoticePreviewScreen.getBoundingClientRect().height / 32.77}`)

        // move according to rotation (90°)
        safetyNoticePreviewNextButton.style.top = `${safetyNoticePreviewScreen.getBoundingClientRect().height/2 -
            safetyNoticePreviewNextButton.getBoundingClientRect().width/2}px`
        safetyNoticePreviewNextButton.style.left = `-${safetyNoticePreviewNextButton.getBoundingClientRect().height/2 -
            safetyNoticePreviewNextButton.getBoundingClientRect().width/2}px`
        safetyNoticePreviewNextButton.style.marginLeft = `${safetyNoticePreviewScreen.getBoundingClientRect().width / 6.7}px`
    }
});