document.addEventListener('turbolinks:load', () => {

    // UI elements
    // must be 'let' since they are set again via confirmation_preview.js.erb
    let safetyConfirmPreviewScreen
    let safetyConfirmPreviewNextButton
    let safetyConfirmPreviewIconCircle
    let safetyConfirmPreviewIcon
    let safetyConfirmCheckbox
    let safetyConfirmCheckboxInput
    let safetyConfirmCheckboxText
    let safetyConfirmCheckboxInputDiv
    let safetyConfirmPreviewSgTop
    let safetyConfirmPreviewSgBottom


    initConfirmationPreviewSizeObserver()


    function initConfirmationPreviewSizeObserver() {
        initElements()

        if (safetyConfirmPreviewNextButton !== null && safetyConfirmPreviewScreen !== null &&
            safetyConfirmPreviewIconCircle !== null && safetyConfirmPreviewIcon !== null &&
            safetyConfirmCheckbox !== null && safetyConfirmCheckboxInput !== null && safetyConfirmCheckboxText != null &&
            safetyConfirmCheckboxInputDiv!== null &&
            safetyConfirmPreviewSgTop !== null && safetyConfirmPreviewSgBottom !== null) {
            startObserver()
        }
    }
    window.initConfirmationPreviewSizeObserver = initConfirmationPreviewSizeObserver // provide for confirmation_preview.js.erb

    function initElements() {
        safetyConfirmPreviewScreen = document.getElementById('safetyConfirmPreviewScreen')
        safetyConfirmPreviewNextButton = document.getElementById('safetyConfirmPreviewNextButton')
        safetyConfirmPreviewIconCircle = document.getElementById('safetyConfirmPreviewIconCircle')
        safetyConfirmPreviewIcon = document.getElementById('safetyConfirmPreviewIcon')
        safetyConfirmCheckbox = document.getElementById('safetyConfirmCheckbox')
        safetyConfirmCheckboxInput = document.getElementById('safetyConfirmCheckboxInput')
        safetyConfirmCheckboxText = document.getElementById('safetyConfirmCheckboxText')
        safetyConfirmCheckboxInputDiv = document.getElementById('safetyConfirmCheckboxInputDiv')

        safetyConfirmPreviewSgTop = document.getElementById('safetyConfirmPreviewSgTop')
        safetyConfirmPreviewSgBottom = document.getElementById('safetyConfirmPreviewSgBottom')
    }

    function startObserver() {

        let resizeObserver = new ResizeObserver(() => {
            // Confirm Button
            // font siz is 20sp
            safetyConfirmPreviewNextButton.style.fontSize  = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/18}px`;
            safetyConfirmPreviewNextButton.style.paddingTop = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/70}px`
            safetyConfirmPreviewNextButton.style.paddingBottom = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/70}px`
            safetyConfirmPreviewNextButton.style.paddingLeft = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/30}px`
            safetyConfirmPreviewNextButton.style.paddingRight = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/30}px`

            // icon
            safetyConfirmPreviewIconCircle.style.width = `${safetyConfirmPreviewScreen.getBoundingClientRect().width*0.255}px`;
            safetyConfirmPreviewIconCircle.style.height = `${safetyConfirmPreviewScreen.getBoundingClientRect().width*0.255}px`;
            safetyConfirmPreviewIcon.setAttribute('width', `${safetyConfirmPreviewScreen.getBoundingClientRect().height/15.0}`)
            safetyConfirmPreviewIcon.setAttribute('height', `${safetyConfirmPreviewScreen.getBoundingClientRect().height/15.0}`)

            // Confirm checkbox
            // font size is 18
            safetyConfirmCheckbox.style.fontSize  = `${safetyConfirmPreviewScreen.getBoundingClientRect().width/20}px`;
            safetyConfirmCheckboxInput.style.width = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/39.98}px`;
            safetyConfirmCheckboxInput.style.height = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/39.98}px`;
            safetyConfirmCheckboxText.style.marginLeft = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/59.95}px`;
            safetyConfirmCheckboxInputDiv.style.paddingLeft = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/89.96}px`;
            safetyConfirmCheckbox.style.paddingTop = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/359.85}px`;
            safetyConfirmCheckbox.style.paddingBottom = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/359.85}px`;
            safetyConfirmCheckboxInput.style.borderWidth =  `${safetyConfirmPreviewScreen.getBoundingClientRect().height/239.9}px`;

            // SG Top and Bottom Black area
            safetyConfirmPreviewSgTop.style.borderTopLeftRadius = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/22.0}px`
            safetyConfirmPreviewSgTop.style.borderTopRightRadius = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/22.0}px`
            safetyConfirmPreviewSgBottom.style.borderBottomLeftRadius = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/22.0}px`
            safetyConfirmPreviewSgBottom.style.borderBottomRightRadius = `${safetyConfirmPreviewScreen.getBoundingClientRect().height/22.0}px`
        });

        resizeObserver.observe(safetyConfirmPreviewScreen);
    }
});