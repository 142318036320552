
document.addEventListener('turbolinks:load', () => {

  const mediaEntryIdP = document.getElementById("mediaEntryId")
  const quizQuestionIdP = document.getElementById('quizQuestionId')
  const addAnswerButton = document.getElementById('addAnswer')
  const removeAnswerButton = document.getElementById('removeAnswer')
  const questionTypeIdP = document.getElementById('quizQuestionQuestionType')

  if (addAnswerButton !== null && mediaEntryIdP != null &&
      quizQuestionIdP !== null && questionTypeIdP !== null &&
      (removeAnswerButton !== null || questionTypeIdP.value === "freetext")) { //Freetext Questions don't have a removeAnswerButton)
    init()
  }

  function init() {

    const mediaEntryId = mediaEntryIdP.innerText
    let quizQuestionId = quizQuestionIdP.innerText
    let initialAnswerCount = document.getElementsByClassName('quiz_question_answer').length
    const questionType = questionTypeIdP.value
    if (quizQuestionId === "") {
      quizQuestionId = -1
    }

    // add answer via ajax
    addAnswerButton.addEventListener('click', function () {
      const existingAnswerCount = document.getElementsByClassName('quiz_question_answer').length

      $.rails.ajax({
        url: `/media_entries/${mediaEntryId}/quiz_questions/${quizQuestionId}/add_answer/${existingAnswerCount}`,
        type: "get",
        data: "",
        success: function () {
          if (questionType === "freetext") {
            document.getElementById(`removeAnswer_${existingAnswerCount}`).addEventListener('click', remove_handler, false)
          }}
      })
    })

    // remove last answer via ajax
    // only necessary if != freetext, and button is in the view
    if (questionTypeIdP.value !== "freetext" && removeAnswerButton != null) {
      removeAnswerButton.addEventListener('click', function () {
        remove_answer(document.getElementsByClassName('quiz_question_answer').length - 1, questionType, mediaEntryId);
      })
    }
    //remove answer button for all existing answers
    if (questionType === "freetext") {
      let remove_solution_buttons = document.getElementsByClassName("remove_solution")
      for (let i = 0; i < remove_solution_buttons.length; i++) {
        remove_solution_buttons[i].addEventListener('click', remove_handler, false)
      }
    }
  }

  function remove_handler(event){
    const button = event.currentTarget //Get the button
    const i = parseInt(button.dataset.target)
    let mediaEntryId = mediaEntryIdP.innerText
    let questionType = "freetext" //only used in freetext at the moment, change if used in other questions as well
    remove_answer(i, questionType, mediaEntryId)
  }

  function remove_answer(answer_id, questionType, mediaEntryId){
    const existingAnswerCount = document.getElementsByClassName('quiz_question_answer').length

    if (existingAnswerCount > 2 || existingAnswerCount > 1 && questionType === "freetext") {
      $.rails.ajax({
        url: `/media_entries/${mediaEntryId}/quiz_questions/remove_answer/${answer_id}`,
        type: "get",
        data: "",
        success: function () {
          //if removed Element wasn't the last Element the numeration has to be redone
          if (answer_id !== existingAnswerCount-1){
            enumerate_answers(answer_id)
          }
        }
      })
    } else {
      if (questionType === "freetext") {
        alert(I18n.t('js.minimum_Answer_count_reached_freetext'));
      }
      else {
        alert(I18n.t('js.minimum_Answer_count_reached'));
      }
    }
  }

  // The answers are enumerated again to have a continuing numeration of all the answers.
  // Prevents from multiple inputs having the same ID after deleting an input in the middle.
  function enumerate_answers(answer_id){
    let answers = document.getElementById("answers").getElementsByClassName("quiz_question_answer") // ist getElementById("answers") nötig oder reicht getElementsByClassName?
        //Only the answers after the deleted answer needs to be re enumerated
    for(let i= answer_id; i<answers.length; i++){
      //get the inputs, labels and the checkbox to alter the attributes to the new number
      let answer = answers[i]
      const main_solution = answer.getElementsByClassName("main_solution")[0]
      const language_solutions = answer.getElementsByClassName("language_solution")
      const language_labels = answer.getElementsByClassName("language_label")
      answer.id = `quiz_question_answers_${i}_container`

      //Set id and placeholder for the main solution. Index of id start with 0, of placeholder with 1
      if (main_solution !== undefined){ // Change only if global solution is present
        main_solution.id = `quiz_question_answers_${i}`
        main_solution.placeholder = main_solution.dataset.placeholder.replace('%{solution_number}',(i+1).toString())
      }

      //Set input and label to new number
      for(let language_count = 0; language_count < language_solutions.length; language_count++){
        let input = language_solutions[language_count]
        let label = language_labels[language_count]

        input.id = input.dataset.id.replace('%{solution_number}',i.toString())
        input.name = input.dataset.name.replace('%{solution_number}',i.toString())
        input.placeholder = input.dataset.placeholder.replace('%{solution_number}',(i+1).toString())
        label.htmlFor = input.id
        label.innerHTML = label.dataset.text.replace('%{solution_number}',(i+1).toString())
      }
      // Change the data-target value to set the removeEvent on the new number
      let delete_button = answer.getElementsByTagName("button")[0]
      delete_button.id = `removeAnswer_${i}`
      delete_button.dataset.target = i
    }
  }
});