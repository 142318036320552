document.addEventListener('turbolinks:load', () => {

    // welcome msg
    const showWelcomeButton = document.getElementById('showWelcomeButton')
    const showWelcomeSwitch = document.getElementById('showWelcome')
    const welcomeMsgWithTranslations = document.getElementById('welcomeMsgWithTranslations')

    // feedback msg
    const showFeedbackButton = document.getElementById('showFeedbackButton')
    const showFeedbackSwitch = document.getElementById('showFeedback')
    const feedbackMsgWithTranslations = document.getElementById('feedbackMsgWithTranslations')

    if (welcomeMsgWithTranslations !== null && showWelcomeButton !== null && showWelcomeSwitch !== null &&
        feedbackMsgWithTranslations !== null && showFeedbackButton !== null && showFeedbackSwitch !== null) {

        initWelcomeMsgArea() // show/hide welcome msg at load
        initFeedbackMsgArea() // show/hide feedback msg at load

        // change listener hide/show welcome
        showWelcomeButton.addEventListener('click', function (e) {
            changeWelcomeMsgAreaVisibility()
        })

        // change listener hide/show feedback
        showFeedbackButton.addEventListener('click', function (e) {
            changeFeedbackMsgAreaVisibility()
        })
    }

    /**
     * show/hide welcome msg on page load
     */
    function initWelcomeMsgArea() {
        if (showWelcomeSwitch.checked) {
            welcomeMsgWithTranslations.classList.remove("hidden")
        } else {
            welcomeMsgWithTranslations.classList.add("hidden")
        }
    }

    /**
     * show/hide feedback msg on page load
     */
    function initFeedbackMsgArea() {
        if (showFeedbackSwitch.checked) {
            feedbackMsgWithTranslations.classList.remove("hidden")
        } else {
            feedbackMsgWithTranslations.classList.add("hidden")
        }
    }

    /**
     * show/hide welcome msg on toggle switch
     */
    function changeWelcomeMsgAreaVisibility() {
        if (showWelcomeButton.ariaPressed) { // set via alpine.js
            welcomeMsgWithTranslations.classList.add("hidden")
        } else {
            welcomeMsgWithTranslations.classList.remove("hidden")
        }
    }

    /**
     * show/hide welcome feedback on toggle switch
     */
    function changeFeedbackMsgAreaVisibility() {
        if (showFeedbackButton.ariaPressed) { // set via alpine.js
            feedbackMsgWithTranslations.classList.add("hidden")
        } else {
            feedbackMsgWithTranslations.classList.remove("hidden")
        }
    }

});