document.addEventListener('turbolinks:load', () => {
    const videoSyncChannelLabel = document.getElementById('videoSyncChannelLabel')
    const videoSyncChannel = document.getElementById('videoSyncChannel')
    const isVideoSyncStation = document.getElementById('isVideoSyncStation')
    const isVideoSyncStationButton = document.getElementById('isVideoSyncStationButton')

    if (videoSyncChannel !== null && isVideoSyncStation !== null && videoSyncChannelLabel &&
        isVideoSyncStationButton !== null) {

        // init
        if (isVideoSyncStation.checked) {
            enableVideoSyncChannel()
        } else {
            disableVideoSyncChannel()
        }

        // event listener
        isVideoSyncStationButton.addEventListener('click', function (e) {
            if (isVideoSyncStationButton.ariaPressed) { // set via alpine.js
                disableVideoSyncChannel()
            } else {
                enableVideoSyncChannel()
            }
        })
    }

    function enableVideoSyncChannel() {
        videoSyncChannelLabel.classList.remove("text-gray-400")
        videoSyncChannelLabel.classList.add("text-gray-700")
        videoSyncChannel.disabled = false
        videoSyncChannel.classList.remove("bg-gray-100")
        videoSyncChannel.classList.remove("text-gray-300")
    }

    function disableVideoSyncChannel() {
        videoSyncChannelLabel.classList.add("text-gray-400")
        videoSyncChannelLabel.classList.remove("text-gray-700")
        videoSyncChannel.disabled = true
        videoSyncChannel.classList.add("bg-gray-100")
        videoSyncChannel.classList.add("text-gray-300")
    }

});