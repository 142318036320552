/**
 * JS file for the use with app/view/templates/instant_font_upload.html.erb
 *
 * Values to set:
 *  id: same value as in instant_font_upload.html.erb
 *  urlSegment: as defined in routes
 *  otherIds: array that contains all other ids that use the same upload template (of any type (image, font))
 *      in the same view. Needed to set ID value for rootDiv.dataset.id if a create action
 *      in the controller is used. Otherwise multiple safety_notice_settings
 *      for one organization could be created
 */

document.addEventListener('turbolinks:load', () => {

    // set base id und url for upload
    const id ="byodAppFont"
    const otherIds = ["byodAppLogo", "byodAppBackground"]
    const urlSegment = "byod_apps"

    // init elements
    const rootDiv = document.getElementById(`${id}Div`)
    const jsUpload = document.getElementById(`${id}JsUpload`)
    const noJsUpload = document.getElementById(`${id}NoJsUpload`)
    const input = document.getElementById(`${id}Input`)
    const dropZone = document.getElementById(`${id}DropZone`)
    const fontPreview = document.getElementById(`${id}Preview`) // set uploaded font on page load

    // information what files are allowed
    let allowedFileTypes

    /**
     * add individual needs here
     */


    if (rootDiv !== null && jsUpload !== null && noJsUpload !== null && input !== null && dropZone !== null) {
        init()
    }

    if (fontPreview !== null) {
        const fontPath = fontPreview.dataset.fontPath
        displayFont(fontPath, fontPreview, null)
    }

    function init() {
        // display js upload
        jsUpload.classList.remove('hidden')
        noJsUpload.classList.add('hidden')

        // set info what files are allowed
        allowedFileTypes = rootDiv.dataset.allowedFileTypes

        // upload via input
        input.addEventListener('change', function(e) {

            // handle type "" of fonts-files
            let type = e.target.files[0].type
            if (type === "") {
                type = e.target.files[0].name.split('.').pop()
            }

            // only allowed file types
            if (!allowedFileTypes.includes(type) || type === "") {
                dropZone.classList.remove('bg-green-200')
                console.log(allowedFileTypes)
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", allowedFileTypeString(allowedFileTypes)));
                return;
            }

            // upload file
            ajaxUpload(e.target.files[0], id)
        })

        //upload via drag&drop
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
            dropZone.classList.add('bg-green-200')
        };

        dropZone.ondragleave = function (evt) {
            evt.preventDefault();
            dropZone.classList.remove('bg-green-200')
        };

        dropZone.ondrop = function(evt) {
            evt.preventDefault();
            input.files = evt.dataTransfer.files

            if (evt.dataTransfer.files.length > 1) {
                dropZone.classList.remove('bg-green-200')
                alert(I18n.t('js.more_than_one_file_alert'));
                return;
            }

            // handle type "" of fonts-files
            let type = evt.dataTransfer.files[0].type
            if (type === "") {
                type = evt.dataTransfer.files[0].name.split('.').pop()
            }

            if (!allowedFileTypes.includes(type) || type === "") {
                dropZone.classList.remove('bg-green-200')
                console.log(allowedFileTypes)
                alert(I18n.t('js.wrong_type_alert_with_param')
                    .replace("${allowed_types}", allowedFileTypeString(allowedFileTypes)));
                return;
            }

            // upload file
            ajaxUpload(input.files[0], id)

            /**
             * Add individual needs here
             */
        }
    }

    function ajaxUpload(file, usage) {
        // hide upload area and show upload spinner
        if (usage === id) {
            const placeholder = document.getElementById(`${id}Placeholder`)
            const preview = document.getElementById(`${id}Preview`)
            const uploadText = document.getElementById(`${id}UploadText`)
            const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
            const previewJs = document.getElementById(`${id}JS`)
            const removeIcon = document.getElementById(`remove${capitalizeFirstLetter(id)}`)

            uploadText.classList.add('hidden')
            uploadSpinner.classList.remove('hidden')
            if (previewJs !== null) {
                previewJs.classList.add("hidden")
            }
            if (removeIcon !== null) {
                removeIcon.classList.add('hidden')
            }
            if (placeholder !== null) {
                placeholder.classList.add("hidden")
            } else if (preview !== null) {
                preview.classList.add("hidden")
            }
        }

        // form data from file
        const formData = new FormData();
        formData.append('file_filed', file);
        formData.append('file_name', file.name)
        formData.append('usage', usage)

        // rails id of object
        let objectId = ""
        if (rootDiv !== null) {
            objectId = rootDiv.dataset.id
            if (objectId === "") {
                objectId = "undefined"
            }
        }

        // do ajax upload
        $.rails.ajax({
            url: `/${urlSegment}/${objectId}/upload_resource`,
            type: "put",
            data: formData,
            async: true,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            success: function (response, status) {
                if (usage === id) {
                    const uploadText = document.getElementById(`${id}UploadText`)
                    const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
                    const previewJs = document.getElementById(`${id}JS`)
                    const removeIcon = document.getElementById(`remove${capitalizeFirstLetter(id)}`)
                    const preview = document.getElementById(`${id}Preview`)

                    // display font after successfully upload
                    displayFont(response.upload_path, preview, previewJs)
                    // display font name
                    if (preview !== null) {
                        preview.innerHTML = file.name
                    } else if (previewJs !== null) {
                        previewJs.innerHTML = file.name
                    }

                    // update remove url
                    removeIcon.childNodes[1].href = `/${urlSegment}/${response.object_id}/delete_resource/${id}`

                    // update rails id of object
                    rootDiv.dataset.id = response.object_id
                    for (let i = 0; i < otherIds.length; i++) {
                        const otherRootDiv = document.getElementById(`${otherIds[i]}Div`)
                        otherRootDiv.dataset.id = response.object_id
                    }

                    // hide and display different parts of ui
                    if (previewJs !== null) {
                        previewJs.classList.remove("hidden")
                    } else if (preview !== null) {
                        preview.classList.remove("hidden")
                    }
                    removeIcon.classList.remove("hidden")
                    uploadText.classList.remove('hidden')
                    uploadSpinner.classList.add('hidden')
                    dropZone.classList.remove('bg-green-200')

                    /**
                     * Add individual needs here
                     *
                     */

                }
            }
        });
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function displayFont(path, defaultFontText, jsFontText) {
        // display font after successfully upload
        const byodAppFont = new FontFace('ByodAppFont', `url(${path})`);
        byodAppFont.load().then(function (loaded_face) {
            document.fonts.add(loaded_face);
            if (jsFontText !== null) {
                jsFontText.style.fontFamily = '"ByodAppFont"';
            } else if (defaultFontText !== null) {
                defaultFontText.style.fontFamily = '"ByodAppFont"';
            }
        }).catch(function (error) {
            console.log("load error: " + error)
        });
    }

    // get correct formatted string from comma seperated allowed string
    function allowedFileTypeString(allowedFileType) {
        let result = ""

        const allowedFileTypeArray = allowedFileType.split(",")
        for (var i = 0; i < allowedFileTypeArray.length; i++) {
            if (i === 0) {
                result += allowedFileTypeArray[i]
            } else if (i < allowedFileTypeArray.length-1) {
                result += `, ${allowedFileTypeArray[i]}`
            } else {
                result += ` ${I18n.t('js.or')} ${allowedFileTypeArray[i]}`
            }
        }

        return result
    }
});